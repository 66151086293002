import * as React from "react";
import { WithStyles, withStyles } from "@material-ui/core";
import { withTranslation, WithTranslation } from "react-i18next";
import styles from "./EventRegisterModalImageSetStyles";
import ImageUploadZone from "./parts/ImageUploadZone";

interface Props extends WithStyles<typeof styles>, WithTranslation {
  imageDataUrl?: string;
  onImageSet: (file: File) => void;
  onImageClear: () => void;
}

const EventRegisterModalImageSet: React.FC<Props> = ({ classes, imageDataUrl, onImageSet, onImageClear }) => (
  <div className={classes.root}>
    <ImageUploadZone url={imageDataUrl} onDrop={onImageSet} onClear={onImageClear} />
  </div>
);

export default withTranslation()(withStyles(styles)(EventRegisterModalImageSet));

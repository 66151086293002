import axios, { AxiosInstance } from "axios";
import { parseISO } from "date-fns";
import { sha256 } from "js-sha256";
import { Buffer } from "buffer";
import { PirikaOdpApi } from "./interface";
import {
  ApiEventApprovalRegister,
  ApiEventRegisterParams,
  ApiInformation,
  ApiInformationParameter,
  ApiResponse,
  ApiResponseStatus,
  PageConfigurations,
} from "./model";

interface RawInformationItem {
  id: number;
  title: string;
  detail: string;
  date: string;
  author: string;
  url: string;
  availableFrom: string;
  availableTo: string;
  updateDate: string;
}

interface RawInformation {
  information: RawInformationItem[];
}

const parseDate = (dateString: string): Date => parseISO(`${dateString}Z`);

const calcSha256Bytes = async (str: string): Promise<number[] | Uint8Array> => {
  // IE11の場合
  if (!window.TextEncoder) {
    const hash = sha256.create();
    hash.update(str);
    return hash.digest();
  }
  const buffer = new TextEncoder().encode(str);
  const digest = await crypto.subtle.digest("SHA-256", buffer);
  return new Uint8Array(digest);
};

const calcSha256Hex = async (str: string): Promise<string> => {
  const digest = await calcSha256Bytes(str);
  return Buffer.from(digest).toString("hex");
};

export class PirikaOdpApiImplements implements PirikaOdpApi {
  private axios: AxiosInstance;

  public constructor() {
    this.axios = axios.create({
      baseURL: "/odpApi/v1/",
      responseType: "json",
      validateStatus: (status) => status < 500,
    });
  }

  public getOpenDataPageConfigurationAsync = async (password?: string): Promise<ApiResponse<PageConfigurations>> => {
    try {
      const cryptPassword = password && (await calcSha256Hex(password));
      const headers: Record<string, string> = {};
      if (cryptPassword) {
        headers.Authorization = `Bearer ${cryptPassword}`;
      }
      const response = await this.axios.get<ApiResponse<PageConfigurations>>("/pages/config", {
        headers,
      });
      return response.data;
    } catch (e) {
      return { status: ApiResponseStatus.Error, code: -1 };
    }
  };

  public getInformationAsync = async (parameter: ApiInformationParameter): Promise<ApiResponse<ApiInformation>> => {
    try {
      const response = await this.axios.get<ApiResponse<RawInformation>>("/information/", {
        params: {
          cursorId: parameter.cursorId || null,
          count: parameter.count || null,
        },
      });
      const rawData = response.data;
      return {
        ...rawData,
        payload: rawData.payload && {
          ...rawData.payload,
          information:
            rawData.payload.information.map((v) => ({
              ...v,
              date: parseDate(v.availableFrom),
              availableFrom: parseDate(v.availableFrom),
              availableTo: parseDate(v.availableTo),
              updateDate: parseDate(v.updateDate),
            })) ?? [],
        },
      };
    } catch (e) {
      return { status: ApiResponseStatus.Error, code: -1 };
    }
  };

  public registerEventAsync = async (
    params: ApiEventRegisterParams,
  ): Promise<ApiResponse<ApiEventApprovalRegister>> => {
    try {
      const formData = new FormData();
      Object.entries(params).forEach(([k, v]) => {
        if (v instanceof Date) {
          formData.append(k, v.toISOString());
        } else if (v instanceof Array && v.length > 0) {
          formData.append(k, v[0] as string);
        } else if (v !== undefined) {
          formData.append(k, v as string);
        }
      });

      const response = await this.axios.post<ApiResponse<ApiEventApprovalRegister>>("/events/register", formData);
      return response.data;
    } catch (e) {
      return { status: ApiResponseStatus.Error, code: -1 };
    }
  };
}

export default PirikaOdpApiImplements;

import actionCreatorFactory from "typescript-fsa";
import { reducerWithInitialState } from "typescript-fsa-reducers";
import { ApiEvent, ApiEvents } from "../../api/baseApi";
import { LoadingState } from "../models";

// models
// ----------------------------------------

export type GroupEvent = ApiEvent;

// State
// ----------------------------------------

export interface GroupEventsComponentState {
  loadingState: LoadingState;
  events?: GroupEvent[];
}

const EmptyGroupEventsComponentState: GroupEventsComponentState = {
  loadingState: LoadingState.Initial,
};

export interface GroupEventsComponentsState {
  components: Record<string, GroupEventsComponentState>;
}

const initialState: GroupEventsComponentsState = {
  components: {},
};

// Parameters
// ----------------------------------------

export interface LoadGroupEventsParameters {
  componentId: string;
  organizationId: number;
  pageId: number;
  groupId: number;
  cursorId?: number;
}

// Support Functions
// ----------------------------------------

const lookupComponent = (
  state: GroupEventsComponentsState,
  id: string,
): [GroupEventsComponentsState, GroupEventsComponentState] => {
  if (!state.components[id]) {
    const newComponent = { ...EmptyGroupEventsComponentState };
    const newComponents = { ...state.components };
    newComponents[id] = newComponent;
    return [{ ...state, components: newComponents }, newComponent];
  }
  return [state, state.components[id]];
};

const updateComponent = (
  state: GroupEventsComponentsState,
  id: string,
  component: GroupEventsComponentState,
): GroupEventsComponentsState => {
  const newComponents = { ...state.components };
  newComponents[id] = component;
  return { ...state, components: newComponents };
};

// Selectors
// ----------------------------------------

// ActionCreators
// ----------------------------------------

const actionCreator = actionCreatorFactory("PirikaOdp/GroupEventsComponents");

export const loadGroupEvents = actionCreator<LoadGroupEventsParameters>("LoadGroupEvents");
export const loadGroupEventsProgress = actionCreator.async<LoadGroupEventsParameters, ApiEvents, Error>(
  "LoadGroupEventsProgress",
);

// Reducer
// ----------------------------------------

const reducer = reducerWithInitialState(initialState)
  .case(loadGroupEventsProgress.started, (state, { componentId }) => {
    const [newState, component] = lookupComponent(state, componentId);
    return updateComponent(newState, componentId, {
      ...component,
      loadingState: LoadingState.Loading,
    });
  })
  .case(loadGroupEventsProgress.done, (state, { params, result }) => {
    const [newState, component] = lookupComponent(state, params.componentId);
    const events = params.cursorId && component.events ? [...component.events, ...result.events] : result.events;
    return updateComponent(newState, params.componentId, {
      ...component,
      loadingState: LoadingState.Initial,
      events,
    });
  })
  .case(loadGroupEventsProgress.failed, (state, { params }) => {
    const [newState, component] = lookupComponent(state, params.componentId);
    return updateComponent(newState, params.componentId, {
      ...component,
      loadingState: LoadingState.Error,
    });
  });

export default reducer;

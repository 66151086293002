import { createStyles, StyleRules } from "@material-ui/core";

const styles = (/* theme: Theme */): StyleRules =>
  createStyles({
    root: {
      width: "100%",
      height: "100%",
      display: "flex",
      flexDirection: "column",
    },
  });
export default styles;

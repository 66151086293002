import { createStyles, StyleRules, Theme } from "@material-ui/core";

const styles = (theme: Theme): StyleRules =>
  createStyles({
    root: {
      transform: "translateY(calc(-100% - 15px)) translateX(-50%)",
      filter: "drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.4))",
      cursor: "default",
      width: "30em",
    },
    contents: {
      padding: theme.spacing(1.5),
      borderRadius: "4px",
      backgroundColor: "white",
      fontSize: "14px",
      fontFamily: "'Noto Sans JP', '游ゴシック体', YuGothic, '游ゴシック', 'Yu Gothic', sans-serif",
      color: "#212121",
    },
    tail: {
      position: "absolute",
      top: "100%",
      left: "50%",
      marginLeft: "-15px",
      border: "15px solid transparent",
      borderTop: "15px solid white",
    },
    postContainer: {},
    abstractContainer: {
      display: "flex",
      alignItems: "baseline",
    },
    userImageContainer: {
      paddingRight: theme.spacing(1),
    },
    userImage: {
      width: "32px",
      height: "32px",
      borderRadius: "16px",
      verticalAlign: "-20px",
    },
    abstractTextContainer: {
      display: "flex",
      flexDirection: "column",
      overflow: "hidden",
    },
    userName: {
      fontSize: "16px",
      fontWeight: "bold",
      flexGrow: 1,
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
      minWidth: 0,
    },
    date: {
      fontSize: "14px",
      color: "#5f5f5f",
      whiteSpace: "nowrap",
    },
    bodyContainer: {
      paddingTop: theme.spacing(1),
      display: "flex",
      flexDirection: "row",
    },
    thumbnailContainer: {},
    thumbnail: {
      width: "100px",
      height: "100px",
    },
    comment: {
      paddingLeft: theme.spacing(1),
      wordBreak: "break-all",
    },
    actionContainer: {
      textAlign: "center",
      paddingTop: theme.spacing(1),
    },
  });
export default styles;

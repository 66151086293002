import actionCreatorFactory from "typescript-fsa";
import { reducerWithInitialState } from "typescript-fsa-reducers";
import { LoadingState } from "../models";
import { ApiUserList, ApiUserListEntry } from "../../api/baseApi";

// models
// ----------------------------------------

export type UserListEntry = ApiUserListEntry;

export interface UserListComponentState {
  loadingState: LoadingState;
  users?: UserListEntry[];
}

const EmptyUserListComponentState: UserListComponentState = {
  loadingState: LoadingState.Initial,
};

export interface UserListComponentsState {
  components: Record<string, UserListComponentState>;
}

const initialState: UserListComponentsState = {
  components: {},
};

// State
// ----------------------------------------

// Parameters
// ----------------------------------------

export interface LoadUserListParameters {
  componentId: string;
  organizationId: number;
  pageId: number;
  userListId: number;
}

// Support Functions
// ----------------------------------------

const lookupComponent = (
  state: UserListComponentsState,
  id: string,
): [UserListComponentsState, UserListComponentState] => {
  if (!state.components[id]) {
    const newComponent = { ...EmptyUserListComponentState };
    const newComponents = { ...state.components };
    newComponents[id] = newComponent;
    return [{ ...state, components: newComponents }, newComponent];
  }
  return [state, state.components[id]];
};

const updateComponent = (
  state: UserListComponentsState,
  id: string,
  component: UserListComponentState,
): UserListComponentsState => {
  const newComponents = { ...state.components };
  newComponents[id] = component;
  return { ...state, components: newComponents };
};

// Selectors
// ----------------------------------------

// ActionCreators
// ----------------------------------------

const actionCreator = actionCreatorFactory("PirikaOdp/UserListComponents");

export const loadUserList = actionCreator<LoadUserListParameters>("LoadUserList");
export const loadUserListProgress = actionCreator.async<LoadUserListParameters, ApiUserList, Error>(
  "LoadUserListProgress",
);

// Reducer
// ----------------------------------------

const reducer = reducerWithInitialState(initialState)
  .case(loadUserListProgress.started, (state, { componentId }) => {
    const [newState, component] = lookupComponent(state, componentId);
    return updateComponent(newState, componentId, {
      ...component,
      loadingState: LoadingState.Loading,
    });
  })
  .case(loadUserListProgress.done, (state, { params, result }) => {
    const [newState, component] = lookupComponent(state, params.componentId);
    return updateComponent(newState, params.componentId, {
      ...component,
      loadingState: LoadingState.Initial,
      users: result.entities,
    });
  })
  .case(loadUserListProgress.failed, (state, { params }) => {
    const [newState, component] = lookupComponent(state, params.componentId);
    return updateComponent(newState, params.componentId, {
      ...component,
      loadingState: LoadingState.Error,
    });
  });

export default reducer;

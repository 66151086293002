import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { RootState } from "../../../../modules";
import { MapRegionProps, RelativeRegionCounters } from "../../../../components/dashboard/components/map";
import {
  loadPrefectureRegion as loadPrefectureRegionAction,
  loadPrefectureRegionActivities as loadPrefectureRegionActivitiesAction,
  loadPrefectureRegionGeoJson as loadPrefectureRegionGeoJsonAction,
  PopulationData,
  PrefectureRegion,
  PrefectureRegionDataboxState,
  PrefectureRegionPolygonCounters,
  RelativePrefectureRegionAggregateMode,
  updateRelativePrefectureRegionAggregateModeAction,
} from "../../../../modules/prefectureRegionComponents";
import { generatePeriods } from "../../../../helpers/date";
import { ApiActivityDataPoint } from "../../../../api/baseApi";
import RelativePrefectureRegionLegendContainer from "./MapRegionLegendContainer";
import Constants from "../../../../constants";
import { GeoJson, LoadingState, PirikaPost } from "../../../../modules/models";

type ProviderCallableChildren = (props: MapRegionProps) => React.ReactNode;

interface ExportProps {
  children: ProviderCallableChildren | React.ReactNode;
  regionLowerColor?: string;
  regionHigherColor?: string;
  usePopulationScale?: boolean;
  legendMaxTrashes?: number;
  legendMaxParticipants?: number;
}

interface StateProps {
  organizationId: number;
  openDataPageId: number;
  originPeriod: string;
  region?: PrefectureRegion;
  regionLoadingState: LoadingState;
  regionCounters?: Record<string, PrefectureRegionPolygonCounters>;
  relativeRegionAggregateMode: RelativePrefectureRegionAggregateMode;
  populations?: Record<string, PopulationData>;
  geoJson?: GeoJson;
  databoxes: Record<string, PrefectureRegionDataboxState>;
}

interface DispatchProps {
  loadPrefectureRegion: (organizationId: number, openDataPageId: number) => void;
  loadPrefectureRegionActivities: (organizationId: number, openDataPageId: number) => void;
  loadPrefectureRegionGeoJson: (topojsonUrl: string) => void;
  updateRelativeRegionAggregateMode: (mode: RelativePrefectureRegionAggregateMode) => void;
}

type Props = ExportProps & StateProps & DispatchProps;

interface State {
  relativeRegionCounters?: RelativeRegionCounters | null;
}

class MapPrefectureRegionProvider extends React.Component<Props, State> {
  public constructor(props: Props, context: unknown) {
    super(props, context);
    this.state = {
      relativeRegionCounters: null,
    };
  }

  public componentDidMount() {
    this.processRegionLoad();
  }

  public componentDidUpdate(prevProps: Readonly<Props>) {
    const { regionCounters, relativeRegionAggregateMode } = this.props;
    this.processRegionLoad(prevProps);
    if (
      prevProps.regionCounters !== regionCounters ||
      relativeRegionAggregateMode !== prevProps.relativeRegionAggregateMode
    ) {
      this.updateRelativePrefectureRegionCounters();
    }
  }

  private legends = (): React.ReactNode | undefined => {
    const {
      regionLowerColor,
      regionHigherColor,
      usePopulationScale,
      relativeRegionAggregateMode,
      updateRelativeRegionAggregateMode,
    } = this.props;
    const { relativeRegionCounters } = this.state;
    return (
      <RelativePrefectureRegionLegendContainer
        regionCounters={relativeRegionCounters ?? undefined}
        lowerColor={regionLowerColor || Constants.defaultScaleBarLowerColor}
        higherColor={regionHigherColor || Constants.defaultScaleBarHigherColor}
        maxValue={this.maxValue()}
        usePopulationScale={usePopulationScale}
        aggregateMode={relativeRegionAggregateMode}
        onUpdateAggregateMode={(aggregateMode) => updateRelativeRegionAggregateMode(aggregateMode)}
      />
    );
  };

  private maxValue = () => {
    const { legendMaxParticipants, legendMaxTrashes, relativeRegionAggregateMode } = this.props;
    return relativeRegionAggregateMode.valueType === "participants" ? legendMaxParticipants : legendMaxTrashes;
  };

  private processRegionLoad(prevProps?: Readonly<Props>) {
    const {
      organizationId,
      openDataPageId,
      region,
      regionLoadingState,
      loadPrefectureRegionActivities,
      loadPrefectureRegion,
      loadPrefectureRegionGeoJson,
    } = this.props;
    if (region === undefined && regionLoadingState === LoadingState.Initial) {
      loadPrefectureRegion(organizationId, openDataPageId);
      return;
    }
    if (region !== undefined && prevProps?.region === undefined) {
      loadPrefectureRegionGeoJson(region.topojsonUrl);
      loadPrefectureRegionActivities(organizationId, openDataPageId);
    }
  }

  private updateRelativePrefectureRegionCounters(): void {
    const { regionCounters, usePopulationScale, populations, relativeRegionAggregateMode, originPeriod } = this.props;
    const counters = regionCounters;
    if (!counters) {
      this.setState({ relativeRegionCounters: null });
      return;
    }
    const { term, valueType } = relativeRegionAggregateMode;
    const values: Record<string, number> = {};
    const [periodFrom, periodTo] = (() => {
      const now = new Date();
      const originDate = new Date(
        parseInt(originPeriod.substring(0, 4), 10),
        parseInt(originPeriod.substring(4, 6), 10) - 1,
        1,
      );
      let fromDate = now;
      if (term) {
        fromDate = new Date(now.getFullYear(), now.getMonth() - term, 1);
      }
      if (term === undefined || fromDate.getTime() < originDate.getTime()) {
        fromDate = originDate;
      }
      return [fromDate, now];
    })();
    const periods = generatePeriods(periodFrom, periodTo);

    const lookupValue = (point: ApiActivityDataPoint): number => {
      switch (valueType) {
        case "participants":
          return point.participants;
        case "trash":
          return point.trash;
        default:
          return 0;
      }
    };

    Object.keys(counters).forEach((polygonId) => {
      values[polygonId] = counters[polygonId].counters.reduce((prev: number, v: ApiActivityDataPoint) => {
        if (periods === undefined || periods.indexOf(v.period) >= 0) {
          return prev + lookupValue(v);
        }
        return prev;
      }, 0);
    });

    if (usePopulationScale && populations) {
      Object.keys(values).forEach((polygonId) => {
        const population = populations[polygonId]?.population ?? 0;
        if (population === 0) {
          values[polygonId] = 0;
        } else {
          values[polygonId] = values[polygonId] * (1 / population) * 10000;
        }
      });
    }

    this.setState({
      relativeRegionCounters: {
        maxValue: Math.ceil(Math.max(...Object.values(values))),
        minValue: Math.floor(Math.min(...Object.values(values))),
        values,
      },
    });
  }

  public render(): JSX.Element {
    const { geoJson, children, databoxes } = this.props;
    const { relativeRegionCounters } = this.state;
    const props = {
      geoJson,
      relativeRegionCounters: relativeRegionCounters ?? undefined,
      legends: this.legends(),
      maxValue: this.maxValue(),
      databoxes: Object.keys(databoxes).reduce(
        (dict, v) => ({ ...dict, [v]: databoxes[v].posts || [] }),
        {} as Record<string, PirikaPost[]>,
      ),
    };
    const content = typeof children === "function" ? (children as ProviderCallableChildren)(props) : children;
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{content}</>;
  }
}

const mapStateToProps = (state: RootState): StateProps => ({
  organizationId: state.openDataPage.pageConfigurations?.config.organizationId ?? 0,
  openDataPageId: state.openDataPage.pageConfigurations?.config.openDataPageId ?? 0,
  originPeriod: state.openDataPage.pageConfigurations?.config.originPeriod ?? "200001", // 処理を壊さないため仮置き、このコンポーネントが表示される時はundefinedになることは基本的にないので使われない
  region: state.prefectureRegionComponents.region,
  regionLoadingState: state.prefectureRegionComponents.regionLoadingState,
  regionCounters: state.prefectureRegionComponents.counters,
  relativeRegionAggregateMode: state.prefectureRegionComponents.relativeRegionAggregateMode,
  geoJson: state.prefectureRegionComponents.geoJson,
  populations: state.prefectureRegionComponents.populations,
  databoxes: state.prefectureRegionComponents.databoxes,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  loadPrefectureRegion: (organizationId: number, openDataPageId: number) =>
    dispatch(loadPrefectureRegionAction({ organizationId, pageId: openDataPageId })),
  loadPrefectureRegionActivities: (organizationId: number, openDataPageId: number) =>
    dispatch(
      loadPrefectureRegionActivitiesAction({
        organizationId,
        pageId: openDataPageId,
      }),
    ),
  loadPrefectureRegionGeoJson: (topojsonUrl) => dispatch(loadPrefectureRegionGeoJsonAction({ topojsonUrl })),
  updateRelativeRegionAggregateMode: (mode) =>
    dispatch(updateRelativePrefectureRegionAggregateModeAction({ relativeRegionAggregateMode: mode })),
});

export default connect(mapStateToProps, mapDispatchToProps)(MapPrefectureRegionProvider);

import * as React from "react";
import { WithStyles, withStyles } from "@material-ui/core";
import { withTranslation, WithTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroller";
import styles from "./TimelineListStyles";
import { LoadingState, PirikaPost } from "../../../../modules/models";
import useMediaQuery from "../../useMediaQuery";
import PlaceholderImage from "../../../../assets/placeholder.png";
import ListLoadingState from "../../../loading/ListLoadingState";

interface Props extends WithStyles<typeof styles>, WithTranslation {
  loadingState: LoadingState;
  posts: PirikaPost[];
  hasMore: boolean;
  onItemClick: (id: number) => void;
  onLoadMore: () => void;
}

const TimelineList: React.FC<Props> = ({ classes, posts, onItemClick, loadingState, hasMore, onLoadMore }) => {
  const { isS } = useMediaQuery();
  const contents = (
    <ul className={classes.ul}>
      {posts.map((v) => (
        // FIXME: 直すべき
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
        <li className={classes.timelineItem} key={v.id} onClick={() => onItemClick(v.id)}>
          <div className={classes.timelineBox}>
            <div className={classes.timelineThumbnailContainer}>
              <img className={classes.timelineThumbnail} src={v.thumbnailUrl[0] || PlaceholderImage} alt={`${v.id}`} />
            </div>
            <div className={classes.timelineInformationContainer}>
              <div className={classes.timelineAbstractContainer}>
                <div className={classes.timelineUserImageContainer}>
                  <img
                    className={classes.timelineUserImage}
                    src={v.profileImageUrl || PlaceholderImage}
                    alt={v.userName}
                  />
                </div>
                <div className={classes.timelineUserName}>{v.userName}</div>
                <div className={classes.timelineDate}>{v.prettyDate}</div>
              </div>
              <div className={classes.timelineComment}>{v.comment}</div>
            </div>
          </div>
        </li>
      ))}
    </ul>
  );

  return (
    <div className={classes.root}>
      {isS.matches ? (
        <section>
          {contents}
          <button className={classes.loadMoreButton} type="button" onClick={onLoadMore}>
            {loadingState === LoadingState.Initial && <span>もっと読む</span>}
            {loadingState === LoadingState.Loading && <ListLoadingState key="Loader" />}
            {loadingState === LoadingState.Error && <span>ページを再読み込みしてください</span>}
          </button>
        </section>
      ) : (
        <InfiniteScroll
          pageStart={0}
          loadMore={onLoadMore}
          hasMore={hasMore}
          useWindow={false}
          threshold={80}
          loader={<ListLoadingState key="Loader" />}
        >
          {contents}
        </InfiniteScroll>
      )}
    </div>
  );
};

export default withTranslation()(withStyles(styles)(TimelineList));

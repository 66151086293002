import { createStyles, StyleRules, Theme } from "@material-ui/core";

const styles = (theme: Theme): StyleRules =>
  createStyles({
    root: {},
    frame: {
      display: "flex",
      flexDirection: "row",
      [theme.breakpoints.down(600)]: {
        flexDirection: "column",
      },
    },
    frameSection: {
      flexGrow: 1,
    },
    section: {},
    sectionTitle: {
      fontWeight: "bold",
      fontSize: "14px",
      marginTop: 0,
      marginBottom: theme.spacing(1),
    },
    list: {
      listStyle: "none",
      marginTop: 0,
      marginBottom: theme.spacing(2),
      paddingLeft: 0,
    },
    listItem: {
      fontSize: "14px",
      marginBottom: theme.spacing(0.5),
    },
    itemLink: {
      color: "#1470cc",
    },
  });
export default styles;

import { createStyles, StyleRules, Theme } from "@material-ui/core";

const styles = (theme: Theme): StyleRules =>
  createStyles({
    root: {
      display: "flex",
      marginBottom: theme.spacing(1),
      alignItems: "flex-start",
    },
    title: {
      margin: 0,
      fontSize: "16px",
      lineHeight: "16px",
      fontWeight: "bold",
      flexGrow: 1,
    },
    accessoryLinkContainer: {
      lineHeight: "14px",
      fontSize: "14px",
    },
    accessoryLink: {
      color: "#1470CC",
    },
    accessoryTooltipContainer: {},
    accessoryButtonContainer: {
      height: "16px",
      width: "30px",
      position: "relative",
    },
    accessoryTooltipButton: {
      position: "absolute",
      right: "-6px",
      top: "-7px",
    },
  });
export default styles;

import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import MapContents from "../../../../components/dashboard/components/map/MapContents";
import { RootState } from "../../../../modules";
import { ApiPageContractType, MapPageComponent, PageDatabox } from "../../../../api/odpApi";
import {
  DataboxComponentState,
  DataboxRegionFocus,
  focusDataboxRegionAction,
  loadDatabox as loadDataboxAction,
  RegionFocusType,
  selectDataboxItem as selectDataboxItemAction,
} from "../../../../modules/databoxComponents";
import { getUploadedFileUrl, PageContractType, selectDataboxConfig } from "../../../../modules/openDataPage";
import { MapRegionProps } from "../../../../components/dashboard/components/map";
import { GeoBounds, GeoPoint } from "../../../../modules/geolocation";
import Constants from "../../../../constants";
import { DataboxQueryParameter, LoadingState, PirikaPost } from "../../../../modules/models";

interface ExportProps {
  component: MapPageComponent;
  regionProps?: MapRegionProps;
}

interface StateProps {
  organizationId: number;
  openDataPageId: number;
  databoxes: Record<string, DataboxComponentState>;
  databoxConfig: PageDatabox | undefined;
  pageType: PageContractType;
}

interface DispatchProps {
  loadDatabox: (
    databoxConfig: PageDatabox,
    componentId: string,
    organizationId: number,
    pageId: number,
    params: DataboxQueryParameter,
  ) => void;
  selectDataboxItem: (databoxId: string, postId?: number) => void;
  focusDataboxRegion: (databoxId: string, region: DataboxRegionFocus) => void;
}

type Props = ExportProps & StateProps & DispatchProps;

interface State {
  pinHovered: boolean;
}

const getLoadingState = (databox: DataboxComponentState): LoadingState =>
  (databox?.posts === undefined && databox?.loadingState !== LoadingState.Error) || databox?.loadingState === undefined
    ? LoadingState.Loading
    : databox.loadingState;

const handleShowDetail = (id: number) => {
  window.open(`${Constants.snsServiceUrl}/d/${id}`, "_blank", "noreferrer");
};

class MapContentsContainer extends React.Component<Props, State> {
  public constructor(props: Readonly<Props> | Props) {
    super(props);
    this.state = { pinHovered: false };
  }

  public componentDidMount() {
    const { component, organizationId, openDataPageId, databoxConfig, loadDatabox } = this.props;
    if (!databoxConfig) {
      return;
    }
    loadDatabox(databoxConfig, component.id, organizationId, openDataPageId, {
      count: Constants.defaultDataboxItemCount,
    });
  }

  private getDataboxId = () => {
    const { component } = this.props;
    return component.config.databox;
  };

  private handleItemClick = (id: number) => {
    const { selectDataboxItem } = this.props;
    selectDataboxItem(this.getDataboxId(), id);
  };

  private handleClearSelected = () => {
    const { selectDataboxItem } = this.props;
    selectDataboxItem(this.getDataboxId(), undefined);
  };

  private handleChangeBounds = (bounds: GeoBounds, center: GeoPoint, zoomLevel: number) => {
    const { component, organizationId, openDataPageId, pageType, databoxConfig, loadDatabox } = this.props;
    if (!databoxConfig) {
      return;
    }
    if (pageType !== ApiPageContractType.Organization) {
      return;
    }
    loadDatabox(databoxConfig, component.id, organizationId, openDataPageId, {
      latlng: `${center.latitude},${center.longitude}`,
      // API(Geocell)のズームレベル(1ズーム=1/16)に対応させるため
      zoomLevel: Math.floor(zoomLevel / 2),
    });
  };

  private handleRegionClick = (regionId: string): void => {
    const { component, focusDataboxRegion } = this.props;
    const { pinHovered } = this.state;
    if (pinHovered) {
      return;
    }
    const type: RegionFocusType | undefined = (() => {
      switch (component.config.regionDrawType) {
        case "prefecture":
          return RegionFocusType.Prefecture;
        case "static":
        case "relative":
          return RegionFocusType.Custom;
        default:
          return undefined;
      }
    })();
    if (!type) {
      return;
    }
    focusDataboxRegion(this.getDataboxId(), { type, regionId });
  };

  /**
   * カスタマイズピン画像が設定されている場合、画像のURLを返す。
   * なければundefinedになる
   */
  private customizePinImageUrl = (): string | undefined => {
    const { component, organizationId, openDataPageId } = this.props;
    if (!component.config.customizePinImage || organizationId === 0 || openDataPageId === 0) {
      return undefined;
    }
    return getUploadedFileUrl(organizationId, openDataPageId, component.config.customizePinImage);
  };

  private handlePinHoverChanged = (pinHovered: boolean) => this.setState({ pinHovered });

  public render(): JSX.Element {
    const { component, databoxes, regionProps } = this.props;
    if (!component) {
      // eslint-disable-next-line react/jsx-no-useless-fragment
      return <></>;
    }
    const databoxId = this.getDataboxId();
    const databox = databoxes[databoxId];
    const posts = (() => {
      const regionId = databox?.focusedRegion?.regionId;
      if (!regionId) {
        return databox?.posts ?? [];
      }
      const mergedPosts: PirikaPost[] = (databox?.posts ?? []).concat(regionProps?.databoxes[regionId] || []);
      return Array.from(new Map(mergedPosts.map((v) => [v.id, v])).values());
    })();
    const selectedPost = posts.find((v) => v.id === databox.selectedId);
    const regionLowerColor = component.config.regionLowerColor || Constants.defaultScaleBarLowerColor;
    const regionHigherColor = component.config.regionHigherColor || Constants.defaultScaleBarHigherColor;
    const { regionStrokeColor } = component.config;
    return (
      <MapContents
        component={component}
        loadingState={getLoadingState(databox)}
        posts={posts}
        selectedPost={selectedPost}
        geoJson={regionProps?.geoJson}
        regionCounters={regionProps?.relativeRegionCounters}
        regionLowerColor={regionLowerColor}
        regionHigherColor={regionHigherColor}
        regionStrokeColor={regionStrokeColor}
        legends={regionProps?.legends}
        maxValue={regionProps?.maxValue}
        onItemClick={this.handleItemClick}
        onClearSelected={this.handleClearSelected}
        onChangeBounds={this.handleChangeBounds}
        onShowDetail={handleShowDetail}
        onRegionClick={this.handleRegionClick}
        onPinHoverChanged={this.handlePinHoverChanged}
        customizePinImageUrl={this.customizePinImageUrl()}
      />
    );
  }
}

const mapStateToProps = (state: RootState, ownProps: ExportProps): StateProps => ({
  databoxes: state.databoxComponents.databoxes,
  organizationId: state.openDataPage.pageConfigurations?.config.organizationId ?? 0,
  openDataPageId: state.openDataPage.pageConfigurations?.config.openDataPageId ?? 0,
  databoxConfig: selectDataboxConfig(state, ownProps.component.config.databox),
  pageType: state.openDataPage.pageConfigurations?.config.type ?? ApiPageContractType.Organization,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  loadDatabox: (databoxConfig, componentId, organizationId, pageId, parameter) =>
    dispatch(
      loadDataboxAction({
        databox: databoxConfig,
        componentId,
        organizationId,
        pageId,
        parameter,
      }),
    ),
  selectDataboxItem: (databoxId, postId) => dispatch(selectDataboxItemAction({ databoxId, postId })),
  focusDataboxRegion: (databoxId, region: DataboxRegionFocus) =>
    dispatch(focusDataboxRegionAction({ databoxId, region })),
});

export default connect(mapStateToProps, mapDispatchToProps)(MapContentsContainer);

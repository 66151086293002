import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import RankingComponent from "../../../components/dashboard/components/RankingComponent";
import { RootState } from "../../../modules";
import { RankingPageComponent } from "../../../api/odpApi";
import { loadRanking as loadRankingAction, RankingComponentState } from "../../../modules/rankingComponents";
import { LoadingState } from "../../../modules/models";

interface ExportProps {
  component: RankingPageComponent;
}

interface StateProps {
  organizationId: number;
  openDataPageId: number;
  componentsStates: Record<string, RankingComponentState>;
}

interface DispatchProps {
  loadRanking: (
    componentId: string,
    organizationId: number,
    pageId: number,
    mode: string,
    aggregateBy: string,
    period: string,
    exceptUserListId?: number,
  ) => void;
}

type Props = ExportProps & StateProps & DispatchProps;

class RankingComponentContainer extends React.Component<Props> {
  public componentDidMount() {
    const { loadRanking, component, organizationId, openDataPageId } = this.props;
    loadRanking(
      component.id,
      organizationId,
      openDataPageId,
      component.config.mode,
      component.config.aggregateBy,
      component.config.period,
      component.config.exceptUserListId,
    );
  }

  public render(): JSX.Element {
    const { component, componentsStates } = this.props;
    const states = componentsStates[component.id];
    const loadingState =
      (states?.places === undefined && states?.loadingState !== LoadingState.Error) ||
      states?.loadingState === undefined
        ? LoadingState.Loading
        : states.loadingState;
    const places = states?.places ?? [];
    return <RankingComponent component={component} loadingState={loadingState} places={places} />;
  }
}

const mapStateToProps = (state: RootState): StateProps => ({
  componentsStates: state.rankingComponents.components,
  organizationId: state.openDataPage.pageConfigurations?.config.organizationId ?? 0,
  openDataPageId: state.openDataPage.pageConfigurations?.config.openDataPageId ?? 0,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  loadRanking: (componentId, organizationId, pageId, mode, aggregateBy, period, exceptUserListId) =>
    dispatch(
      loadRankingAction({
        componentId,
        organizationId,
        pageId,
        mode,
        aggregateBy,
        period,
        exceptUserListId,
      }),
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(RankingComponentContainer);

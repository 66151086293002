import { createStyles, StyleRules, Theme } from "@material-ui/core";

const styles = (theme: Theme): StyleRules =>
  createStyles({
    root: {
      minHeight: "234px",
    },
    items: {
      [theme.breakpoints.up(961)]: {
        maxHeight: `${70 * 5 + 26}px`,
        overflowY: "scroll",
      },
    },
    emptyState: {
      minHeight: "80px",
      height: "100%",
      fontSize: "16px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  });
export default styles;

import * as React from "react";
import { WithStyles, withStyles } from "@material-ui/core";
import { withTranslation, WithTranslation } from "react-i18next";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import styles from "./ComponentErrorStateViewStyles";

interface Props extends WithStyles<typeof styles>, WithTranslation {}

const ComponentErrorStateView: React.FC<Props> = ({ classes }) => (
  <div className={classes.root}>
    <ErrorOutlineIcon fontSize="large" />
    <p className={classes.caption}>データの読み込みに失敗しました</p>
  </div>
);

export default withTranslation()(withStyles(styles)(ComponentErrorStateView));

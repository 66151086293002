import * as React from "react";
import { Grid, GridSize, WithStyles, withStyles } from "@material-ui/core";
import { withTranslation, WithTranslation } from "react-i18next";
import classNames from "classnames";
import styles from "./UserEntryStyles";
import { UserListEntry } from "../../../../modules/userListComponents";
import Constants from "../../../../constants";
import UserIconDisplay from "./UserIconDisplay";
import UserListDisplay from "./UserListDisplay";

interface Props extends WithStyles<typeof styles>, WithTranslation {
  user: UserListEntry;
  useIcon: boolean;
  xs: GridSize;
  sm: GridSize;
  md: GridSize;
}

const UserEntry: React.FC<Props> = ({ user, useIcon, xs, sm, md, classes }) => (
  <Grid item xs={xs} sm={sm} md={md}>
    <a
      key={user.id}
      className={classNames(classes.userLink, useIcon ? classes.iconLink : classes.listLink)}
      href={`${Constants.snsServiceUrl}/u/${user.id}`}
    >
      {useIcon && <UserIconDisplay user={user} />}
      {!useIcon && <UserListDisplay user={user} />}
    </a>
  </Grid>
);

export default withTranslation()(withStyles(styles)(UserEntry));

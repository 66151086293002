import { createStyles, StyleRules, Theme } from "@material-ui/core";

const styles = (theme: Theme): StyleRules =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      minHeight: "100px",
      height: "100%",
      justifyContent: "center",
      alignItems: "center",
    },
    caption: {
      marginTop: theme.spacing(1),
      marginBottom: 0,
    },
  });
export default styles;

import { applyMiddleware, combineReducers, createStore as reduxCreateStore, Store } from "redux";
import { connectRouter, routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";
import createSagaMiddleware from "redux-saga";
import logger from "redux-logger";
import { all } from "redux-saga/effects";

import openDataPageReducer from "./modules/openDataPage";
import openDataPageSaga from "./modules/openDataPage/saga";
import counterGraphComponentsReducer from "./modules/counterGraphComponents";
import counterGraphComponentsSaga from "./modules/counterGraphComponents/saga";
import rankingComponentsReducer from "./modules/rankingComponents";
import rankingComponentsSaga from "./modules/rankingComponents/saga";
import informationComponentsReducer from "./modules/informationComponents";
import informationComponentsSaga from "./modules/informationComponents/saga";
import areaEventsComponentsReducer from "./modules/areaEventsComponents";
import areaEventsComponentsSaga from "./modules/areaEventsComponents/saga";
import groupEventsComponentsReducer from "./modules/groupEventsComponents";
import groupEventsComponentsSaga from "./modules/groupEventsComponents/saga";
import organizationsComponentsReducer from "./modules/organizationsComponents";
import organizationsComponentsSaga from "./modules/organizationsComponents/saga";
import counterComponentsReducer from "./modules/counterComponents";
import counterComponentsSaga from "./modules/counterComponents/saga";
import databoxComponentsReducer from "./modules/databoxComponents";
import databoxComponentsSaga from "./modules/databoxComponents/saga";
import regionComponentsReducer from "./modules/regionComponents";
import regionComponentsSaga from "./modules/regionComponents/saga";
import prefectureRegionComponentsReducer from "./modules/prefectureRegionComponents";
import prefectureRegionComponentsSaga from "./modules/prefectureRegionComponents/saga";
import userListComponentsReducer from "./modules/userListComponents";
import userListComponentsSaga from "./modules/userListComponents/saga";
import eventRegisterModalReducer from "./modules/eventRegisterModal";
import eventRegisterModalSaga from "./modules/eventRegisterModal/saga";
import eventsReducer from "./modules/events";
import eventsSaga from "./modules/events/saga";
import windowReducer from "./modules/window";
import windowSaga from "./modules/window/saga";

const sagaMiddleware = createSagaMiddleware();
function* rootSaga(): Generator {
  yield all([
    openDataPageSaga(),
    counterGraphComponentsSaga(),
    rankingComponentsSaga(),
    informationComponentsSaga(),
    areaEventsComponentsSaga(),
    groupEventsComponentsSaga(),
    organizationsComponentsSaga(),
    counterComponentsSaga(),
    databoxComponentsSaga(),
    regionComponentsSaga(),
    prefectureRegionComponentsSaga(),
    userListComponentsSaga(),
    eventRegisterModalSaga(),
    eventsSaga(),
    windowSaga(),
  ]);
}

export const history = createBrowserHistory();

const createStore = (): Store => {
  const reducer = combineReducers({
    router: connectRouter(history),
    eventRegisterModal: eventRegisterModalReducer,
    openDataPage: openDataPageReducer,
    counterGraphComponents: counterGraphComponentsReducer,
    rankingComponents: rankingComponentsReducer,
    informationComponents: informationComponentsReducer,
    areaEventsComponents: areaEventsComponentsReducer,
    groupEventsComponents: groupEventsComponentsReducer,
    organizationsComponents: organizationsComponentsReducer,
    counterComponents: counterComponentsReducer,
    databoxComponents: databoxComponentsReducer,
    regionComponents: regionComponentsReducer,
    prefectureRegionComponents: prefectureRegionComponentsReducer,
    userListComponents: userListComponentsReducer,
    events: eventsReducer,
    window: windowReducer,
  });
  const middleware =
    process.env.NODE_ENV === "production"
      ? applyMiddleware(sagaMiddleware, routerMiddleware(history))
      : applyMiddleware(sagaMiddleware, routerMiddleware(history), logger);
  const store = reduxCreateStore(reducer, middleware);
  sagaMiddleware.run(rootSaga);

  return store;
};
export default createStore;

import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { replace } from "connected-react-router";
import NotFoundPage from "../../components/pages/NotFoundPage";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ExportProps {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface StateProps {}

interface DispatchProps {
  redirectToTop: () => void;
}

type Props = ExportProps & StateProps & DispatchProps;

class NotFoundPageContainer extends React.Component<Props> {
  public componentDidMount() {
    const { redirectToTop } = this.props;
    redirectToTop();
  }

  public render(): JSX.Element {
    return <NotFoundPage />;
  }
}

const mapStateToProps = (/* state: RootState */): StateProps => ({});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  redirectToTop: () => dispatch(replace("/")),
});

export default connect(mapStateToProps, mapDispatchToProps)(NotFoundPageContainer);

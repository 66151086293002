import { createStyles, StyleRules, Theme } from "@material-ui/core";

const styles = (theme: Theme): StyleRules =>
  createStyles({
    root: {
      position: "relative",
      padding: theme.spacing(1.5),
    },
  });
export default styles;

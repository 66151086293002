import * as React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, WithStyles, withStyles } from "@material-ui/core";
import { withTranslation, WithTranslation } from "react-i18next";
import styles from "./AcknowledgementsDialogStyles";
import { PrefectureRegion } from "../../../../modules/prefectureRegionComponents";
import { Region } from "../../../../modules/regionComponents";

interface Props extends WithStyles<typeof styles>, WithTranslation {
  open: boolean;
  hasPrefectureRegionData: boolean;
  // eslint-disable-next-line react/no-unused-prop-types
  hasRegionData: boolean;
  prefectureRegion?: PrefectureRegion;
  region?: Region;
  onCloseClick: () => void;
}

const AcknowledgementsDialog: React.FC<Props> = ({
  open,
  hasPrefectureRegionData,
  prefectureRegion,
  region,
  onCloseClick,
}) => (
  <Dialog open={open} onClose={onCloseClick}>
    <DialogTitle>使用データについて</DialogTitle>
    <DialogContent>
      <p>
        このページでは、外部から提供されている以下のデータを基に、株式会社ピリカが加工して使用しているデータがあります。
      </p>
      <ul>
        <li>
          <a href="https://uedayou.net/loa/" target="_blank" rel="noopener noreferrer nofollow">
            Linked Open Addresses Japan
          </a>
        </li>
        <li>
          <a
            href="https://nlftp.mlit.go.jp/ksj/gml/datalist/KsjTmplt-N03-v3_0.html"
            target="_blank"
            rel="noopener noreferrer nofollow"
          >
            国土交通省国土数値情報ダウンロードサイト
          </a>
        </li>
        <li>
          <a href="https://www.e-stat.go.jp/" target="_blank" rel="noopener noreferrer nofollow">
            政府統計の総合窓口(e-Stat)
          </a>
        </li>
        {hasPrefectureRegionData && (
          <li>
            <a href="https://opendata.resas-portal.go.jp/" target="_blank" rel="noopener noreferrer nofollow">
              RESAS（地域経済分析システム）
            </a>
          </li>
        )}
      </ul>
      <p>
        これらの提供元の情報から作成されたデータは、
        <a
          href="https://creativecommons.org/licenses/by-sa/4.0/deed.ja"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          クリエイティブ・コモンズ 表示 - 継承 4.0 国際 (CC BY-SA 4.0)ライセンス
        </a>
        に基づいてこちらからダウンロードできます。
        ライセンスの規定の通り、サイトの運営者並びに株式会社ピリカは内容について保証しないものとします。
      </p>
      <h3>使用データ</h3>
      <ul>
        {prefectureRegion && (
          <li>
            市区町村ポリゴンデータ{" "}
            <a href={prefectureRegion.geojsonUrl} target="_blank" rel="noopener noreferrer nofollow">
              GeoJSON
            </a>{" "}
            <a href={prefectureRegion.topojsonUrl} target="_blank" rel="noopener noreferrer nofollow">
              TopoJSON
            </a>
          </li>
        )}
        {region && (
          <li>
            ポリゴンデータ{" "}
            <a href={region.geojsonUrl} target="_blank" rel="noopener noreferrer nofollow">
              GeoJSON
            </a>{" "}
            <a href={region.topojsonUrl} target="_blank" rel="noopener noreferrer nofollow">
              TopoJSON
            </a>
          </li>
        )}
      </ul>
    </DialogContent>
    <DialogActions>
      <Button onClick={onCloseClick}>閉じる</Button>
    </DialogActions>
  </Dialog>
);

export default withTranslation()(withStyles(styles)(AcknowledgementsDialog));

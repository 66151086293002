import * as React from "react";
import { connect } from "react-redux";
import ConfigurationLoadingStateScreen from "../../components/loading/ConfigurationLoadingStateScreen";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ExportProps {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface StateProps {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface DispatchProps {}

type Props = ExportProps & StateProps & DispatchProps;

class ConfigurationLoadingStateScreenContainer extends React.PureComponent<Props> {
  public render(): JSX.Element {
    return <ConfigurationLoadingStateScreen />;
  }
}

const mapStateToProps = (/* state: RootState */): StateProps => ({});

const mapDispatchToProps = (/* dispatch: Dispatch */): DispatchProps => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ConfigurationLoadingStateScreenContainer);

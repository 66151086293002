import * as React from "react";
import { WithStyles, withStyles } from "@material-ui/core";
import { withTranslation, WithTranslation } from "react-i18next";
import styles from "./CounterComponentStyles";
import WidgetGridItem from "./WidgetGridItem";
import { CounterPageComponent } from "../../../api/odpApi";
import { CounterData } from "../../../modules/counterComponents";
import Nl2Br from "./parts/Nl2Br";
import { LoadingState } from "../../../modules/models";
import { getContrastedColor } from "../../functions";
import Counter from "./parts/Counter";

interface Props extends WithStyles<typeof styles>, WithTranslation {
  component: CounterPageComponent;
  // eslint-disable-next-line react/no-unused-prop-types
  loadingState: LoadingState;
  counter?: CounterData;
}

const CounterComponent: React.FC<Props> = ({ component, counter, classes }) => (
  <WidgetGridItem component={component}>
    <div
      className={classes.root}
      style={{
        backgroundColor: component.config.backgroundColor,
        color: getContrastedColor(component.config.backgroundColor),
      }}
    >
      <div className={classes.counterOuterContainer}>
        <div className={classes.counterContainer}>
          {component.config.showParticipants && (
            <div className={classes.counter}>
              <div className={classes.counterTitle}>
                <Nl2Br text={component.config.participantsTitle} />
              </div>
              <div className={classes.counterDigitContainer}>
                <Counter value={counter?.participants ?? -1} fills={7} digitClassName={classes.counterDigit} />
              </div>
            </div>
          )}
          {component.config.showPieces && (
            <div className={classes.counter}>
              <div className={classes.counterTitle}>
                <Nl2Br text={component.config.piecesTitle} />
              </div>
              <div className={classes.counterDigitContainer}>
                <Counter value={counter?.trash ?? -1} fills={10} digitClassName={classes.counterDigit} />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  </WidgetGridItem>
);

export default withTranslation()(withStyles(styles)(CounterComponent));

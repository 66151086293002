import * as React from "react";
import { WithStyles, withStyles } from "@material-ui/core";
import { withTranslation, WithTranslation } from "react-i18next";
import classNames from "classnames";
import styles from "./TimelineComponentStyles";
import WidgetGridItem from "./WidgetGridItem";
import { TimelinePageComponent } from "../../../api/odpApi";
import useMediaQuery from "../useMediaQuery";

interface Props extends WithStyles<typeof styles>, WithTranslation {
  component: TimelinePageComponent;
}

const TimelineComponent: React.FC<Props> = ({ component, children, classes }) => {
  const { isS } = useMediaQuery();
  return (
    <WidgetGridItem component={component}>
      <div
        className={classNames(classes.root, !isS.matches ? classes.rootFullSize : undefined)}
        style={{
          maxHeight: component.config.customizeHeight ? `${component.config.customizeHeight}px` : "400px",
        }}
      >
        {children}
      </div>
    </WidgetGridItem>
  );
};

export default withTranslation()(withStyles(styles)(TimelineComponent));

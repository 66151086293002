import { createStyles, StyleRules, Theme } from "@material-ui/core";

const styles = (theme: Theme): StyleRules =>
  createStyles({
    root: {},
    user: {
      display: "flex",
      alignItems: "center",
      whiteSpace: "nowrap",
      overflow: "hidden",
      justifyContent: "flex-start",
      textDecoration: "none",
      marginBottom: theme.spacing(1),
    },
    userImage: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: "64px",
      height: "64px",
      borderRadius: "32px",
      backgroundColor: "#e2e2e2",
      display: "block",
      verticalAlign: "-7px",
      flexShrink: 0,
    },
    userName: {
      flex: "1 1 auto",
      overflow: "hidden",
      textOverflow: "ellipsis",
      minWidth: 0,
      flexGrow: 0,
      fontSize: "18px",
    },
  });

export default styles;

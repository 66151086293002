import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import TimelineList from "../../../../components/dashboard/components/timeline/TimelineList";
import { TimelinePageComponent } from "../../../../api/odpApi";
import { LoadingState, PirikaPost } from "../../../../modules/models";
import { selectDataboxItem as selectDataboxItemAction } from "../../../../modules/databoxComponents";

interface ExportProps {
  component: TimelinePageComponent;
  loadingState: LoadingState;
  posts: PirikaPost[];
  hasMore: boolean;
  handleLoadMore: () => void;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface StateProps {}

interface DispatchProps {
  selectDataboxItem: (databoxId: string, postId?: number) => void;
}

type Props = ExportProps & StateProps & DispatchProps;

class TimelineListContainer extends React.PureComponent<Props> {
  private handleItemClick = (id: number) => {
    const { selectDataboxItem } = this.props;
    selectDataboxItem(this.getDataboxId(), id);
  };

  private getDataboxId = () => {
    const { component } = this.props;
    return component.config.databox;
  };

  public render(): JSX.Element {
    const { loadingState, posts, hasMore, handleLoadMore } = this.props;

    return (
      <TimelineList
        loadingState={loadingState}
        posts={posts}
        hasMore={hasMore}
        onItemClick={this.handleItemClick}
        onLoadMore={handleLoadMore}
      />
    );
  }
}

const mapStateToProps = (/* state: RootState */): StateProps => ({});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  selectDataboxItem: (databoxId, postId) => dispatch(selectDataboxItemAction({ databoxId, postId })),
});

export default connect(mapStateToProps, mapDispatchToProps)(TimelineListContainer);

import { createStyles, StyleRules } from "@material-ui/core";

const styles = (/* theme: Theme */): StyleRules =>
  createStyles({
    root: {
      flexGrow: 1,
      minHeight: "268px",
    },
  });
export default styles;

import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import UserListComponent from "../../../components/dashboard/components/UserListComponent";
import { UserListPageComponent } from "../../../api/odpApi";
import { loadUserList as loadUserListAction, UserListComponentState } from "../../../modules/userListComponents";
import { RootState } from "../../../modules";
import { LoadingState } from "../../../modules/models";

interface ExportProps {
  component: UserListPageComponent;
}

interface StateProps {
  organizationId: number;
  openDataPageId: number;
  componentsStates: Record<string, UserListComponentState>;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface DispatchProps {
  loadUserList: (componentId: string, organizationId: number, pageId: number, userListId: number) => void;
}

type Props = ExportProps & StateProps & DispatchProps;

class UserListComponentContainer extends React.PureComponent<Props> {
  public componentDidMount() {
    const { loadUserList, component, organizationId, openDataPageId } = this.props;
    loadUserList(component.id, organizationId, openDataPageId, component.config.userListId);
  }

  public render(): JSX.Element {
    const { component, componentsStates } = this.props;
    const states = componentsStates[component.id];
    const loadingState =
      (states?.users === undefined && states?.loadingState !== LoadingState.Error) || states?.loadingState === undefined
        ? LoadingState.Loading
        : states.loadingState;
    const users = states?.users ?? [];
    return <UserListComponent component={component} loadingState={loadingState} users={users} />;
  }
}

const mapStateToProps = (state: RootState): StateProps => ({
  organizationId: state.openDataPage.pageConfigurations?.config.organizationId ?? 0,
  openDataPageId: state.openDataPage.pageConfigurations?.config.openDataPageId ?? 0,
  componentsStates: state.userListComponents.components,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  loadUserList: (componentId, organizationId, pageId, userListId) =>
    dispatch(loadUserListAction({ componentId, organizationId, pageId, userListId })),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserListComponentContainer);

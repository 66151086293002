import * as React from "react";
import { WithStyles, withStyles } from "@material-ui/core";
import { withTranslation, WithTranslation } from "react-i18next";
import styles from "./PhotoGalleryComponentStyles";
import { PhotoGalleryPageComponent } from "../../../api/odpApi";
import WidgetGridItem from "./WidgetGridItem";
import useMediaQuery from "../useMediaQuery";

interface Props extends WithStyles<typeof styles>, WithTranslation {
  component: PhotoGalleryPageComponent;
  baseUrl: string;
}

const PhotoGalleryComponent: React.FC<Props> = ({ component, baseUrl, classes }) => {
  const mediaQuery = useMediaQuery();
  const image = (() => {
    if (mediaQuery.isS.matches && component.config.smallImage) {
      return component.config.smallImage;
    }
    if (mediaQuery.isM.matches && component.config.middleImage) {
      return component.config.middleImage;
    }
    return component.config.largeImage;
  })();

  return (
    <WidgetGridItem component={component}>
      <div className={classes.root}>
        {image && <img className={classes.image} src={`${baseUrl}${image}`} alt="Gallery" />}
      </div>
    </WidgetGridItem>
  );
};

export default withTranslation()(withStyles(styles)(PhotoGalleryComponent));

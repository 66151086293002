import * as React from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import { withTranslation, WithTranslation } from "react-i18next";
import CloseIcon from "@material-ui/icons/Close";
import { format } from "date-fns";
import styles from "./EventDialogStyles";
import ComponentLoadingStateView from "../components/parts/ComponentLoadingStateView";
import ComponentErrorStateView from "../components/parts/ComponentErrorStateView";
import { EventDetail } from "../../../modules/events";
import useMediaQuery from "../useMediaQuery";
import { ReactComponent as LocationImage } from "../../../assets/event_location.svg";
import { ReactComponent as ClockImage } from "../../../assets/event_clock.svg";
import StaticMap from "../components/parts/StaticMap";
import { LoadingState } from "../../../modules/models";

interface Props extends WithStyles<typeof styles>, WithTranslation {
  open: boolean;
  loadingState: LoadingState;
  eventDetail?: EventDetail;
  onClose: () => void;
}

const EventDialogContent: React.FC<WithStyles<typeof styles> & { eventDetail: EventDetail }> = ({
  eventDetail,
  classes,
}) => {
  const mediaQuery = useMediaQuery();

  const eventImage = ((): string => {
    if (eventDetail.imageUrl.length > 0) {
      return eventDetail.imageUrl[0];
    }
    // FIXME(iseebi): 当初この画面で扱うデータには必ず画像がついているのでデフォルト画像なしにしている
    return "";
  })();
  const dateRangeText = (() => {
    // FIXME(iseebi): DateFormatterをBizから持ってくる
    // FIXME(iseebi): 終日イベントの対応が必要(当初扱うデータにはない)
    const dateFormat = "yyyy/MM/dd";
    const timeFormat = "HH:mm";
    const dateTimeFormat = "yyyy/MM/dd HH:mm";
    if (!eventDetail.endDate) {
      return format(eventDetail.startDate, dateTimeFormat);
    }
    // 同一日の場合は終了は時間のみ
    if (format(eventDetail.startDate, dateFormat) === format(eventDetail.endDate, dateFormat)) {
      return `${format(eventDetail.startDate, dateTimeFormat)} ~ ${format(eventDetail.endDate, timeFormat)}`;
    }
    return `${format(eventDetail.startDate, dateTimeFormat)} ~ ${format(eventDetail.endDate, dateTimeFormat)}`;
  })();

  const descriptionGrid = (
    <Grid item xs={12} sm={7}>
      <Typography variant="subtitle1">{eventDetail.title}</Typography>
      <Typography className={classes.captionText} variant="body2">
        {eventDetail.authorName}
      </Typography>
      <Typography className={classes.captionText} variant="body2">
        <LocationImage className={classes.captionImage} />
        {eventDetail.place}
      </Typography>
      <Typography className={classes.captionText} variant="body2">
        <ClockImage className={classes.captionImage} />
        {dateRangeText}
      </Typography>
    </Grid>
  );
  const imageGrid = (
    <Grid item xs={12} sm={5}>
      <img className={classes.image} src={eventImage} alt={eventDetail.title} />
    </Grid>
  );
  const commentGrid = (
    <Grid item xs={12} sm={7}>
      <Typography className={classes.commentText} variant="body2">
        {eventDetail.comment}
      </Typography>
    </Grid>
  );
  const mapGrid = (
    <Grid item xs={12} sm={5}>
      <StaticMap size="225x180" markers={[{ location: [eventDetail.lat, eventDetail.lng] }]} />
    </Grid>
  );
  if (mediaQuery.isS.matches) {
    return (
      <Grid container>
        {imageGrid}
        {descriptionGrid}
        {commentGrid}
        {mapGrid}
      </Grid>
    );
  }
  return (
    <Grid container spacing={1}>
      {descriptionGrid}
      {imageGrid}
      {commentGrid}
      {mapGrid}
    </Grid>
  );
};

const EventDialog: React.FC<Props> = ({ open, loadingState, eventDetail, onClose, classes }) => (
  <Dialog open={open} fullWidth scroll="body">
    <div className={classes.dialogTitle}>
      <DialogTitle>
        <div>イベント詳細</div>
      </DialogTitle>
      <IconButton className={classes.dialogCloseButton} onClick={onClose}>
        <CloseIcon />
      </IconButton>
    </div>
    <DialogContent>
      {loadingState === LoadingState.Initial && eventDetail && (
        <EventDialogContent eventDetail={eventDetail} classes={classes} />
      )}
      {loadingState === LoadingState.Loading && <ComponentLoadingStateView />}
      {loadingState === LoadingState.Error && <ComponentErrorStateView />}
    </DialogContent>
  </Dialog>
);

export default withTranslation()(withStyles(styles)(EventDialog));

import { createStyles, StyleRules, Theme } from "@material-ui/core";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const styles = (theme: Theme): StyleRules =>
  createStyles({
    regionAggregateMode: {
      position: "absolute",
      right: "6px",
      top: "6px",
    },
    regionAggregateModeButton: {
      backgroundColor: "white",
    },
    regionAggregateModeContentFrame: {
      padding: theme.spacing(2),
    },
    regionColorScale: {
      position: "absolute",
      left: "6px",
      bottom: "28px",
      width: "60%",
    },
    regionColorScaleInnerContainer: {
      display: "flex",
      padding: "0px 8px",
      alignItems: "center",
    },
    regionColorScaleBarLegendContainer: {
      verticalAlign: "2px",
    },
    regionColorScaleBarLegend: {
      fontSize: theme.typography.caption.fontSize,
      lineHeight: "6px",
      verticalAlign: "2px",
    },
    regionColorScaleBarContainer: {
      flexGrow: 1,
      padding: "0 8px",
    },
    regionColorScaleBar: {
      width: "100%",
      height: "12px",
      backgroundColor: "red",
    },
  });
export default styles;

import { createStyles, StyleRules } from "@material-ui/core";

const styles = (/* theme: Theme */): StyleRules =>
  createStyles({
    root: {
      position: "relative",
      height: "100%",
      /*
    padding: theme.spacing(1),
     */
    },
    paper: {
      /*
    height: `calc(100% - ${theme.spacing(1)}px)`
     */
    },
  });
export default styles;

import actionCreatorFactory from "typescript-fsa";
import { reducerWithInitialState } from "typescript-fsa-reducers";
import { ApiEventApprovalRegister, ApiEventRegisterParams, ApiEventRegisterResponseMessage } from "../../api/odpApi";
import { LoadingState } from "../models";

// models
// ----------------------------------------

export interface EventRegisterApplication extends EventRegisterParams {
  place: string;
  recaptchaVerified: boolean;
}

export type EventRegisterResponseMessage = ApiEventRegisterResponseMessage;

// Parameters
// ----------------------------------------

export type EventRegisterParams = ApiEventRegisterParams;

// State
// ----------------------------------------

export interface EventRegisterModalState {
  modalOpen: boolean;
  responseMessage?: EventRegisterResponseMessage;
  submittingLoadingState: LoadingState;
}

const emptyEventRegisterModalState: EventRegisterModalState = {
  modalOpen: false,
  submittingLoadingState: LoadingState.Initial,
  responseMessage: undefined,
};

const initialState: EventRegisterModalState = emptyEventRegisterModalState;

// ActionCreators
// ----------------------------------------

const actionCreator = actionCreatorFactory("PirikaOdp/EventsRegisterModal");

export const handleModal = actionCreator<boolean>("handleModal");

export const registerEvent = actionCreator<EventRegisterParams>("registerEvent");
export const registerEventProgress = actionCreator.async<EventRegisterParams, ApiEventApprovalRegister, Error>(
  "registerEventProgress",
);

// Reducer
// ----------------------------------------

const reducer = reducerWithInitialState(initialState)
  .case(handleModal, (state, payload) => ({
    ...state,
    modalOpen: payload,
    responseMessage: undefined,
  }))
  .case(registerEventProgress.started, (state) => ({
    ...state,
    submittingLoadingState: LoadingState.Loading,
  }))
  .case(registerEventProgress.done, (state, payload) => ({
    ...state,
    submittingLoadingState: LoadingState.Initial,
    responseMessage: payload.result.message,
  }))
  .case(registerEventProgress.failed, (state) => ({
    ...state,
    submittingLoadingState: LoadingState.Error,
  }));

export default reducer;

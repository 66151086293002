import * as React from "react";
import { connect } from "react-redux";
import StackedBarRegionGraph from "../../../../components/dashboard/components/regionGraph/StackedBarRegionGraph";
import { RootState } from "../../../../modules";
import { Region, RegionPolygonCounters } from "../../../../modules/regionComponents";
import { generatePeriods, generateYearPeriod, periodToDate } from "../../../../helpers/date";
import { RegionGraphPageComponent } from "../../../../api/odpApi";
import { ApiActivityDataPoint } from "../../../../api/baseApi";

interface ExportProps {
  component: RegionGraphPageComponent;
}

interface StateProps {
  region?: Region;
  counters?: Record<string, RegionPolygonCounters>;
  originPeriod?: string;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface DispatchProps {}

type Props = ExportProps & StateProps & DispatchProps;

class StackedBarRegionGraphContainer extends React.Component<Props> {
  public render(): JSX.Element {
    const { region, counters, originPeriod, component } = this.props;

    if (!region || !counters || !originPeriod) {
      // eslint-disable-next-line react/jsx-no-useless-fragment
      return <></>;
    }

    const periods = (() => {
      switch (component.config.collectionPeriodType) {
        case "all":
          return generatePeriods(periodToDate(originPeriod), new Date());
        case "year":
          return generateYearPeriod(new Date(), 1);
        case "fiscalYear":
          return generateYearPeriod(new Date(), 4);
        default:
          return [];
      }
    })();
    const values: Record<string, Record<string, number>> = {};
    Object.keys(counters).forEach((polygonId) => {
      const periodDict = (counters[polygonId].counters ?? []).reduce((prev, value) => {
        const next = prev;
        next[value.period] = value;
        return next;
      }, {} as Record<string, ApiActivityDataPoint>);
      values[polygonId] = {};
      Object.values(periods).forEach((period) => {
        // TODO(iseebi): 何を表示するかを設定できるようにする
        values[polygonId][period] = periodDict[period] !== undefined ? periodDict[period].trash : 0;
      });
    });

    return <StackedBarRegionGraph region={region} periods={periods} values={values} />;
  }
}

const mapStateToProps = (state: RootState): StateProps => ({
  region: state.regionComponents.region,
  counters: state.regionComponents.counters,
  originPeriod: state.openDataPage.pageConfigurations?.config.originPeriod,
});

const mapDispatchToProps = (/* dispatch: Dispatch */): DispatchProps => ({});

export default connect(mapStateToProps, mapDispatchToProps)(StackedBarRegionGraphContainer);

import { createStyles, StyleRules, Theme } from "@material-ui/core";

const styles = (theme: Theme): StyleRules =>
  createStyles({
    root: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
    },
  });
export default styles;

import * as React from "react";
import { WithStyles, withStyles } from "@material-ui/core";
import { withTranslation, WithTranslation } from "react-i18next";
import styles from "./MapPinStyles";
import { ReactComponent as MarkerImage } from "../../../../assets/marker.svg";
import { PirikaPost } from "../../../../modules/models";

interface Props extends WithStyles<typeof styles>, WithTranslation {
  // (Google Maps から使用している)
  // eslint-disable-next-line react/no-unused-prop-types
  lat: number;
  // (Google Maps から使用している)
  // eslint-disable-next-line react/no-unused-prop-types
  lng: number;
  post: PirikaPost;
  onClick: () => void;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
  imageUrl?: string;
}

const MapPin: React.FC<Props> = ({ post, onClick, onMouseEnter, onMouseLeave, imageUrl, classes }) => (
  // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
  <div
    className={classes.root}
    data-item-type="MapPin"
    data-id={post.id}
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
    onClick={
      (/* ev */) => {
        onClick();
        // 重なっているものを対応する必要がある場合
        // const ids = ((): number[] => {
        //   const ownerDocument = ev.currentTarget.ownerDocument;
        //   if (ownerDocument === null) {
        //     return [post.id];
        //   }
        //   return ownerDocument
        //     .elementsFromPoint(ev.pageX, ev.pageY)
        //     .filter(v => v.getAttribute("data-item-type") === "MapPin")
        //     .map(v => parseInt(v.getAttribute("data-id") as string)) as number[];
        // })();
        // if (ids.length > 1) {
        //   onLayeredClick(ids);
        // } else {
        //   onClick();
        // }
      }
    }
  >
    {!imageUrl && <MarkerImage className={classes.defaultImage} />}
    {imageUrl && <img className={classes.customizeImage} src={imageUrl} alt="page" />}
  </div>
);

export default withTranslation()(withStyles(styles)(MapPin));

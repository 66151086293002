import actionCreatorFactory from "typescript-fsa";
import { reducerWithInitialState } from "typescript-fsa-reducers";
import { ApiPageContractType, PageConfigurations as ApiPageConfigurations, PageDatabox } from "../../api/odpApi";
import Constants from "../../constants";
import { LoadingState } from "../models";

// models
// ----------------------------------------

export const LoginFailedState = 3;

export type OpenDataPageConfigurations = ApiPageConfigurations;
export type PageContractType = ApiPageContractType;
export type ConfigurationLoadingState = LoadingState | typeof LoginFailedState;

// State
// ----------------------------------------

export interface OpenDataPageState {
  configurationLoadingState: ConfigurationLoadingState;
  pageConfigurations?: OpenDataPageConfigurations;
}

export const EmptyPageState: OpenDataPageState = {
  configurationLoadingState: LoadingState.Initial,
};

const initialState: OpenDataPageState = EmptyPageState;

export const LoginAuthenticationError = "AuthenticationError";

// Parameters
// ----------------------------------------

export interface LoadOpenDataPageConfigurationParameters {
  password?: string;
}

// Selectors
// ----------------------------------------

export const isPageConfigurationLoaded = (state: { openDataPage: OpenDataPageState }): boolean =>
  state.openDataPage.configurationLoadingState === LoadingState.Initial &&
  state.openDataPage.pageConfigurations !== undefined;

export const isWidgetMode = (state: { openDataPage: OpenDataPageState }): boolean | undefined =>
  state.openDataPage.pageConfigurations?.config.isWidget;

/**
 * stateからdataboxIdに対応するdataboxの設定を取得する
 * @param state
 * @param databoxId
 */
export const selectDataboxConfig = (
  state: { openDataPage: OpenDataPageState },
  databoxId: string,
): PageDatabox | undefined => state.openDataPage.pageConfigurations?.databoxes.find((v) => v.id === databoxId);

// Support Functions
// ----------------------------------------

export const getUploadedFileUrl = (organizationId: number, pageId: number, fileId: string): string =>
  `${Constants.cloudStorageBaseUrl}biz/${organizationId}/odp/${pageId}/upload_images/${fileId}`;

// ActionCreators
// ----------------------------------------

const actionCreator = actionCreatorFactory("PirikaOdp/OpenDataPage");

export const loadOpenDataPageConfiguration = actionCreator<LoadOpenDataPageConfigurationParameters>(
  "loadOpenDataPageConfiguration",
);
export const loadOpenDataPageConfigurationProgress = actionCreator.async<
  LoadOpenDataPageConfigurationParameters,
  OpenDataPageConfigurations,
  Error | "AuthenticationError"
>("loadOpenDataPageConfigurationLoading");

// Reducer
// ----------------------------------------

const reducer = reducerWithInitialState(initialState)
  .case(loadOpenDataPageConfiguration, (state) => ({
    ...state,
    configurationLoadingState: LoadingState.Loading,
  }))
  .case(loadOpenDataPageConfigurationProgress.started, (state) => ({
    ...state,
    configurationLoadingState: LoadingState.Loading,
  }))
  .case(loadOpenDataPageConfigurationProgress.done, (state, { result }) => ({
    ...state,
    configurationLoadingState: LoadingState.Initial,
    pageConfigurations: result,
  }))
  .case(loadOpenDataPageConfigurationProgress.failed, (state, { error }) => {
    if (error !== LoginAuthenticationError) {
      return {
        ...state,
        configurationLoadingState: LoadingState.Error,
        pageConfigurations: undefined,
      };
    }
    return {
      ...state,
      configurationLoadingState: LoginFailedState,
      pageConfigurations: undefined,
    };
  });

export default reducer;

import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import CounterComponent from "../../../components/dashboard/components/CounterComponent";
import { RootState } from "../../../modules";
import { CounterPageComponent, PageDatabox } from "../../../api/odpApi";
import {
  CounterComponentState,
  loadCounter as loadCounterAction,
  LoadCounterParameters,
} from "../../../modules/counterComponents";
import { LoadingState } from "../../../modules/models";
import { selectDataboxConfig } from "../../../modules/openDataPage";

interface ExportProps {
  component: CounterPageComponent;
}

interface StateProps {
  organizationId: number;
  openDataPageId: number;
  componentsStates: Record<string, CounterComponentState>;
  databoxConfig: PageDatabox | undefined;
}

interface DispatchProps {
  loadCounter: (params: LoadCounterParameters) => void;
}

type Props = ExportProps & StateProps & DispatchProps;

class CounterComponentContainer extends React.Component<Props> {
  public componentDidMount() {
    const { loadCounter, component, organizationId, openDataPageId, databoxConfig } = this.props;
    if (!databoxConfig) {
      return;
    }
    loadCounter({
      componentId: component.id,
      organizationId,
      pageId: openDataPageId,
      databox: databoxConfig,
      counterAddMetaId: component.config.counterAddMetaId,
    });
  }

  public render(): JSX.Element {
    const { componentsStates, component } = this.props;
    const states = componentsStates[component.id];
    const loadingState =
      (states?.counter === undefined && states?.loadingState !== LoadingState.Error) ||
      states?.loadingState === undefined
        ? LoadingState.Loading
        : states.loadingState;
    const counter = states?.counter;
    return <CounterComponent component={component} loadingState={loadingState} counter={counter} />;
  }
}

const mapStateToProps = (state: RootState, ownProps: ExportProps): StateProps => ({
  componentsStates: state.counterComponents.components,
  organizationId: state.openDataPage.pageConfigurations?.config.organizationId ?? 0,
  openDataPageId: state.openDataPage.pageConfigurations?.config.openDataPageId ?? 0,
  databoxConfig: selectDataboxConfig(state, ownProps.component.config.databox),
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  loadCounter: (params: LoadCounterParameters) => dispatch(loadCounterAction(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CounterComponentContainer);

import { createStyles, StyleRules } from "@material-ui/core";

const styles = (/* theme: Theme */): StyleRules =>
  createStyles({
    root: {
      position: "absolute",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    contentsFrame: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    loadingImage: {
      width: "180px",
    },
    progress: {
      width: "100%",
    },
  });
export default styles;

import axios, { AxiosInstance } from "axios";
import { ApiGeocode } from "./model";
import { ExternalApi } from "./interface";
import Constants from "../../constants";

export class ExternalApiImplements implements ExternalApi {
  private axios: AxiosInstance;

  public constructor() {
    this.axios = axios.create({
      responseType: "json",
      validateStatus: (status) => status < 500,
    });
  }

  public getGeocodeAsync = (address?: string): Promise<ApiGeocode | undefined> =>
    this.fetchGeocodeAsync("https://maps.googleapis.com/maps/api/geocode/json", address);

  private async fetchGeocodeAsync(url: string, address?: string): Promise<ApiGeocode | undefined> {
    try {
      if (!address) {
        return undefined;
      }
      const params = {
        key: Constants.googleGeocodeApiKey,
        hoge: "hoge",
        address,
      };
      const response = await this.axios.get<ApiGeocode>(url, { params });
      return response.data;
    } catch (e) {
      return undefined;
    }
  }
}

export default ExternalApiImplements;

import { createStyles, StyleRules, Theme } from "@material-ui/core";

const styles = (theme: Theme): StyleRules =>
  createStyles({
    root: {},
    buttonsContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      marginLeft: theme.spacing(1.5),
      marginRight: theme.spacing(1.5),
      [theme.breakpoints.down(600)]: {
        flexDirection: "column-reverse",
        alignItems: "center",
        justifyContent: "center",
      },
    },
    shareButtonContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      [theme.breakpoints.down(600)]: {
        marginTop: theme.spacing(1),
      },
    },
    shareButton: {
      marginRight: theme.spacing(2),
    },
    bannerContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      [theme.breakpoints.down(600)]: {
        maxWidth: "100%",
        flexDirection: "column",
      },
    },
    bannerImg: {
      maxWidth: "100%",
    },
    withPirikaLogo: {
      width: "180px",
    },
    copyrightContainer: {
      textAlign: "center",
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    copyright: {
      fontSize: "14px",
      color: "#5f5f5f",
    },
    acknowledgements: {
      fontSize: "8px",
      color: "#5f5f5f",
    },
    acknowledgementsLink: {
      color: "#5f5f5f",
    },
  });
export default styles;

import { createStyles, StyleRules } from "@material-ui/core";

const styles = (/* theme: Theme */): StyleRules =>
  createStyles({
    root: {},
    emptyState: {
      minHeight: "80px",
      height: "100%",
      fontSize: "16px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    usersContainer: {
      display: "flex",
      flexWrap: "wrap",
    },
  });

export default styles;

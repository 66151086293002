import { createStyles, StyleRules } from "@material-ui/core";

const styles = (/* theme: Theme */): StyleRules =>
  createStyles({
    root: {
      display: "flex",
    },
    image: {
      width: "100%",
    },
  });
export default styles;

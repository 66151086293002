import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { RootState } from "../../../../modules";
import { MapRegionProps, RelativeRegionCounters } from "../../../../components/dashboard/components/map";
import {
  loadRegion as loadRegionAction,
  loadRegionActivities as loadRegionActivitiesAction,
  loadRegionGeoJson as loadRegionGeoJsonAction,
  Region,
  RegionDataboxState,
  RegionPolygonCounters,
  RelativeRegionAggregateMode,
  updateRelativeRegionAggregateModeAction,
} from "../../../../modules/regionComponents";
import { generatePeriods } from "../../../../helpers/date";
import { ApiActivityDataPoint } from "../../../../api/baseApi";
import MapRegionLegendContainer from "./MapRegionLegendContainer";
import Constants from "../../../../constants";
import { GeoJson, LoadingState, PirikaPost } from "../../../../modules/models";

type ProviderCallableChildren = (props: MapRegionProps) => React.ReactNode;

interface ExportProps {
  children: ProviderCallableChildren | React.ReactNode;
  regionDrawType: "static" | "relative";
  regionLowerColor?: string;
  regionHigherColor?: string;
  legendMaxTrashes?: number;
  legendMaxParticipants?: number;
}

interface StateProps {
  organizationId: number;
  openDataPageId: number;
  region?: Region;
  regionLoadingState: LoadingState;
  regionCounters?: Record<string, RegionPolygonCounters>;
  relativeRegionAggregateMode: RelativeRegionAggregateMode;
  geoJson?: GeoJson;
  databoxes: Record<string, RegionDataboxState>;
}

interface DispatchProps {
  loadRegion: (organizationId: number, openDataPageId: number) => void;
  loadRegionActivities: (organizationId: number, openDataPageId: number) => void;
  loadRegionGeoJson: (topojsonUrl: string) => void;
  updateRelativeRegionAggregateMode: (mode: RelativeRegionAggregateMode) => void;
}

type Props = ExportProps & StateProps & DispatchProps;

interface State {
  relativeRegionCounters?: RelativeRegionCounters | null;
}

class MapRegionProvider extends React.Component<Props, State> {
  public constructor(props: Props, context: unknown) {
    super(props, context);
    this.state = {
      relativeRegionCounters: null,
    };
  }

  public componentDidMount() {
    this.processRegionLoad();
  }

  public componentDidUpdate(prevProps: Readonly<Props>) {
    const { regionCounters, relativeRegionAggregateMode } = this.props;
    this.processRegionLoad(prevProps);
    if (
      prevProps.regionCounters !== regionCounters ||
      relativeRegionAggregateMode !== prevProps.relativeRegionAggregateMode
    ) {
      this.updateRelativeRegionCounters();
    }
  }

  private legends = (): React.ReactNode | undefined => {
    const {
      regionDrawType,
      regionLowerColor,
      regionHigherColor,
      relativeRegionAggregateMode,
      updateRelativeRegionAggregateMode,
    } = this.props;
    const { relativeRegionCounters } = this.state;
    return (
      <MapRegionLegendContainer
        showScale={regionDrawType === "relative"}
        regionCounters={relativeRegionCounters ?? undefined}
        lowerColor={regionLowerColor || Constants.defaultScaleBarLowerColor}
        higherColor={regionHigherColor || Constants.defaultScaleBarHigherColor}
        maxValue={this.maxValue()}
        aggregateMode={relativeRegionAggregateMode}
        onUpdateAggregateMode={updateRelativeRegionAggregateMode}
      />
    );
  };

  private maxValue = () => {
    const { legendMaxParticipants, legendMaxTrashes, relativeRegionAggregateMode } = this.props;
    return relativeRegionAggregateMode.valueType === "participants" ? legendMaxParticipants : legendMaxTrashes;
  };

  private processRegionLoad = (prevProps?: Readonly<Props>) => {
    const {
      organizationId,
      openDataPageId,
      region,
      regionLoadingState,
      loadRegion,
      loadRegionActivities,
      loadRegionGeoJson,
    } = this.props;
    if (region === undefined && regionLoadingState === LoadingState.Initial) {
      loadRegion(organizationId, openDataPageId);
      return;
    }
    if (region !== undefined && prevProps?.region === undefined) {
      loadRegionGeoJson(region.topojsonUrl);
      loadRegionActivities(organizationId, openDataPageId);
    }
  };

  private updateRelativeRegionCounters = (): void => {
    const { regionCounters, relativeRegionAggregateMode } = this.props;
    const counters = regionCounters;
    if (!counters) {
      this.setState({ relativeRegionCounters: null });
      return;
    }
    const { term, valueType } = relativeRegionAggregateMode;
    const values: Record<string, number> = {};
    const periods = (() => {
      if (term === undefined) {
        return undefined;
      }
      const now = new Date();
      return generatePeriods(new Date(now.getFullYear(), now.getMonth() - term, 1), now);
    })();

    const lookupValue = (point: ApiActivityDataPoint): number => {
      switch (valueType) {
        case "participants":
          return point.participants;
        case "trash":
          return point.trash;
        default:
          return 0;
      }
    };

    Object.keys(counters).forEach((polygonId) => {
      values[polygonId] = counters[polygonId].counters.reduce((prev: number, v: ApiActivityDataPoint) => {
        if (periods === undefined || periods.indexOf(v.period) >= 0) {
          return prev + lookupValue(v);
        }
        return prev;
      }, 0);
    });
    this.setState({
      relativeRegionCounters: {
        maxValue: Math.max(...Object.values(values)),
        minValue: Math.min(...Object.values(values)),
        values,
      },
    });
  };

  public render(): JSX.Element {
    const { geoJson, children, databoxes } = this.props;
    const { relativeRegionCounters } = this.state;
    const props = {
      geoJson,
      relativeRegionCounters: relativeRegionCounters ?? undefined,
      legends: this.legends(),
      maxValue: this.maxValue(),
      databoxes: Object.keys(databoxes).reduce(
        (dict, v) => ({ ...dict, [v]: databoxes[v].posts || [] }),
        {} as Record<string, PirikaPost[]>,
      ),
    };
    const content = typeof children === "function" ? (children as ProviderCallableChildren)(props) : children;
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{content}</>;
  }
}

const mapStateToProps = (state: RootState): StateProps => ({
  organizationId: state.openDataPage.pageConfigurations?.config.organizationId ?? 0,
  openDataPageId: state.openDataPage.pageConfigurations?.config.openDataPageId ?? 0,
  region: state.regionComponents.region,
  regionLoadingState: state.regionComponents.regionLoadingState,
  regionCounters: state.regionComponents.counters,
  relativeRegionAggregateMode: state.regionComponents.relativeRegionAggregateMode,
  geoJson: state.regionComponents.geoJson,
  databoxes: state.regionComponents.databoxes,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  loadRegion: (organizationId: number, openDataPageId: number) =>
    dispatch(loadRegionAction({ organizationId, pageId: openDataPageId })),
  loadRegionActivities: (organizationId: number, openDataPageId: number) =>
    dispatch(loadRegionActivitiesAction({ organizationId, pageId: openDataPageId })),
  loadRegionGeoJson: (topojsonUrl) => dispatch(loadRegionGeoJsonAction({ topojsonUrl })),
  updateRelativeRegionAggregateMode: (mode) =>
    dispatch(updateRelativeRegionAggregateModeAction({ relativeRegionAggregateMode: mode })),
});

export default connect(mapStateToProps, mapDispatchToProps)(MapRegionProvider);

import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import GroupEventsComponent from "../../../components/dashboard/components/GroupEventsComponent";
import { RootState } from "../../../modules";
import { GroupEventsPageComponent } from "../../../api/odpApi";
import {
  GroupEventsComponentState,
  loadGroupEvents as loadGroupEventsAction,
} from "../../../modules/groupEventsComponents";
import { AreaEvent } from "../../../modules/areaEventsComponents";
import { openEvent as openEventAction, PirikaEvent } from "../../../modules/events";
import { LoadingState } from "../../../modules/models";

interface ExportProps {
  component: GroupEventsPageComponent;
}

interface StateProps {
  organizationId: number;
  openDataPageId: number;
  componentsStates: Record<string, GroupEventsComponentState>;
}

interface DispatchProps {
  loadGroupEvents: (componentId: string, organizationId: number, pageId: number, groupId: number) => void;
  openEvent: (event: PirikaEvent) => void;
}

type Props = ExportProps & StateProps & DispatchProps;

class GroupEventsComponentContainer extends React.Component<Props> {
  public componentDidMount() {
    const { loadGroupEvents, component, organizationId, openDataPageId } = this.props;
    loadGroupEvents(component.id, organizationId, openDataPageId, component.config.groupId);
  }

  private handleOpenEvent = (event: AreaEvent) => {
    const { openEvent } = this.props;
    openEvent(event);
  };

  public render(): JSX.Element {
    const { component, componentsStates } = this.props;
    const date = new Date();
    const states = componentsStates[component.id];
    const loadingState =
      (states?.events === undefined && states?.loadingState !== LoadingState.Error) ||
      states?.loadingState === undefined
        ? LoadingState.Loading
        : states.loadingState;
    const events = (states?.events ?? []).filter((v) => v.endDate.getTime() > date.getTime());
    return (
      <GroupEventsComponent
        component={component}
        loadingState={loadingState}
        events={events}
        onOpenEvent={this.handleOpenEvent}
      />
    );
  }
}

const mapStateToProps = (state: RootState): StateProps => ({
  componentsStates: state.groupEventsComponents.components,
  organizationId: state.openDataPage.pageConfigurations?.config.organizationId ?? 0,
  openDataPageId: state.openDataPage.pageConfigurations?.config.openDataPageId ?? 0,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  loadGroupEvents: (componentId, organizationId, pageId, groupId) =>
    dispatch(loadGroupEventsAction({ componentId, organizationId, pageId, groupId })),
  openEvent: (event) => dispatch(openEventAction({ event })),
});

export default connect(mapStateToProps, mapDispatchToProps)(GroupEventsComponentContainer);

import * as React from "react";
import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Paper,
  Popover,
  Radio,
  RadioGroup,
  WithStyles,
  withStyles,
} from "@material-ui/core";
import { withTranslation, WithTranslation } from "react-i18next";
import { useRef } from "react";
import styles from "./MapRegionLegendStyles";
import { RelativeRegionCounters } from "./index";
import { RelativeRegionAggregateMode } from "../../../../modules/regionComponents";
import { RelativePrefectureRegionAggregateMode } from "../../../../modules/prefectureRegionComponents";

interface Props extends WithStyles<typeof styles>, WithTranslation {
  counters: RelativeRegionCounters;
  showAggregateMode?: boolean;
  showScale?: boolean;
  lowerColor: string;
  higherColor: string;
  maxValue?: number;
  aggregateMode: RelativeRegionAggregateMode;
  openAggregateMode: boolean;
  usePopulationScale?: boolean;
  onUpdateOpenAggregateMode: (open: boolean) => void;
  onUpdateAggregateMode: (aggregateMode: RelativeRegionAggregateMode | RelativePrefectureRegionAggregateMode) => void;
}

const MapRegionLegend: React.FC<Props> = ({
  counters,
  showScale,
  showAggregateMode,
  lowerColor,
  higherColor,
  maxValue,
  aggregateMode,
  openAggregateMode,
  usePopulationScale,
  onUpdateOpenAggregateMode,
  onUpdateAggregateMode,
  classes,
}) => {
  const regionAggregateModeRef = useRef(null);
  const valueTypeName = aggregateMode.valueType === "participants" ? "のべ参加人数" : "ごみの数";
  const termName = aggregateMode.term === undefined ? "全期間" : `${aggregateMode.term}ヶ月`;
  const unit = aggregateMode.valueType === "participants" ? "人" : "個";
  return (
    <>
      {showAggregateMode !== false && (
        <div className={classes.regionAggregateMode}>
          <Button
            ref={regionAggregateModeRef}
            aria-describedby={openAggregateMode ? "simple-popover" : undefined}
            className={classes.regionAggregateModeButton}
            variant="contained"
            onClick={() => onUpdateOpenAggregateMode(!openAggregateMode)}
          >{`${valueTypeName} ${termName} ▼`}</Button>
          <Popover
            open={openAggregateMode}
            anchorEl={regionAggregateModeRef.current}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            onClose={() => onUpdateOpenAggregateMode(false)}
          >
            <div className={classes.regionAggregateModeContentFrame}>
              <FormControl component="fieldset">
                <FormLabel component="legend">指標値</FormLabel>
                <RadioGroup
                  defaultValue="trash"
                  name="radio-buttons-group"
                  value={aggregateMode.valueType}
                  onChange={(e) =>
                    onUpdateAggregateMode({
                      ...aggregateMode,
                      valueType: e.target.value === "participants" ? "participants" : "trash",
                    })
                  }
                >
                  <FormControlLabel value="trash" control={<Radio />} label="ごみの数" />
                  <FormControlLabel value="participants" control={<Radio />} label="のべ参加人数" />
                </RadioGroup>
              </FormControl>
              <FormControl component="fieldset">
                <FormLabel component="legend">集計期間</FormLabel>
                <RadioGroup
                  defaultValue={3}
                  name="radio-buttons-group"
                  value={aggregateMode.term !== undefined ? aggregateMode.term : -1}
                  onChange={(e) => {
                    onUpdateAggregateMode({
                      ...aggregateMode,
                      term: parseInt(e.target.value, 10) >= 0 ? parseInt(e.target.value, 10) : undefined,
                    });
                  }}
                >
                  <FormControlLabel value={1} control={<Radio />} label="今月" />
                  <FormControlLabel value={3} control={<Radio />} label="3ヶ月" />
                  <FormControlLabel value={-1} control={<Radio />} label="全期間" />
                </RadioGroup>
              </FormControl>
            </div>
          </Popover>
        </div>
      )}
      {showScale !== false && (
        <div className={classes.regionColorScale}>
          <Paper>
            <div className={classes.regionColorScaleInnerContainer}>
              <div className={classes.regionColorScaleBarLegendContainer}>
                <span className={classes.regionColorScaleBarLegend}>{`${counters.minValue}${unit}`}</span>
              </div>
              <div className={classes.regionColorScaleBarContainer}>
                <div
                  className={classes.regionColorScaleBar}
                  style={{
                    background: `linear-gradient(to right, ${lowerColor}, ${higherColor})`,
                  }}
                />
              </div>
              <div className={classes.regionColorScaleBarLegendContainer}>
                <span className={classes.regionColorScaleBarLegend}>
                  {`${maxValue || counters.maxValue}${unit}${maxValue ? "〜" : ""}`}
                  {usePopulationScale ? "(1万人あたり)" : ""}
                </span>
              </div>
            </div>
          </Paper>
        </div>
      )}
    </>
  );
};

export default withTranslation()(withStyles(styles)(MapRegionLegend));

import { createStyles, StyleRules, Theme } from "@material-ui/core";

const styles = (theme: Theme): StyleRules =>
  createStyles({
    root: {},
    items: {
      [theme.breakpoints.down(960)]: {
        maxHeight: `${70 * 5 + 26}px`,
        overflowY: "scroll",
      },
    },
    container: {
      // InfiniteScrollのために必要
      [theme.breakpoints.up(961)]: {
        maxHeight: `${70 * 5 + 26}px`,
        overflow: "scroll",
      },
    },
    item: {
      borderBottom: "1px solid #e2e2e2",
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      "&:first-child": {
        paddingTop: 0,
      },
    },
    itemTitle: {
      marginTop: 0,
      marginBottom: theme.spacing(1),
      fontSize: "14px",
      fontWeight: "bold",
    },
    itemDetail: {
      fontSize: "14px",
    },
    itemFooter: {
      fontSize: "12px",
      color: "#5f5f5f",
    },
    itemLink: {
      color: "#1470CC",
    },
    emptyState: {
      minHeight: "80px",
      height: "100%",
      fontSize: "16px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    loadMoreButton: {
      width: "100%",
      height: "54px",
      lineHeight: "1",
      background: "none",
      border: 0,
      textAlign: "center",
      fontSize: "14px",
      color: "#212121", // TODO: 全体で定数化したい
    },
  });
export default styles;

import * as React from "react";
import { WithStyles, withStyles } from "@material-ui/core";
import { withTranslation, WithTranslation } from "react-i18next";
import classNames from "classnames";
import styles from "./ComponentFrameStyles";

interface Props extends WithStyles<typeof styles>, WithTranslation {
  children?: React.ReactNode;
  className?: string;
}

/*
const ComponentFrame: React.FC<Props> = ({ children, className, classes }) => (
  <div className={classNames(classes.root, className)}>
    <Paper className={classNames(classes.paper)}>{children}</Paper>
  </div>
);
 */
const ComponentFrame: React.FC<Props> = ({ children, className, classes }) => (
  <div className={classNames(classes.root, className)}>{children}</div>
);

export default withTranslation()(withStyles(styles)(ComponentFrame));

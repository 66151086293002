import actionCreatorFactory from "typescript-fsa";
import { reducerWithInitialState } from "typescript-fsa-reducers";
import { ApiEvent, ApiEventDetail } from "../../api/baseApi";
import { LoadingState } from "../models";

// models
// ----------------------------------------

export type PirikaEvent = ApiEvent;
export type EventDetail = ApiEventDetail;

// State
// ----------------------------------------

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface EventsState {
  dialogOpenEvent?: PirikaEvent;
  dialogEventDetailLoadingState: LoadingState;
  dialogEventDetail?: EventDetail;
}

export const EmptyEventsState = {
  dialogEventDetailLoadingState: LoadingState.Initial,
};

const initialState: EventsState = EmptyEventsState;

// Parameters
// ----------------------------------------

export interface OpenEventParameters {
  event: PirikaEvent;
}

export interface LoadEventParameters {
  event: PirikaEvent;
}

// ActionCreators
// ----------------------------------------

const actionCreator = actionCreatorFactory("PirikaOdp/Events");

export const openEvent = actionCreator<OpenEventParameters>("Open");
export const loadEvent = actionCreator<LoadEventParameters>("Load");
export const loadEventProgress = actionCreator.async<LoadEventParameters, ApiEventDetail, Error>("Load");
export const closeEventDialog = actionCreator<void>("CloseDialog");

// Reducer
// ----------------------------------------

const reducer = reducerWithInitialState(initialState)
  .case(loadEvent, (state, { event }) => ({
    ...state,
    dialogOpenEvent: event,
    dialogEventDetailLoadingState: LoadingState.Loading,
  }))
  .case(loadEventProgress.started, (state) => ({
    ...state,
    dialogEventDetailLoadingState: LoadingState.Loading,
  }))
  .case(loadEventProgress.done, (state, { result }) => ({
    ...state,
    dialogEventDetailLoadingState: LoadingState.Initial,
    dialogEventDetail: result,
  }))
  .case(loadEventProgress.failed, (state) => ({
    ...state,
    dialogEventDetailLoadingState: LoadingState.Error,
  }))
  .case(closeEventDialog, (state) => ({
    ...state,
    dialogEventDetailLoadingState: LoadingState.Initial,
    dialogEventDetail: undefined,
    dialogOpenEvent: undefined,
  }));

export default reducer;

import * as React from "react";
import { WithStyles, withStyles } from "@material-ui/core";
import { withTranslation, WithTranslation } from "react-i18next";
import styles from "./CounterStyles";
import { ReactComponent as Digit0Image } from "../../../../assets/digits/digit_0.svg";
import { ReactComponent as Digit1Image } from "../../../../assets/digits/digit_1.svg";
import { ReactComponent as Digit2Image } from "../../../../assets/digits/digit_2.svg";
import { ReactComponent as Digit3Image } from "../../../../assets/digits/digit_3.svg";
import { ReactComponent as Digit4Image } from "../../../../assets/digits/digit_4.svg";
import { ReactComponent as Digit5Image } from "../../../../assets/digits/digit_5.svg";
import { ReactComponent as Digit6Image } from "../../../../assets/digits/digit_6.svg";
import { ReactComponent as Digit7Image } from "../../../../assets/digits/digit_7.svg";
import { ReactComponent as Digit8Image } from "../../../../assets/digits/digit_8.svg";
import { ReactComponent as Digit9Image } from "../../../../assets/digits/digit_9.svg";
import { ReactComponent as DigitCommaImage } from "../../../../assets/digits/digit_comma.svg";
import { ReactComponent as DigitBlankImage } from "../../../../assets/digits/digit_blank.svg";

interface Props extends WithStyles<typeof styles>, WithTranslation {
  value: number;
  fills: number;
  digitClassName?: string;
}

const numberToDigitStrings = (num: number, fills: number): string[] => {
  const result = [];
  if (num >= 0) {
    const items = `${num}`.split("");
    for (let i = 0; i < items.length; i += 1) {
      const index = items.length - 1 - i;
      if (i % 3 === 0 && i > 0) {
        result.unshift(",");
      }
      result.unshift(items[index]);
    }
  }
  while (result.length < fills) {
    result.unshift(" ");
  }
  return result;
};

const digitStringToImage = (digit: string): JSX.Element => {
  switch (digit) {
    case "0":
      return <Digit0Image />;
    case "1":
      return <Digit1Image />;
    case "2":
      return <Digit2Image />;
    case "3":
      return <Digit3Image />;
    case "4":
      return <Digit4Image />;
    case "5":
      return <Digit5Image />;
    case "6":
      return <Digit6Image />;
    case "7":
      return <Digit7Image />;
    case "8":
      return <Digit8Image />;
    case "9":
      return <Digit9Image />;
    case ",":
      return <DigitCommaImage />;
    default:
      return <DigitBlankImage />;
  }
};

const Counter: React.FC<Props> = ({ value, fills, digitClassName }) => (
  <>
    {numberToDigitStrings(value, fills).map((v, index) => (
      // eslint-disable-next-line react/no-array-index-key,@typescript-eslint/restrict-template-expressions
      <span key={`digit${index}`} className={digitClassName}>
        {digitStringToImage(v)}
      </span>
    ))}
  </>
);

export default withTranslation()(withStyles(styles)(Counter));

import * as React from "react";
import { connect } from "react-redux";
import MapRegionLegend from "../../../../components/dashboard/components/map/MapRegionLegend";
import { RelativeRegionCounters } from "../../../../components/dashboard/components/map";
import { RelativeRegionAggregateMode } from "../../../../modules/regionComponents";
import { RelativePrefectureRegionAggregateMode } from "../../../../modules/prefectureRegionComponents";

interface ExportProps {
  lowerColor: string;
  higherColor: string;
  showAggregateMode?: boolean;
  showScale?: boolean;
  maxValue?: number;
  usePopulationScale?: boolean;
  regionCounters?: RelativeRegionCounters;
  aggregateMode: RelativeRegionAggregateMode;
  onUpdateAggregateMode: (aggregateMode: RelativeRegionAggregateMode | RelativePrefectureRegionAggregateMode) => void;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface StateProps {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface DispatchProps {}

type Props = ExportProps & StateProps & DispatchProps;

interface State {
  openAggregateMode: boolean;
}

class MapRegionLegendContainer extends React.Component<Props, State> {
  public constructor(props: Readonly<Props> | Props) {
    super(props);
    this.state = { openAggregateMode: false };
  }

  public render(): JSX.Element {
    const {
      lowerColor,
      higherColor,
      regionCounters,
      aggregateMode,
      onUpdateAggregateMode,
      usePopulationScale,
      maxValue,
      showAggregateMode,
      showScale,
    } = this.props;
    const { openAggregateMode } = this.state;
    if (!regionCounters) {
      // eslint-disable-next-line react/jsx-no-useless-fragment
      return <></>;
    }
    return (
      <MapRegionLegend
        counters={regionCounters}
        lowerColor={lowerColor}
        higherColor={higherColor}
        maxValue={maxValue}
        showScale={showScale}
        showAggregateMode={showAggregateMode}
        aggregateMode={aggregateMode}
        openAggregateMode={openAggregateMode}
        usePopulationScale={usePopulationScale}
        onUpdateOpenAggregateMode={(newState) => this.setState({ openAggregateMode: newState })}
        onUpdateAggregateMode={(newAggregateMode) => {
          onUpdateAggregateMode(newAggregateMode);
          this.setState({ openAggregateMode: false });
        }}
      />
    );
  }
}

const mapStateToProps = (/* state: RootState */): StateProps => ({});

const mapDispatchToProps = (/* dispatch: Dispatch */): DispatchProps => ({});

export default connect(mapStateToProps, mapDispatchToProps)(MapRegionLegendContainer);

import * as React from "react";
import { WithStyles, withStyles } from "@material-ui/core";
import { withTranslation, WithTranslation } from "react-i18next";
import styles from "./RankingComponentStyles";
import { RankingPageComponent } from "../../../api/odpApi";
import WidgetGridItem from "./WidgetGridItem";
import { RankingPlace } from "../../../modules/rankingComponents";
import ComponentLoadingStateView from "./parts/ComponentLoadingStateView";
import ComponentErrorStateView from "./parts/ComponentErrorStateView";
import ComponentContents from "./parts/ComponentContents";
import ComponentTitle from "./parts/ComponentTitle";
import ComponentFrame from "./parts/ComponentFrame";
import Constants from "../../../constants";
import { LoadingState } from "../../../modules/models";

interface Props extends WithStyles<typeof styles>, WithTranslation {
  component: RankingPageComponent;
  loadingState: LoadingState;
  places: RankingPlace[];
}

const RankingComponent: React.FC<Props> = ({ component, places, loadingState, classes }) => (
  <WidgetGridItem component={component}>
    <ComponentFrame className={classes.root}>
      {loadingState === LoadingState.Initial && (
        <ComponentContents>
          <ComponentTitle
            accessoryLinkTitle={component.config.accessoryLinkTitle}
            accessoryLinkTarget={component.config.accessoryLinkTarget}
            accessoryTooltipText={component.config.accessoryTooltipText}
          >
            {component.title}
          </ComponentTitle>
          <div>
            {places.map((v, index) => (
              <a
                key={`place${index + 1}`}
                className={classes.placeLink}
                href={`${Constants.snsServiceUrl}/u/${v.id ?? ""}`}
              >
                <div className={classes.place}>
                  <div className={classes.placeRankNumber}>{index + 1}</div>
                  <div className={classes.placeRankTail}>位</div>
                  <img
                    className={classes.placeImage}
                    width="38"
                    src={v.image !== "" ? v.image : ""}
                    alt={`${index + 1}位 ${v.name}さん`}
                  />
                  <div className={classes.placeName}>{v.name}</div>
                  <div className={classes.placeNameTitle}>さん</div>
                </div>
              </a>
            ))}
            {places.length === 0 && <div className={classes.emptyState}>データがありません</div>}
          </div>
        </ComponentContents>
      )}
      {loadingState === LoadingState.Loading && <ComponentLoadingStateView />}
      {loadingState === LoadingState.Error && <ComponentErrorStateView />}
    </ComponentFrame>
  </WidgetGridItem>
);

export default withTranslation()(withStyles(styles)(RankingComponent));

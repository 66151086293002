import { all, call, put, takeEvery } from "redux-saga/effects";
import { Action } from "typescript-fsa";
import { SagaIterator } from "redux-saga";
import { loadDatabox, LoadDataboxParameters, loadDataboxProgress } from "./index";
import Api, { ApiDatabox } from "../../api/baseApi";
import { checkPirikaWebApiResult } from "../saga-support";
import { PageDataboxType } from "../../api/odpApi";

function* loadDataboxSaga(action: Action<LoadDataboxParameters>) {
  const params = action.payload;
  const progressAction = loadDataboxProgress;
  yield put(progressAction.started(params));

  let result: ApiDatabox;

  switch (params.databox.type) {
    case PageDataboxType.Hashtag:
      result = (yield call(
        [Api, Api.getHashtagDataboxAsync],
        params.organizationId,
        params.pageId,
        params.databox.hashtag ?? "",
        params.parameter,
      )) as ApiDatabox;
      break;
    case PageDataboxType.Region:
      result = (yield call(
        [Api, Api.getRegionDataboxAsync],
        params.organizationId,
        params.pageId,
        undefined,
        params.parameter,
      )) as ApiDatabox;
      break;
    default:
      result = (yield call(
        [Api, Api.getDataboxAsync],
        params.organizationId,
        params.pageId,
        params.parameter,
      )) as ApiDatabox;
      break;
  }

  yield all(
    checkPirikaWebApiResult(
      result,
      (payload: ApiDatabox) => progressAction.done({ params, result: payload }),
      () => progressAction.failed({ params, error: new Error() }),
    ),
  );
}

export default function* watch(): SagaIterator {
  yield takeEvery(loadDatabox.type, loadDataboxSaga);
}

import { createStyles, StyleRules, Theme } from "@material-ui/core";

const styles = (theme: Theme): StyleRules =>
  createStyles({
    root: {},
    textField: {
      marginTop: theme.spacing(2),
    },
    datePickers: {
      marginTop: theme.spacing(4),
    },
    formArea: {
      paddingBottom: theme.spacing(4),
    },
    submitArea: {
      textAlign: "center",
      paddingBottom: theme.spacing(2),
    },
  });
export default styles;

import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import Root from "../components/Root";
import { RootState } from "../modules";
import {
  ConfigurationLoadingState,
  isPageConfigurationLoaded,
  isWidgetMode as getIsWidgetModeCheck,
  loadOpenDataPageConfiguration as loadOpenDataPageConfigurationAction,
  LoginFailedState,
} from "../modules/openDataPage";
import ConfigurationLoadFailedStateScreenContainer from "./loading/ConfigurationLoadFailedStateScreenContainer";
import ConfigurationLoadingStateScreenContainer from "./loading/ConfigurationLoadingStateScreenContainer";
import ConfigurationLoginFailedStateScreenContainer from "./loading/ConfigurationLoginFailedStateScreenContainer";
import { LoadingState } from "../modules/models";

interface ExportProps {
  children?: React.ReactNode;
}

interface StateProps {
  configurationLoaded: boolean;
  isWidgetMode?: boolean;
  configurationLoadingState: ConfigurationLoadingState;
  themeColor?: string;
}

interface DispatchProps {
  loadOpenDataPageConfiguration: (password?: string) => void;
}

type Props = ExportProps & StateProps & DispatchProps;

class RootContainer extends React.Component<Props> {
  public componentDidMount() {
    const { loadOpenDataPageConfiguration } = this.props;
    loadOpenDataPageConfiguration();
  }

  public render(): JSX.Element {
    const { configurationLoaded, configurationLoadingState, themeColor, isWidgetMode, children } = this.props;
    const component = (() => {
      if (configurationLoaded) {
        return children;
      }
      if (configurationLoadingState === LoadingState.Error) {
        return <ConfigurationLoadFailedStateScreenContainer />;
      }
      if (configurationLoadingState === LoginFailedState) {
        return <ConfigurationLoginFailedStateScreenContainer />;
      }
      return <ConfigurationLoadingStateScreenContainer />;
    })();
    return (
      <Root themeColor={themeColor} isWidgetMode={isWidgetMode}>
        {component}
      </Root>
    );
  }
}

const mapStateToProps = (state: RootState): StateProps => ({
  configurationLoaded: isPageConfigurationLoaded(state),
  isWidgetMode: getIsWidgetModeCheck(state),
  configurationLoadingState: state.openDataPage.configurationLoadingState,
  themeColor: state.openDataPage.pageConfigurations?.config.keyColor,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  loadOpenDataPageConfiguration: (password) => dispatch(loadOpenDataPageConfigurationAction({ password })),
});

export default connect(mapStateToProps, mapDispatchToProps)(RootContainer);

import { createStyles, StyleRules } from "@material-ui/core";

const styles = (/* theme: Theme */): StyleRules =>
  createStyles({
    root: {
      margin: "auto",
      fontFamily: "'Noto Sans JP', '游ゴシック体', YuGothic, '游ゴシック', 'Yu Gothic', sans-serif",
      color: "#212121",
      maxWidth: "1024px",
    },
    isWidget: {
      maxWidth: "100%",
    },
  });
export default styles;

import { createStyles, StyleRules } from "@material-ui/core";

const styles = (/* theme: Theme */): StyleRules =>
  createStyles({
    root: {},
    paragraph: {
      fontFamily: "serif",
      padding: 0,
    },
  });
export default styles;

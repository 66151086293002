import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { RootState } from "../../../../modules";
import { TimelinePageComponent } from "../../../../api/odpApi";
import {
  accumulateCounter,
  EmptyTimelineProviderPropsValues,
  TimelineProviderCallableChildren,
  TimelineProviderPropsValues,
  TimelineSummaryValue,
} from "./index";
import Constants from "../../../../constants";
import { DataboxQueryParameter, LoadingState } from "../../../../modules/models";
import {
  loadRegionDataboxAction,
  Region,
  RegionDataboxState,
  RegionPolygonCounters,
  RelativeRegionAggregateMode,
} from "../../../../modules/regionComponents";
import { generatePeriods } from "../../../../helpers/date";

interface ExportProps {
  children: TimelineProviderCallableChildren | React.ReactNode;
  component: TimelinePageComponent;
  regionId: string;
}

interface StateProps {
  organizationId: number;
  openDataPageId: number;
  databoxes: Record<string, RegionDataboxState>;
  region?: Region;
  relativeRegionAggregateMode: RelativeRegionAggregateMode;
  regionCounter?: RegionPolygonCounters;
}

interface DispatchProps {
  loadRegionDatabox: (
    regionId: string,
    componentId: string,
    organizationId: number,
    pageId: number,
    params: DataboxQueryParameter,
  ) => void;
}

type Props = ExportProps & StateProps & DispatchProps;

interface State {
  providerPropsValues: TimelineProviderPropsValues;
}

class RegionTimelineProvider extends React.Component<Props, State> {
  public constructor(props: Readonly<Props> | Props) {
    super(props);
    this.state = {
      providerPropsValues: EmptyTimelineProviderPropsValues,
    };
  }

  public componentDidMount() {
    this.loadDatabox();
  }

  public componentDidUpdate(prevProps: Readonly<Props>) {
    const { regionId, region, databoxes, relativeRegionAggregateMode } = this.props;
    if (prevProps.regionId !== regionId) {
      this.loadDatabox();
    }
    if (
      prevProps.regionId !== regionId ||
      prevProps.region !== region ||
      databoxes[regionId] !== prevProps.databoxes[regionId] ||
      databoxes[regionId]?.posts !== prevProps.databoxes[regionId]?.posts ||
      databoxes[regionId]?.loadingState !== prevProps.databoxes[regionId]?.loadingState ||
      relativeRegionAggregateMode !== prevProps.relativeRegionAggregateMode
    ) {
      this.updateProviderProps();
    }
  }

  private loadDatabox = () => {
    const { component, regionId, openDataPageId, organizationId, loadRegionDatabox } = this.props;
    const count = Constants.defaultDataboxItemCount;
    loadRegionDatabox(regionId, component.id, organizationId, openDataPageId, { count });
  };

  private getSummaryValue = (): TimelineSummaryValue | undefined => {
    const { regionCounter, relativeRegionAggregateMode } = this.props;
    const { term } = relativeRegionAggregateMode;

    if (!regionCounter) {
      return undefined;
    }

    const counters = term
      ? ((t: number) => {
          const now = new Date();
          const periods = generatePeriods(new Date(now.getFullYear(), now.getMonth() - t, 1), now);
          return regionCounter.counters.filter((v) => periods.indexOf(v.period) >= 0);
        })(term)
      : regionCounter.counters;
    return accumulateCounter(counters);
  };

  private updateProviderProps = (): void => {
    const { databoxes, regionId, region, relativeRegionAggregateMode } = this.props;
    const databox = databoxes[regionId];
    const loadingState =
      (databox?.posts === undefined && databox?.loadingState !== LoadingState.Error) ||
      databox?.loadingState === undefined
        ? LoadingState.Loading
        : databox.loadingState;
    const posts = databox?.posts;
    const lastPostId = posts ? posts[posts.length - 1]?.id : undefined;
    const hasMore = databox ? !databox.isTerminated : true;
    const summaryValue = this.getSummaryValue();
    const summaryValueType = relativeRegionAggregateMode.valueType;
    const summaryValueTerm = relativeRegionAggregateMode.term;

    const regionName = region && region.polygons.find((v) => v.polygonId === regionId)?.name;

    this.setState({
      providerPropsValues: {
        loadingState,
        posts,
        lastPostId,
        hasMore,
        regionName,
        summaryValue,
        summaryValueType,
        summaryValueTerm,
      },
    });
  };

  private handleLoadMore = () => {
    const { component, regionId, organizationId, openDataPageId, loadRegionDatabox, databoxes } = this.props;
    const { providerPropsValues } = this.state;
    const databox = databoxes[regionId];
    if (databox.isTerminated) {
      return;
    }
    if (providerPropsValues.loadingState === LoadingState.Initial) {
      loadRegionDatabox(regionId, component.id, organizationId, openDataPageId, {
        beforeId: providerPropsValues.lastPostId,
        count: Constants.fetchDataboxItemCount,
      });
    }
  };

  public render(): React.ReactNode {
    const { children } = this.props;
    const { providerPropsValues } = this.state;
    return typeof children === "function"
      ? (children as TimelineProviderCallableChildren)({
          ...providerPropsValues,
          handleLoadMore: this.handleLoadMore,
        })
      : children;
  }
}

const mapStateToProps = (state: RootState, ownProps: ExportProps): StateProps => ({
  databoxes: state.regionComponents.databoxes,
  organizationId: state.openDataPage.pageConfigurations?.config.organizationId ?? 0,
  openDataPageId: state.openDataPage.pageConfigurations?.config.openDataPageId ?? 0,
  region: state.regionComponents.region,
  relativeRegionAggregateMode: state.regionComponents.relativeRegionAggregateMode,
  regionCounter: state.regionComponents.counters ? state.regionComponents.counters[ownProps.regionId] : undefined,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  loadRegionDatabox: (regionId, componentId, organizationId, pageId, parameter) =>
    dispatch(loadRegionDataboxAction({ regionId, organizationId, pageId, parameter })),
});

export default connect(mapStateToProps, mapDispatchToProps)(RegionTimelineProvider);

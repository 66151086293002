import * as React from "react";
import { LinearProgress, WithStyles, withStyles } from "@material-ui/core";
import { withTranslation, WithTranslation } from "react-i18next";
import styles from "./ConfigurationLoadingStateScreenStyles";
import { ReactComponent as Loading } from "../../assets/loading.svg";

interface Props extends WithStyles<typeof styles>, WithTranslation {}

const ConfigurationLoadingStateScreen: React.FC<Props> = ({ classes }) => (
  <div className={classes.root}>
    <div className={classes.contentsFrame}>
      <Loading className={classes.loadingImage} />
      <LinearProgress className={classes.progress} />
    </div>
  </div>
);

export default withTranslation()(withStyles(styles)(ConfigurationLoadingStateScreen));

import { createStyles, StyleRules, Theme } from "@material-ui/core";

const styles = (theme: Theme): StyleRules =>
  createStyles({
    root: {
      minHeight: "262px",
    },
    place: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      "&:last-child": {
        marginBottom: 0,
      },
      display: "flex",
      alignItems: "center",
      whiteSpace: "nowrap",
      overflow: "hidden",
      justifyContent: "flex-start",
    },
    placeLink: {
      display: "block",
      textDecoration: "none",
      color: "inherit",
    },
    placeRankNumber: {
      fontSize: "24px",
    },
    placeRankTail: {
      fontSize: "14px",
    },
    placeImage: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: "38px",
      height: "38px",
      borderRadius: "19px",
      backgroundColor: "#e2e2e2",
      display: "block",
      verticalAlign: "-7px",
      flexShrink: 0,
    },
    placeName: {
      fontSize: "20px",
      flex: "1 1 auto",
      overflow: "hidden",
      textOverflow: "ellipsis",
      minWidth: 0,
      flexGrow: 0,
      [theme.breakpoints.down("sm")]: {
        fontSize: "16px",
      },
    },
    placeNameTitle: {
      marginLeft: "4px",
      fontSize: "14px",
    },
    emptyState: {
      minHeight: "80px",
      height: "100%",
      fontSize: "16px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  });
export default styles;

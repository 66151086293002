import * as React from "react";
import { WithStyles, withStyles } from "@material-ui/core";
import { withTranslation, WithTranslation } from "react-i18next";
import styles from "./MapComponentStyles";

interface Props extends WithStyles<typeof styles>, WithTranslation {}

// eslint-disable-next-line react/jsx-no-useless-fragment
const MapComponent: React.FC<Props> = ({ children }) => <>{children}</>;

export default withTranslation()(withStyles(styles)(MapComponent));

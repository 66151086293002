import * as React from "react";
import { connect } from "react-redux";
import { RootState } from "../../../modules";
import { PhotoGalleryPageComponent } from "../../../api/odpApi";
import PhotoGalleryComponent from "../../../components/dashboard/components/PhotoGalleryComponent";
import { getUploadedFileUrl } from "../../../modules/openDataPage";

interface ExportProps {
  component: PhotoGalleryPageComponent;
}

interface StateProps {
  organizationId: number;
  openDataPageId: number;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface DispatchProps {}

type Props = ExportProps & StateProps & DispatchProps;

class PhotoGalleryComponentContainer extends React.PureComponent<Props> {
  public render(): JSX.Element {
    const { component, organizationId, openDataPageId } = this.props;
    const baseUrl = getUploadedFileUrl(organizationId, openDataPageId, "");
    return <PhotoGalleryComponent component={component} baseUrl={baseUrl} />;
  }
}

const mapStateToProps = (state: RootState): StateProps => ({
  organizationId: state.openDataPage.pageConfigurations?.config.organizationId ?? 0,
  openDataPageId: state.openDataPage.pageConfigurations?.config.openDataPageId ?? 0,
});

const mapDispatchToProps = (/* dispatch: Dispatch */): DispatchProps => ({});

export default connect(mapStateToProps, mapDispatchToProps)(PhotoGalleryComponentContainer);

import * as React from "react";
import { IconButton, WithStyles, withStyles } from "@material-ui/core";
import { withTranslation, WithTranslation } from "react-i18next";
import Dropzone from "react-dropzone";
import PublishIcon from "@material-ui/icons/Publish";
import CancelIcon from "@material-ui/icons/Cancel";
import ImageSelect from "../../../../assets/image-upload.svg";
import styles from "./ImageUploadZoneStyles";

interface Props extends WithStyles<typeof styles>, WithTranslation {
  url?: string;
  onDrop?: (file: File) => void;
  onClear?: () => void;
}

const ImageUploadZone: React.FC<Props> = ({ url, onDrop, onClear, classes }) => (
  <div className={classes.root}>
    <Dropzone
      accept={["image/jpeg", "image/png"]}
      maxFiles={1}
      minSize={10}
      multiple={false}
      onDropAccepted={(e) => {
        if (e.length !== 1) {
          return;
        }
        if (!onDrop) {
          return;
        }
        onDrop(e[0]);
      }}
    >
      {({ getRootProps, getInputProps }) => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <div {...getRootProps()} className={classes.root}>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <input {...getInputProps()} />
          {url && (
            <div className={classes.imageContainer}>
              {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
              <img className={classes.image} src={url} alt="Uploaded Image" />
              <div className={classes.buttons}>
                <IconButton>
                  <PublishIcon />
                </IconButton>
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    if (!onClear) {
                      return;
                    }
                    onClear();
                  }}
                >
                  <CancelIcon />
                </IconButton>
              </div>
            </div>
          )}
          {!url && (
            <div className={classes.placeholder}>
              <img src={ImageSelect} alt="画像を選択する" />
              <p className={classes.placeholderCaption}>画像を選択する</p>
            </div>
          )}
        </div>
      )}
    </Dropzone>
  </div>
);

export default withTranslation()(withStyles(styles)(ImageUploadZone));

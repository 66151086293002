import { Action } from "typescript-fsa";
import { all, call, put, takeEvery } from "redux-saga/effects";
import { SagaIterator } from "redux-saga";
import {
  loadRegion,
  loadRegionActivities,
  loadRegionActivitiesProgress,
  loadRegionDataboxAction,
  loadRegionDataboxActionProgress,
  LoadRegionDataboxParameters,
  loadRegionGeoJson,
  LoadRegionGeoJsonParameters,
  loadRegionGeoJsonProgress,
  LoadRegionParameters,
  loadRegionProgress,
} from "./index";
import Api, { ApiDatabox, ApiRegion, ApiRegionActivities } from "../../api/baseApi";
import GeoJsonDownloader from "../../api/geojson";
import { checkPirikaWebApiResult } from "../saga-support";
import { GeoJson } from "../models";

function* loadRegionSaga(action: Action<LoadRegionParameters>) {
  const params = action.payload;
  const progressAction = loadRegionProgress;
  yield put(progressAction.started(params));

  const result = (yield call([Api, Api.getRegionAsync], params.organizationId, params.pageId)) as ApiRegion;
  yield all(
    checkPirikaWebApiResult(
      result,
      (payload: ApiRegion) => progressAction.done({ params, result: payload }),
      () => progressAction.failed({ params, error: new Error() }),
    ),
  );
}

function* loadRegionActivitiesSaga(action: Action<LoadRegionParameters>) {
  const params = action.payload;
  const progressAction = loadRegionActivitiesProgress;
  yield put(progressAction.started(params));

  const result = (yield call(
    [Api, Api.getRegionActivitiesAsync],
    params.organizationId,
    params.pageId,
  )) as ApiRegionActivities;
  yield all(
    checkPirikaWebApiResult(
      result,
      (payload: ApiRegionActivities) => progressAction.done({ params, result: payload }),
      () => progressAction.failed({ params, error: new Error() }),
    ),
  );
}

function* loadRegionGeoJsonSaga(action: Action<LoadRegionGeoJsonParameters>) {
  const params = action.payload;
  const progressAction = loadRegionGeoJsonProgress;
  yield put(progressAction.started(params));

  try {
    const result = (yield call(
      [GeoJsonDownloader, GeoJsonDownloader.getTopoJsonZipAsync],
      params.topojsonUrl,
    )) as GeoJson;
    yield put(progressAction.done({ params, result }));
  } catch (error) {
    yield put(progressAction.failed({ params, error: error as Error }));
  }
}

function* loadRegionDataboxSaga(action: Action<LoadRegionDataboxParameters>) {
  const params = action.payload;
  const progressAction = loadRegionDataboxActionProgress;
  yield put(progressAction.started(params));

  const result = (yield call(
    [Api, Api.getRegionDataboxAsync],
    params.organizationId,
    params.pageId,
    params.regionId,
    params.parameter,
  )) as ApiDatabox;
  yield all(
    checkPirikaWebApiResult(
      result,
      (payload: ApiDatabox) => progressAction.done({ params, result: payload }),
      () => progressAction.failed({ params, error: new Error() }),
    ),
  );
}

export default function* watch(): SagaIterator {
  yield takeEvery(loadRegion.type, loadRegionSaga);
  yield takeEvery(loadRegionActivities.type, loadRegionActivitiesSaga);
  yield takeEvery(loadRegionGeoJson.type, loadRegionGeoJsonSaga);
  yield takeEvery(loadRegionDataboxAction.type, loadRegionDataboxSaga);
}

import { all, call, put, takeEvery } from "redux-saga/effects";
import { Action } from "typescript-fsa";
import { SagaIterator } from "redux-saga";
import {
  loadOpenDataPageConfiguration,
  loadOpenDataPageConfigurationProgress,
  LoadOpenDataPageConfigurationParameters,
  LoginAuthenticationError,
} from "./index";
import Api, { ApiPageDataboxMode, ApiResponse, PageConfigurations, PageDataboxType } from "../../api/odpApi";
import { checkApiResult } from "../saga-support";

const DefaultDataboxId = "**DEFAULT**";

/**
 * PageDatabox導入時のマイグレーション処理を行う
 * 21/4Qリリース後、すべてマイグレーションが終わったら削除可能
 * @param received
 */
const migratedConfiguration = (received: PageConfigurations): PageConfigurations => {
  if (received.databoxes !== undefined && received.databoxes !== null) {
    return received;
  }
  const result = { ...received };
  if (received.config.databoxMode === ApiPageDataboxMode.Region) {
    result.databoxes = [
      {
        id: DefaultDataboxId,
        name: "Default",
        type: PageDataboxType.Region,
      },
    ];
  } else if (received.config.type === "government") {
    result.databoxes = [
      {
        id: DefaultDataboxId,
        name: "Default",
        type: PageDataboxType.Area,
      },
    ];
  } else {
    result.databoxes = [
      {
        id: DefaultDataboxId,
        name: "Default",
        type: PageDataboxType.Organization,
      },
    ];
  }
  result.components = result.components.map((v) => {
    switch (v.type) {
      case "counter":
        return {
          ...v,
          config: { ...v.config, databox: result.databoxes[0].id },
        };
      case "map":
        return {
          ...v,
          config: { ...v.config, databox: result.databoxes[0].id },
        };
      case "timeline":
        return {
          ...v,
          config: { ...v.config, databox: result.databoxes[0].id },
        };
      case "counterGraph":
        return {
          ...v,
          config: { ...v.config, databox: result.databoxes[0].id },
        };
      default:
        return v;
    }
  });
  return result;
};

function* loadOpenDataPageConfigurationSaga(action: Action<LoadOpenDataPageConfigurationParameters>) {
  const params = action.payload;
  if (!params) {
    return;
  }
  const progressAction = loadOpenDataPageConfigurationProgress;
  yield put(progressAction.started(params));

  const result = (yield call(
    [Api, Api.getOpenDataPageConfigurationAsync],
    params.password,
  )) as ApiResponse<PageConfigurations>;
  yield all(
    checkApiResult(
      result,
      (payload) => progressAction.done({ params, result: migratedConfiguration(payload) }),
      (code: number) =>
        code !== 11
          ? progressAction.failed({ params, error: new Error() })
          : progressAction.failed({ params, error: LoginAuthenticationError }),
    ),
  );
}

export default function* watch(): SagaIterator {
  yield takeEvery(loadOpenDataPageConfiguration.type, loadOpenDataPageConfigurationSaga);
}

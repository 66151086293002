import * as React from "react";
import { WithStyles, withStyles } from "@material-ui/core";
import { withTranslation, WithTranslation } from "react-i18next";
import styles from "./SummaryRegionGraphStyles";
import { CounterDataPoint } from "../../../../modules/counterGraphComponents";
import CounterGraph from "../counterGraph/CounterGraph";

interface Props extends WithStyles<typeof styles>, WithTranslation {
  dataPoints: CounterDataPoint[];
}

const SummaryRegionGraph: React.FC<Props> = ({ classes, dataPoints }) => (
  <div className={classes.root}>
    <CounterGraph dataPoints={dataPoints} />
  </div>
);

export default withTranslation()(withStyles(styles)(SummaryRegionGraph));

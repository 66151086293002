import actionCreatorFactory from "typescript-fsa";
import { reducerWithInitialState } from "typescript-fsa-reducers";
import { ApiCounter } from "../../api/baseApi";
import { LoadingState } from "../models";
import { PageDatabox } from "../../api/odpApi";

// models
// ----------------------------------------

export interface CounterData {
  participants: number;
  posts: number;
  trash: number;
}

// State
// ----------------------------------------

export interface CounterComponentState {
  loadingState: LoadingState;
  counter?: CounterData;
}

const EmptyCounterComponentState: CounterComponentState = {
  loadingState: LoadingState.Initial,
};

export interface CounterComponentsState {
  components: Record<string, CounterComponentState>;
}

const initialState: CounterComponentsState = {
  components: {},
};

// Parameters
// ----------------------------------------

export interface LoadCounterParameters {
  componentId: string;
  organizationId: number;
  pageId: number;
  databox: PageDatabox;
  counterAddMetaId?: number;
}

// Support Functions
// ----------------------------------------

const lookupComponent = (
  state: CounterComponentsState,
  id: string,
): [CounterComponentsState, CounterComponentState] => {
  if (!state.components[id]) {
    const newComponent = { ...EmptyCounterComponentState };
    const newComponents = { ...state.components };
    newComponents[id] = newComponent;
    return [{ ...state, components: newComponents }, newComponent];
  }
  return [state, state.components[id]];
};

const updateComponent = (
  state: CounterComponentsState,
  id: string,
  component: CounterComponentState,
): CounterComponentsState => {
  const newComponents = { ...state.components };
  newComponents[id] = component;
  return { ...state, components: newComponents };
};

// Selectors
// ----------------------------------------

// ActionCreators
// ----------------------------------------

const actionCreator = actionCreatorFactory("PirikaOdp/CounterComponents");

export const loadCounter = actionCreator<LoadCounterParameters>("LoadCounter");
export const loadCounterProgress = actionCreator.async<LoadCounterParameters, ApiCounter, Error>("LoadCounterProgress");

// Reducer
// ----------------------------------------

const reducer = reducerWithInitialState(initialState)
  .case(loadCounterProgress.started, (state, { componentId }) => {
    const [newState, component] = lookupComponent(state, componentId);
    return updateComponent(newState, componentId, {
      ...component,
      loadingState: LoadingState.Loading,
    });
  })
  .case(loadCounterProgress.done, (state, { params, result }) => {
    const [newState, component] = lookupComponent(state, params.componentId);
    return updateComponent(newState, params.componentId, {
      ...component,
      loadingState: LoadingState.Initial,
      counter: {
        participants: result.participants,
        posts: result.post,
        trash: result.trash,
      },
    });
  })
  .case(loadCounterProgress.failed, (state, { params }) => {
    const [newState, component] = lookupComponent(state, params.componentId);
    return updateComponent(newState, params.componentId, {
      ...component,
      loadingState: LoadingState.Error,
    });
  });

export default reducer;

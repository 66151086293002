import { createStyles, StyleRules } from "@material-ui/core";

const styles = (/* theme: Theme */): StyleRules =>
  createStyles({
    root: {
      display: "flex",
      minHeight: "100px",
      height: "100%",
      justifyContent: "center",
      alignItems: "center",
    },
  });

export default styles;

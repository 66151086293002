import * as React from "react";
import { LoadingState, PirikaPost } from "../../../../modules/models";
import { ApiActivityDataPoint } from "../../../../api/baseApi";

export interface TimelineSummaryValue {
  participants: number;
  post: number;
  trash: number;
}

export const EmptyTimelineSummaryValue = {
  participants: 0,
  post: 0,
  trash: 0,
};

export interface TimelineProviderPropsValues {
  loadingState: LoadingState;
  posts?: PirikaPost[];
  lastPostId?: number;
  regionName?: string;
  summaryValue?: TimelineSummaryValue;
  summaryValueType?: "participants" | "trash";
  summaryValueTerm?: number;
  hasMore: boolean;
}

export interface TimelineProviderProps extends TimelineProviderPropsValues {
  handleLoadMore: () => void;
}

export const EmptyTimelineProviderPropsValues: TimelineProviderPropsValues = {
  loadingState: LoadingState.Initial,
  hasMore: true,
};

export type TimelineProviderCallableChildren = (props: TimelineProviderProps) => React.ReactNode;

export const accumulateCounter = (points: ApiActivityDataPoint[]): TimelineSummaryValue =>
  points.reduce(
    (prev, next) => ({
      participants: prev.participants + next.participants,
      post: prev.post + next.post,
      trash: prev.trash + next.trash,
    }),
    EmptyTimelineSummaryValue,
  );

import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import InformationComponent from "../../../components/dashboard/components/InformationComponent";
import { RootState } from "../../../modules";
import { InformationPageComponent } from "../../../api/odpApi";
import {
  InformationComponentState,
  InformationItem,
  InformationParameter,
  loadInformation as loadInformationAction,
} from "../../../modules/informationComponents";
import { LoadingState } from "../../../modules/models";

interface ExportProps {
  component: InformationPageComponent;
}

interface StateProps {
  componentsStates: Record<string, InformationComponentState>;
}

interface DispatchProps {
  loadInformation: (componentId: string, parameters: InformationParameter) => void;
}

type Props = ExportProps & StateProps & DispatchProps;

class InformationComponentContainer extends React.Component<Props> {
  public componentDidMount() {
    const { loadInformation, component } = this.props;
    loadInformation(component.id, {});
  }

  private handleMore = () => {
    const { loadInformation, component, componentsStates } = this.props;
    const { information, loadingState, isTerminated } = componentsStates[component.id];
    if (isTerminated) {
      return;
    }
    if (loadingState !== LoadingState.Initial) {
      // InfiniteScrollがあるので、ロード中は何もしない
      return;
    }
    const lastId: number | undefined = information ? information[information.length - 1]?.id : undefined;
    loadInformation(component.id, { cursorId: lastId });
  };

  public render(): JSX.Element {
    const { component, componentsStates } = this.props;
    const states: InformationComponentState = componentsStates[component.id];
    const loadingState: LoadingState =
      (states?.information === undefined && states?.loadingState !== LoadingState.Error) ||
      states?.loadingState === undefined
        ? LoadingState.Loading
        : states.loadingState;
    const information: InformationItem[] = states?.information ?? [];
    const isTerminated: boolean = states?.isTerminated !== undefined ? states.isTerminated : false;
    return (
      <InformationComponent
        component={component}
        loadingState={loadingState}
        information={information}
        hasMore={!isTerminated}
        onMore={this.handleMore}
      />
    );
  }
}

const mapStateToProps = (state: RootState): StateProps => ({
  componentsStates: state.informationComponents.components,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  loadInformation: (componentId: string, parameter: InformationParameter) =>
    dispatch(loadInformationAction({ componentId, parameter })),
});

export default connect(mapStateToProps, mapDispatchToProps)(InformationComponentContainer);

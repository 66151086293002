import * as React from "react";
import { connect } from "react-redux";
import AcknowledgementsDialog from "../../../../components/dashboard/components/footer/AcknowledgementsDialog";
import { RootState } from "../../../../modules";
import { hasPrefectureRegionDataComponent, PrefectureRegion } from "../../../../modules/prefectureRegionComponents";
import { hasRegionDataComponent, Region } from "../../../../modules/regionComponents";

interface ExportProps {
  open: boolean;
  onClose: () => void;
}

interface StateProps {
  hasPrefectureRegionData: boolean;
  hasRegionData: boolean;
  prefectureRegion?: PrefectureRegion;
  region?: Region;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface DispatchProps {}

type Props = ExportProps & StateProps & DispatchProps;

class AcknowledgementsDialogContainer extends React.PureComponent<Props> {
  public render(): JSX.Element {
    const { open, hasPrefectureRegionData, hasRegionData, prefectureRegion, region, onClose } = this.props;
    return (
      <AcknowledgementsDialog
        open={open}
        hasPrefectureRegionData={hasPrefectureRegionData}
        hasRegionData={hasRegionData}
        prefectureRegion={prefectureRegion}
        region={region}
        onCloseClick={onClose}
      />
    );
  }
}

const mapStateToProps = (state: RootState): StateProps => ({
  hasPrefectureRegionData: hasPrefectureRegionDataComponent(state),
  hasRegionData: hasRegionDataComponent(state),
  prefectureRegion: state.prefectureRegionComponents.region,
  region: state.regionComponents.region,
});

const mapDispatchToProps = (/* dispatch: Dispatch */): DispatchProps => ({});

export default connect(mapStateToProps, mapDispatchToProps)(AcknowledgementsDialogContainer);

import { createStyles, StyleRules, Theme } from "@material-ui/core";

const styles = (theme: Theme): StyleRules =>
  createStyles({
    root: {},
    map: {
      height: "50vh",
      position: "relative",
    },
    placeEditArea: {
      display: "flex",
      marginBottom: theme.spacing(2),
    },
    searchButton: {
      minWidth: "100px",
      marginLeft: theme.spacing(4),
    },
    marker: {
      position: "absolute",
      top: "50%",
      left: "50%",
      width: "1.5rem",
      height: "1.5rem",
      transform: "translateX(-50%) translateY(-50%)",
    },
  });
export default styles;

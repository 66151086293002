import actionCreatorFactory from "typescript-fsa";
import { reducerWithInitialState } from "typescript-fsa-reducers";
import {
  ApiDatabox,
  ApiRegion,
  ApiRegionActivities,
  ApiRegionPolygon,
  ApiRegionPolygonCounters,
} from "../../api/baseApi";
import { DataboxQueryParameter, GeoJson, LoadingState, PirikaPost } from "../models";
import { mergePosts } from "../functions";
import { OpenDataPageState } from "../openDataPage";

// 任意区域分割カウンタ(マップコンポーネント内任意区域分割描画・区域分割グラフ)はページ内で同じデータを共有する

// models
// ----------------------------------------

export type RegionPolygon = ApiRegionPolygon;
export interface Region {
  available: boolean;
  geojsonUrl: string;
  topojsonUrl: string;
  polygons: RegionPolygon[];
}
export type RegionPolygonCounters = ApiRegionPolygonCounters;

export interface RelativeRegionAggregateMode {
  valueType: "participants" | "trash";
  term: number | undefined;
}

// State
// ----------------------------------------

export interface RegionDataboxState {
  loadingState: LoadingState;
  posts?: PirikaPost[];
  isTerminated: boolean;
}

const EmptyRegionDataboxState: RegionDataboxState = {
  loadingState: LoadingState.Initial,
  isTerminated: false,
};

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface RegionGraphComponentState {}

// export const EmptyRegionGraphComponentState: RegionGraphComponentState = {};

export interface RegionComponentsState {
  regionLoadingState: LoadingState;
  region?: Region;
  countersLoadingState: LoadingState;
  counters?: Record<string, RegionPolygonCounters>;
  graphComponents: Record<string, RegionGraphComponentState>;
  geoJson?: GeoJson;
  databoxes: Record<string, RegionDataboxState>;
  relativeRegionAggregateMode: RelativeRegionAggregateMode;
}

const EmptyRegionComponentsState: RegionComponentsState = {
  regionLoadingState: LoadingState.Initial,
  countersLoadingState: LoadingState.Initial,
  graphComponents: {},
  databoxes: {},
  relativeRegionAggregateMode: {
    valueType: "trash",
    term: 3,
  },
};

const initialState = EmptyRegionComponentsState;

// Parameters
// ----------------------------------------

export interface LoadRegionParameters {
  organizationId: number;
  pageId: number;
}

export interface LoadRegionGeoJsonParameters {
  topojsonUrl: string;
}

export interface LoadRegionDataboxParameters {
  regionId: string;
  organizationId: number;
  pageId: number;
  parameter: DataboxQueryParameter;
}

export interface UpdateRelativeRegionAggregateModeParameters {
  relativeRegionAggregateMode: RelativeRegionAggregateMode;
}

// Selectors
// ----------------------------------------

/**
 * ページのコンフィグの中にカスタム区域分割を参照したものが存在するか判定する
 */
export const hasRegionDataComponent = (state: { openDataPage: OpenDataPageState }): boolean => {
  const components = state.openDataPage.pageConfigurations?.components || [];
  return (
    components.find((v) => {
      if (v.type === "map") {
        return (
          Boolean(v.config.regionDrawType) &&
          (v.config.regionDrawType === "static" || v.config.regionDrawType === "relative")
        );
      }
      // noinspection RedundantIfStatementJS
      if (v.type === "regionGraph") {
        return true;
      }
      return false;
    }) !== undefined
  );
};

// Support Functions
// ----------------------------------------

/* 使用するようになったらコメントアウト解除
const lookupGraphComponent = (
  state: RegionComponentsState,
  id: string
): [RegionComponentsState, RegionGraphComponentState] => {
  if (!state.graphComponents[id]) {
    const newComponent = { ...EmptyRegionGraphComponentState };
    const newComponents = { ...state.graphComponents };
    newComponents[id] = newComponent;
    return [{ ...state, graphComponents: newComponents }, newComponent];
  }
  return [state, state.graphComponents[id]];
};

const updateComponent = (
  state: RegionComponentsState,
  id: string,
  component: RegionGraphComponentState
): RegionComponentsState => {
  const newComponents = { ...state.graphComponents };
  newComponents[id] = component;
  return { ...state, graphComponents: newComponents };
};
 */

/**
 * Counter のリストから PolygonId -> PolygonCounter のマップを作成する
 * @param result
 */
const makeRegionCounterDictionary = (result: ApiRegionActivities): Record<string, RegionPolygonCounters> =>
  result.counters.reduce((prev, value) => {
    const next = prev;
    next[value.polygonId] = value;
    return next;
  }, {} as Record<string, RegionPolygonCounters>);

const lookupDatabox = (state: RegionComponentsState, regionId: string): [RegionComponentsState, RegionDataboxState] => {
  const id = regionId;
  if (!state.databoxes[id]) {
    const newDatabox = { ...EmptyRegionDataboxState };
    const newDataboxes = { ...state.databoxes };
    newDataboxes[id] = { ...EmptyRegionDataboxState };
    return [{ ...state, databoxes: newDataboxes }, newDatabox];
  }
  return [state, state.databoxes[id]];
};

const updateDatabox = (
  state: RegionComponentsState,
  regionId: string,
  databox: RegionDataboxState,
): RegionComponentsState => {
  const id = regionId;
  const newDataboxes = { ...state.databoxes };
  newDataboxes[id] = databox;
  return { ...state, databoxes: newDataboxes };
};

// ActionCreators
// ----------------------------------------

const actionCreator = actionCreatorFactory("PirikaOdp/RegionComponents");
export const loadRegion = actionCreator<LoadRegionParameters>("LoadRegion");
export const loadRegionProgress = actionCreator.async<LoadRegionParameters, ApiRegion, Error>("LoadRegionProgress");
export const loadRegionActivities = actionCreator<LoadRegionParameters>("LoadRegionActivities");
export const loadRegionActivitiesProgress = actionCreator.async<LoadRegionParameters, ApiRegionActivities, Error>(
  "LoadRegionActivitiesProgress",
);
export const loadRegionGeoJson = actionCreator<LoadRegionGeoJsonParameters>("LoadRegionGeoJson");
export const loadRegionGeoJsonProgress = actionCreator.async<LoadRegionGeoJsonParameters, GeoJson, Error>(
  "LoadRegionGeoJsonProgress",
);
export const loadRegionDataboxAction = actionCreator<LoadRegionDataboxParameters>("LoadDatabox");
export const loadRegionDataboxActionProgress = actionCreator.async<LoadRegionDataboxParameters, ApiDatabox, Error>(
  "LoadDataboxProgress",
);
export const updateRelativeRegionAggregateModeAction = actionCreator<UpdateRelativeRegionAggregateModeParameters>(
  "UpdateRelativeRegionAggregateMode",
);

// Reducer
// ----------------------------------------

const reducer = reducerWithInitialState(initialState)
  .case(loadRegion, (state) => ({
    ...state,
    regionLoadingState: LoadingState.Loading,
  }))
  .case(loadRegionProgress.started, (state) => ({
    ...state,
    regionLoadingState: LoadingState.Loading,
  }))
  .case(loadRegionProgress.done, (state, { result }) => ({
    ...state,
    regionLoadingState: LoadingState.Initial,
    region: result,
  }))
  .case(loadRegionProgress.failed, (state) => ({
    ...state,
    regionLoadingState: LoadingState.Error,
  }))
  .case(loadRegionActivitiesProgress.started, (state) => ({
    ...state,
    countersLoadingState: LoadingState.Loading,
  }))
  .case(loadRegionActivitiesProgress.done, (state, { result }) => ({
    ...state,
    countersLoadingState: LoadingState.Initial,
    counters: makeRegionCounterDictionary(result),
  }))
  .case(loadRegionActivitiesProgress.failed, (state) => ({
    ...state,
    countersLoadingState: LoadingState.Error,
  }))
  .case(loadRegionGeoJsonProgress.done, (state, { result }) => ({
    ...state,
    geoJson: result,
  }))
  .case(loadRegionDataboxActionProgress.started, (state, { regionId }) => {
    const [newState, databox] = lookupDatabox(state, regionId);
    return updateDatabox(newState, regionId, {
      ...databox,
      loadingState: LoadingState.Loading,
    });
  })
  .case(loadRegionDataboxActionProgress.done, (state, { params, result }) => {
    const [newState, databox] = lookupDatabox(state, params.regionId);
    const byArea = params.parameter.latlng !== undefined && params.parameter.zoomLevel !== undefined;
    return updateDatabox(newState, params.regionId, {
      ...databox,
      loadingState: LoadingState.Initial,
      posts: mergePosts(databox.posts ?? [], result.info),
      isTerminated: databox.isTerminated || (!byArea && result.info.length === 0),
    });
  })
  .case(loadRegionDataboxActionProgress.failed, (state, { params }) => {
    const [newState, databox] = lookupDatabox(state, params.regionId);
    return updateDatabox(newState, params.regionId, {
      ...databox,
      loadingState: LoadingState.Error,
    });
  })
  .case(updateRelativeRegionAggregateModeAction, (state, { relativeRegionAggregateMode }) => ({
    ...state,
    relativeRegionAggregateMode,
  }));
export default reducer;

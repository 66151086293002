// https://stackoverflow.com/questions/28016890/how-to-make-a-color-similar-to-another-color-by-percentage-in-javascript

const generateHex = (nR: number, nG: number, nB: number): string => {
  let r = nR.toString(16);
  let g = nG.toString(16);
  let b = nB.toString(16);

  // to address problem mentioned by Alexis Wilke:
  while (r.length < 2) {
    r = `0${r}`;
  }
  while (g.length < 2) {
    g = `0${g}`;
  }
  while (b.length < 2) {
    b = `0${b}`;
  }

  return `#${r}${g}${b}`;
};

const mix = (start: number, end: number, percent: number): number => start + percent * (end - start);

// eslint-disable-next-line import/prefer-default-export
export const approximateColor1ToColor2ByPercent = (color1: string, color2: string, percent: number): string => {
  const red1 = parseInt(color1[1] + color1[2], 16);
  const green1 = parseInt(color1[3] + color1[4], 16);
  const blue1 = parseInt(color1[5] + color1[6], 16);

  const red2 = parseInt(color2[1] + color2[2], 16);
  const green2 = parseInt(color2[3] + color2[4], 16);
  const blue2 = parseInt(color2[5] + color2[6], 16);

  const red = Math.round(mix(red1, red2, percent));
  const green = Math.round(mix(green1, green2, percent));
  const blue = Math.round(mix(blue1, blue2, percent));

  return generateHex(red, green, blue);
};

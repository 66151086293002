import * as React from "react";
import { Grid } from "@material-ui/core";

import useMediaQuery from "../useMediaQuery";
import { PageComponentSize, PageComponent } from "../../../api/odpApi";

const WidgetGridItem = ({
  component,
  children,
}: {
  component: PageComponent;
  children: JSX.Element | React.ReactNode;
}): JSX.Element => {
  const mediaQuery = useMediaQuery();
  let gridType;

  if (component.size === PageComponentSize.L) {
    gridType = "A";
  } else if (component.size === PageComponentSize.M) {
    gridType = "B";
  } else if (component.size === PageComponentSize.S) {
    gridType = "C";
  }

  const GridA = (
    <Grid item xs={12} sm={12} md={12} lg={12}>
      {children}
    </Grid>
  );
  const GridB = (
    <Grid item xs={12} sm={12} md={8} lg={8}>
      {children}
    </Grid>
  );

  const GridC = (
    <Grid item xs={12} sm={12} md={4} lg={4}>
      {children}
    </Grid>
  );

  const allowedCount = component.allowScreens
    .map(
      (screen: PageComponentSize) =>
        (mediaQuery.isL.matches && screen === PageComponentSize.L) ||
        (mediaQuery.isM.matches && screen === PageComponentSize.M) ||
        (mediaQuery.isS.matches && screen === PageComponentSize.S),
    )
    .filter(Boolean).length;

  if (allowedCount > 0) {
    return (
      <>
        {gridType === "A" && GridA}
        {gridType === "B" && GridB}
        {gridType === "C" && GridC}
      </>
    );
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <></>;
};

export default WidgetGridItem;

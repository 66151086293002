import * as React from "react";
import { WithStyles, withStyles } from "@material-ui/core";
import { withTranslation, WithTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import GoogleMapReact from "google-map-react";
import styles from "./MapContentsStyles";
import { MapPageComponent } from "../../../../api/odpApi";
import { RelativeRegionCounters } from "./index";
import { GeoBounds, GeoPoint, isEqualGeoBounds, locationStringToGeoPoint } from "../../../../modules/geolocation";
import { approximateColor1ToColor2ByPercent } from "../../../../helpers/color";
import { usePrevious } from "../../../../helpers";
import WidgetGridItem from "../WidgetGridItem";
import Constants from "../../../../constants";
import MapPin from "../parts/MapPin";
import MapBalloon from "../parts/MapBalloon";
import { GeoJson, LoadingState, PirikaPost } from "../../../../modules/models";
import useMediaQuery from "../../useMediaQuery";

const calculateScale = (value: number, min: number, max: number): number =>
  max !== min ? Math.max((value - min) / (max - min), 0) : 0;

interface Props extends WithStyles<typeof styles>, WithTranslation {
  component: MapPageComponent;
  // eslint-disable-next-line react/no-unused-prop-types
  loadingState: LoadingState;
  posts: PirikaPost[];
  selectedPost: PirikaPost | undefined;
  geoJson?: GeoJson;
  regionCounters?: RelativeRegionCounters;
  regionLowerColor: string;
  regionHigherColor: string;
  regionStrokeColor?: string;
  legends?: React.ReactNode;
  maxValue?: number;
  onItemClick: (id: number) => void;
  onClearSelected: () => void;
  onChangeBounds: (bounds: GeoBounds, center: GeoPoint, zoomLevel: number) => void;
  onShowDetail: (id: number) => void;
  onRegionClick: (regionId: string) => void;
  onPinHoverChanged: (hovered: boolean) => void;
  customizePinImageUrl?: string;
}

const hiddenRegionMapDataStyle: google.maps.Data.StyleOptions = {
  visible: false,
};

const staticRegionMapDataStyle: google.maps.Data.StylingFunction = (feature): google.maps.Data.StyleOptions => {
  const regionId = feature.getProperty("pirika_region_id") as string;
  if (!regionId) {
    return {
      visible: false,
    };
  }
  const backgroundColor = feature.getProperty("pirika_region_background_color") as string;
  const strokeColor = feature.getProperty("pirika_region_stroke_color") as string;
  return {
    clickable: true,
    fillColor: backgroundColor,
    strokeColor: strokeColor || backgroundColor,
    fillOpacity: 0.6,
  };
};

const createRelativeRegionMapDataStyle =
  (
    regionCounters: RelativeRegionCounters,
    regionLowerColor: string,
    regionHigherColor: string,
    regionStrokeColor?: string,
    restrictedMaxValue?: number,
  ): google.maps.Data.StylingFunction =>
  (feature) => {
    const regionId = (feature.getProperty("pirika_region_id") as string) || (feature.getProperty("name") as string);
    if (!regionCounters || !regionId) {
      return {
        visible: false,
      };
    }
    const maxValue = restrictedMaxValue ?? regionCounters.maxValue;
    const value = regionCounters.values[regionId] ?? 0;
    const scale = calculateScale(Math.min(value, maxValue), regionCounters.minValue, maxValue);
    const color = approximateColor1ToColor2ByPercent(regionLowerColor, regionHigherColor, scale);
    return {
      clickable: true,
      fillColor: color,
      strokeColor: regionStrokeColor ?? color,
      fillOpacity: 0.6,
    };
  };

const MapContents: React.FC<Props> = ({
  component,
  posts,
  selectedPost,
  geoJson,
  regionCounters,
  regionLowerColor,
  regionHigherColor,
  regionStrokeColor,
  legends,
  maxValue,
  onItemClick,
  onClearSelected,
  onChangeBounds,
  onShowDetail,
  onRegionClick,
  onPinHoverChanged,
  customizePinImageUrl,
  classes,
}) => {
  const mapBounds = {
    southWest: locationStringToGeoPoint(component.config.southWest) ?? {
      latitude: 0,
      longitude: 0,
    },
    northEast: locationStringToGeoPoint(component.config.northEast) ?? {
      latitude: 0,
      longitude: 0,
    },
  };
  const [googleMap, setGoogleMap] = useState<google.maps.Map | undefined>(undefined);
  const prevMapBounds = usePrevious(mapBounds);
  const mediaQuery = useMediaQuery();

  const fitBounds = () => {
    if (!googleMap || !mapBounds) {
      return;
    }
    googleMap.fitBounds(
      new google.maps.LatLngBounds(
        new google.maps.LatLng(mapBounds.southWest.latitude, mapBounds.southWest.longitude),
        new google.maps.LatLng(mapBounds.northEast.latitude, mapBounds.northEast.longitude),
      ),
    );
  };

  const offsetCenter = (
    latlng: google.maps.LatLng,
    offsetX: number,
    offsetY: number,
  ): google.maps.LatLng | undefined => {
    const projection = googleMap?.getProjection();
    if (!googleMap || !projection) {
      return undefined;
    }
    const zoom = googleMap.getZoom() ?? 0;
    const scale = 2 ** zoom;

    const worldCoordinateCenter = projection.fromLatLngToPoint(latlng);
    if (!worldCoordinateCenter) {
      return undefined;
    }
    const pixelOffset = new google.maps.Point(offsetX / scale || 0, offsetY / scale || 0);

    const worldCoordinateNewCenter = new google.maps.Point(
      worldCoordinateCenter.x - pixelOffset.x,
      worldCoordinateCenter.y + pixelOffset.y,
    );

    return projection.fromPointToLatLng(worldCoordinateNewCenter) || undefined;
  };

  // 表示領域アップデート
  useEffect(() => {
    if (!googleMap) {
      return;
    }
    googleMap.data.addListener("click", (e: google.maps.Data.MouseEvent) => {
      const regionId =
        (e.feature.getProperty("pirika_region_id") as string) || (e.feature.getProperty("name") as string);
      if (regionId && !selectedPost) {
        e.stop();
        onRegionClick(regionId);
      }
    });
    fitBounds();
  }, [googleMap]);
  useEffect(() => {
    if (prevMapBounds && mapBounds && isEqualGeoBounds(prevMapBounds, mapBounds)) {
      return;
    }
    fitBounds();
  }, [mapBounds]);

  useEffect(() => {
    if (!googleMap) {
      return;
    }
    if (selectedPost) {
      if (!mediaQuery.isL.matches) {
        onShowDetail(selectedPost.id);
        return;
      }
      const center = offsetCenter(new google.maps.LatLng(selectedPost.lat, selectedPost.lng), 0, -80);
      if (center) {
        googleMap.panTo(center);
      }
    }
  }, [googleMap, selectedPost]);

  useEffect(() => {
    if (!googleMap || !geoJson) {
      return;
    }
    googleMap.data.addGeoJson(geoJson);
  }, [googleMap, geoJson]);

  useEffect(() => {
    if (!googleMap || !geoJson) {
      return;
    }
    const drawType = component.config.regionDrawType;
    if ((drawType === "relative" || drawType === "prefecture") && regionCounters) {
      googleMap.data.setStyle(
        createRelativeRegionMapDataStyle(
          regionCounters,
          regionLowerColor,
          regionHigherColor,
          regionStrokeColor,
          maxValue,
        ),
      );
    } else if (component.config.regionDrawType === "static") {
      googleMap.data.setStyle(staticRegionMapDataStyle);
    } else {
      googleMap.data.setStyle(hiddenRegionMapDataStyle);
    }
  }, [googleMap, geoJson, regionCounters, regionLowerColor, regionHigherColor, component]);

  return (
    <WidgetGridItem component={component}>
      <div
        className={classes.root}
        style={{
          height: component.config.customizeHeight ? `${component.config.customizeHeight}px` : "400px",
        }}
      >
        <GoogleMapReact
          yesIWantToUseGoogleMapApiInternals
          bootstrapURLKeys={{ key: Constants.googleApiKey }}
          defaultCenter={{ lat: 0, lng: 0 }}
          defaultZoom={1}
          options={{
            clickableIcons: false,
            fullscreenControl: false,
          }}
          onGoogleApiLoaded={(p: { map: google.maps.Map }): void => setGoogleMap(p.map)}
          onClick={(): void => {
            if (selectedPost) {
              onClearSelected();
            }
          }}
          onChange={(e) =>
            onChangeBounds(
              {
                northEast: {
                  latitude: e.marginBounds.ne.lat,
                  longitude: e.marginBounds.ne.lng,
                },
                southWest: {
                  latitude: e.marginBounds.sw.lat,
                  longitude: e.marginBounds.sw.lng,
                },
              },
              { latitude: e.center.lat, longitude: e.center.lng },
              e.zoom,
            )
          }
        >
          {posts.map((v) => (
            <MapPin
              key={v.id}
              lat={v.lat}
              lng={v.lng}
              post={v}
              onClick={() => onItemClick(v.id)}
              onMouseEnter={() => onPinHoverChanged(true)}
              onMouseLeave={() => onPinHoverChanged(false)}
              imageUrl={customizePinImageUrl}
            />
          ))}
          {selectedPost && mediaQuery.isL.matches && (
            <MapBalloon
              lat={selectedPost.lat}
              lng={selectedPost.lng}
              post={selectedPost}
              onDetailClick={() => onShowDetail(selectedPost.id)}
            />
          )}
        </GoogleMapReact>
        {legends}
      </div>
    </WidgetGridItem>
  );
};

export default withTranslation()(withStyles(styles)(MapContents));

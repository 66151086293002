import * as React from "react";
import { TextField, WithStyles, withStyles } from "@material-ui/core";
import { withTranslation, WithTranslation } from "react-i18next";
import DatePicker from "react-datepicker";
import { addDays } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import styles from "./DateTimePickerStyles";

interface Props extends WithStyles<typeof styles>, WithTranslation {
  className: string;
  start: Date | undefined;
  end: Date | undefined;
  onStartChange?: (date: Date) => void;
  onEndChange?: (date: Date) => void;
  // eslint-disable-next-line max-len
  onBlur: {
    (e: React.FocusEvent<unknown>): void;
    <T = unknown>(fieldOrEvent: T): T extends string ? (e: unknown) => void : void;
  };
  startDateError?: boolean;
  startDateHelperText?: React.ReactNode;
  endDateError?: boolean;
  endDateHelperText?: React.ReactNode;
}

const DateTimePicker: React.FC<Props> = ({
  className,
  start,
  end,
  onStartChange,
  onEndChange,
  onBlur,
  startDateError,
  startDateHelperText,
  endDateError,
  endDateHelperText,
  classes,
}) => {
  const handleStartChange = (date: Date) => {
    if (!onStartChange) {
      return;
    }
    onStartChange(date);
  };

  const handleEndChange = (date: Date) => {
    if (!onEndChange) {
      return;
    }
    onEndChange(date);
  };

  return (
    <div className={`${classes.root} ${className}`}>
      <div className={classes.datePickerContainer}>
        <DatePicker
          popperClassName={classes.datePickerPopper}
          name="start"
          selected={start}
          onChange={handleStartChange}
          showTimeSelect
          timeFormat="HH:mm"
          dateFormat="yyyy/MM/d h:mm aa"
          required
          minDate={addDays(new Date(), 1)}
          popperPlacement="top-start"
          customInput={
            <TextField
              label="開始日時"
              onBlur={onBlur}
              error={startDateError}
              helperText={startDateHelperText}
              fullWidth
            />
          }
        />
      </div>
      <div className={classes.datePickerContainer}>
        <DatePicker
          popperClassName={classes.datePickerPopper}
          name="end"
          selected={end}
          minDate={start ? addDays(start, 0) : undefined}
          onChange={handleEndChange}
          showTimeSelect
          timeFormat="HH:mm"
          dateFormat="yyyy/MM/d h:mm aa"
          popperPlacement="top-start"
          required
          customInput={
            <TextField label="終了日時" onBlur={onBlur} error={endDateError} helperText={endDateHelperText} fullWidth />
          }
        />
      </div>
    </div>
  );
};

export default withTranslation()(withStyles(styles)(DateTimePicker));

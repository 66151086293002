import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import TimelineHeader from "../../../../components/dashboard/components/timeline/TimelineHeader";
import { RootState } from "../../../../modules";
import { blurDataboxRegionAction, DataboxComponentState } from "../../../../modules/databoxComponents";
import { TimelinePageComponent } from "../../../../api/odpApi";
import Constants from "../../../../constants";
import { TimelineSummaryValue } from "./index";

interface ExportProps {
  component: TimelinePageComponent;
  databoxId: string;
  regionName?: string;
  summaryValue?: TimelineSummaryValue;
  summaryValueType?: "participants" | "trash";
  summaryValueTerm?: number;
}

interface StateProps {
  databoxes: Record<string, DataboxComponentState>;
}

interface DispatchProps {
  blurDataboxRegion: (databoxId: string) => void;
}

type Props = ExportProps & StateProps & DispatchProps;

class TimelineHeaderContainer extends React.PureComponent<Props> {
  private handleClose = () => {
    const { databoxId, blurDataboxRegion } = this.props;
    blurDataboxRegion(databoxId);
  };

  public render(): JSX.Element {
    const { component, databoxId, databoxes, regionName, summaryValue, summaryValueType, summaryValueTerm } =
      this.props;
    const databox = databoxes[databoxId];
    const open = Boolean(databox?.focusedRegion);
    const backgroundColor = component.config.backgroundColor ?? Constants.defaultHeaderBackgroundColor;

    // TODO: カウンタの表示が微妙だったので表示の必要性を考える
    const showParticipants = summaryValueType === "participants";
    const showTrashes = summaryValueType === "trash";
    return (
      <TimelineHeader
        open={open}
        regionName={regionName ?? ""}
        backgroundColor={backgroundColor}
        showParticipants={showParticipants}
        showTrashes={showTrashes}
        participants={summaryValue?.participants}
        trashes={summaryValue?.trash}
        term={summaryValueTerm}
        onClose={this.handleClose}
      />
    );
  }
}

const mapStateToProps = (state: RootState): StateProps => ({
  databoxes: state.databoxComponents.databoxes,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  blurDataboxRegion: (databoxId: string) => dispatch(blurDataboxRegionAction({ databoxId })),
});

export default connect(mapStateToProps, mapDispatchToProps)(TimelineHeaderContainer);

import { all, call, put, takeEvery } from "redux-saga/effects";
import { Action } from "typescript-fsa";
import { SagaIterator } from "redux-saga";
import { loadOrganizations, LoadOrganizationsParameters, loadOrganizationsProgress } from "./index";
import Api, { ApiGovernmentNonIndividuals } from "../../api/baseApi";
import { checkPirikaWebApiResult } from "../saga-support";

function* loadOrganizationsSaga(action: Action<LoadOrganizationsParameters>) {
  const params = action.payload;
  const progressAction = loadOrganizationsProgress;
  yield put(progressAction.started(params));

  const result = (yield call(
    [Api, Api.getGovernmentNonIndividualsAsync],
    params.organizationId,
    params.pageId,
    params.blockUserListId,
  )) as ApiGovernmentNonIndividuals;
  yield all(
    checkPirikaWebApiResult(
      result,
      (payload: ApiGovernmentNonIndividuals) => progressAction.done({ params, result: payload }),
      () => progressAction.failed({ params, error: new Error() }),
    ),
  );
}

export default function* watch(): SagaIterator {
  yield takeEvery(loadOrganizations.type, loadOrganizationsSaga);
}

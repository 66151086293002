declare let PirikaOdpConfig: Record<string, string>;

const Constants = {
  googleApiKey: PirikaOdpConfig.googleApiKey,
  googleGeocodeApiKey: PirikaOdpConfig.googleGeocodeApiKey,
  pirikaWebEndpoint: PirikaOdpConfig.pirikaWebEndpoint,
  snsServiceUrl: PirikaOdpConfig.snsServiceUrl,
  snsHowtoUrl: PirikaOdpConfig.snsHowtoUrl,
  cloudStorageBaseUrl: PirikaOdpConfig.cloudStorageBaseUrl,
  defaultDataboxItemCount: 10,
  fetchDataboxItemCount: 10,
  defaultScaleBarLowerColor: "#96c97e",
  defaultScaleBarHigherColor: "#508d30",
  defaultHeaderBackgroundColor: "#1470cc",
};

export default Constants;

import { createStyles, StyleRules, Theme } from "@material-ui/core";

const styles = (theme: Theme): StyleRules =>
  createStyles({
    root: {
      display: "flex",
      alignItems: "center",
    },
    closeButtonContainer: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    informationContainer: {
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    areaName: {
      fontSize: "14px",
      paddingBottom: "2px",
    },
    counterContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      marginBottom: theme.spacing(0.5),
      fontSize: "12px",
    },
    counterTitle: {
      paddingRight: theme.spacing(0.5),
    },
    counterContent: {},
    counterDigit: {
      display: "inline-block",
      width: "14px",
      marginLeft: "1px",
      "& svg": {
        verticalAlign: "middle",
      },
    },
  });

export default styles;

import * as React from "react";
import { WithStyles, withStyles } from "@material-ui/core";
import { withTranslation, WithTranslation } from "react-i18next";
import styles from "./CounterGraphComponentStyles";
import WidgetGridItem from "./WidgetGridItem";
import { CounterGraphPageComponent } from "../../../api/odpApi";
import { CounterDataPoint } from "../../../modules/counterGraphComponents";
import ComponentLoadingStateView from "./parts/ComponentLoadingStateView";
import ComponentErrorStateView from "./parts/ComponentErrorStateView";
import ComponentContents from "./parts/ComponentContents";
import ComponentTitle from "./parts/ComponentTitle";
import ComponentFrame from "./parts/ComponentFrame";
import { LoadingState } from "../../../modules/models";
import CounterGraph from "./counterGraph/CounterGraph";

interface Props extends WithStyles<typeof styles>, WithTranslation {
  component: CounterGraphPageComponent;
  loadingState: LoadingState;
  dataPoints: CounterDataPoint[];
}

const CounterGraphComponent: React.FC<Props> = (props) => {
  const { component, dataPoints, classes, loadingState } = props;
  return (
    <WidgetGridItem component={component}>
      <ComponentFrame className={classes.root}>
        {loadingState === LoadingState.Initial && (
          <ComponentContents>
            <ComponentTitle
              accessoryLinkTitle={component.config.accessoryLinkTitle}
              accessoryLinkTarget={component.config.accessoryLinkTarget}
              accessoryTooltipText={component.config.accessoryTooltipText}
            >
              {component.title}
            </ComponentTitle>
            <CounterGraph dataPoints={dataPoints} />
          </ComponentContents>
        )}
        {loadingState === LoadingState.Loading && <ComponentLoadingStateView />}
        {loadingState === LoadingState.Error && <ComponentErrorStateView />}
      </ComponentFrame>
    </WidgetGridItem>
  );
};

export default withTranslation()(withStyles(styles)(CounterGraphComponent));

import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import OrganizationsComponent from "../../../components/dashboard/components/OrganizationsComponent";
import { RootState } from "../../../modules";
import { OrganizationsPageComponent } from "../../../api/odpApi";
import {
  loadOrganizations as loadOrganizationsAction,
  OrganizationsComponentState,
} from "../../../modules/organizationsComponents";
import { LoadingState } from "../../../modules/models";

interface ExportProps {
  component: OrganizationsPageComponent;
}

interface StateProps {
  organizationId: number;
  openDataPageId: number;
  componentsStates: Record<string, OrganizationsComponentState>;
}

interface DispatchProps {
  loadOrganizations: (
    componentId: string,
    organizationId: number,
    pageId: number,
    blockUserListId: number | undefined,
  ) => void;
}

type Props = ExportProps & StateProps & DispatchProps;

class OrganizationsComponentContainer extends React.Component<Props> {
  public componentDidMount() {
    const { loadOrganizations, component, organizationId, openDataPageId } = this.props;
    loadOrganizations(component.id, organizationId, openDataPageId, component.config.blockUserListId);
  }

  public render(): JSX.Element {
    const { component, componentsStates } = this.props;
    const states = componentsStates[component.id];
    const loadingState =
      (states?.organizations === undefined && states?.loadingState !== LoadingState.Error) ||
      states?.loadingState === undefined
        ? LoadingState.Loading
        : states.loadingState;
    const organizations = states?.organizations;
    return <OrganizationsComponent component={component} loadingState={loadingState} organizations={organizations} />;
  }
}

const mapStateToProps = (state: RootState): StateProps => ({
  componentsStates: state.organizationsComponents.components,
  organizationId: state.openDataPage.pageConfigurations?.config.organizationId ?? 0,
  openDataPageId: state.openDataPage.pageConfigurations?.config.openDataPageId ?? 0,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  loadOrganizations: (componentId, organizationId, pageId, blockUserListId) =>
    dispatch(loadOrganizationsAction({ componentId, organizationId, pageId, blockUserListId })),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationsComponentContainer);

import * as React from "react";
import { WithStyles, withStyles } from "@material-ui/core";
import { withTranslation, WithTranslation } from "react-i18next";
import WidgetGridWrapper from "./WidgetGridWrapper";
import styles from "./DashboardPageStyles";

interface Props extends WithStyles<typeof styles>, WithTranslation {}

const DashboardPage: React.FC<Props> = ({ classes, children }) => (
  <div className={classes.root}>
    <WidgetGridWrapper>{children}</WidgetGridWrapper>
  </div>
);

export default withTranslation()(withStyles(styles)(DashboardPage));

import * as React from "react";
import { WithStyles, withStyles, Grid } from "@material-ui/core";
import { withTranslation, WithTranslation } from "react-i18next";
import styles from "./WidgetGridWrapperStyles";

interface Props extends WithStyles<typeof styles>, WithTranslation {}

const WidgetGridWrapper: React.FC<Props> = ({ children }) => <Grid container>{children}</Grid>;

export default withTranslation()(withStyles(styles)(WidgetGridWrapper));

import * as React from "react";
import { connect } from "react-redux";
import { FooterPageComponent } from "../../../api/odpApi";
import { RootState } from "../../../modules";
import FooterComponent from "../../../components/dashboard/components/FooterComponent";
import { getUploadedFileUrl } from "../../../modules/openDataPage";
import AcknowledgementsDialogContainer from "./footer/AcknowledgementsDialogContainer";
import { hasPrefectureRegionDataComponent } from "../../../modules/prefectureRegionComponents";
import { hasRegionDataComponent } from "../../../modules/regionComponents";

interface ExportProps {
  component: FooterPageComponent;
}

interface StateProps {
  pageTitle: string;
  organizationId: number;
  openDataPageId: number;
  hasPrefectureRegionData: boolean;
  hasRegionData: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface DispatchProps {}

type Props = ExportProps & StateProps & DispatchProps;

interface State {
  isLoadWidgets: boolean;
  openAcknowledgements: boolean;
}

class FooterComponentContainer extends React.Component<Props, State> {
  public constructor(props: Props, context: unknown) {
    super(props, context);
    this.state = { isLoadWidgets: false, openAcknowledgements: false };
  }

  public componentDidMount() {
    /**
     * isLoadWidgetsでTwitterのShareボタンに利用するwidgets.jsが読み込まれたかどうかを制御、1回だけjsファイルが読み込まれるように調整する
     * PR: https://github.com/pirika-inc/PIRIKA-odp-web/pull/46
     */
    const { isLoadWidgets } = this.state;
    if (!isLoadWidgets) {
      const s = document.createElement("script");
      s.setAttribute("src", "https://platform.twitter.com/widgets.js");
      document.body.appendChild(s);
      this.setState({
        isLoadWidgets: true,
      });
    }
  }

  private handleAcknowledgementsClick = () => {
    this.setState({ openAcknowledgements: true });
  };

  private handleCloseAcknowledgements = () => {
    this.setState({ openAcknowledgements: false });
  };

  public render(): JSX.Element {
    const { component, pageTitle, organizationId, openDataPageId, hasRegionData, hasPrefectureRegionData } = this.props;
    const { openAcknowledgements } = this.state;
    if (!component) {
      // eslint-disable-next-line react/jsx-no-useless-fragment
      return <></>;
    }
    const logoUrl = component.config.logoImage
      ? getUploadedFileUrl(organizationId, openDataPageId, component.config.logoImage)
      : "";
    return (
      <>
        <FooterComponent
          component={component}
          pageTitle={pageTitle}
          logoUrl={logoUrl}
          hasExternalData={hasRegionData || hasPrefectureRegionData}
          onAcknowledgementsClick={this.handleAcknowledgementsClick}
        />
        <AcknowledgementsDialogContainer open={openAcknowledgements} onClose={this.handleCloseAcknowledgements} />
      </>
    );
  }
}

const mapStateToProps = (state: RootState): StateProps => ({
  pageTitle: state.openDataPage.pageConfigurations?.config.title ?? "",
  organizationId: state.openDataPage.pageConfigurations?.config.organizationId ?? 0,
  openDataPageId: state.openDataPage.pageConfigurations?.config.openDataPageId ?? 0,
  hasPrefectureRegionData: hasPrefectureRegionDataComponent(state),
  hasRegionData: hasRegionDataComponent(state),
});

const mapDispatchToProps = (/* dispatch: Dispatch */): DispatchProps => ({});

export default connect(mapStateToProps, mapDispatchToProps)(FooterComponentContainer);

import { all, call, put, takeEvery } from "redux-saga/effects";
import { Action } from "typescript-fsa";
import { SagaIterator } from "redux-saga";
import { registerEvent, registerEventProgress, EventRegisterParams } from "./index";
import { checkApiResult } from "../saga-support";
import Api, { ApiEventApprovalRegister, ApiResponse } from "../../api/odpApi";

function* registerEventSaga(action: Action<EventRegisterParams>) {
  const params = action.payload;
  const progressAction = registerEventProgress;
  yield put(progressAction.started(params));

  const result = (yield call([Api, Api.registerEventAsync], params)) as ApiResponse<ApiEventApprovalRegister>;
  yield all(
    checkApiResult(
      result,
      (payload) => progressAction.done({ params, result: payload }),
      () => progressAction.failed({ params, error: new Error() }),
    ),
  );
}

export default function* watch(): SagaIterator {
  yield takeEvery(registerEvent.type, registerEventSaga);
}

import * as React from "react";
import { connect } from "react-redux";
import UsageComponent from "../../../components/dashboard/components/UsageComponent";
import { UsagePageComponent } from "../../../api/odpApi";

interface ExportProps {
  component: UsagePageComponent;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface StateProps {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface DispatchProps {}

type Props = ExportProps & StateProps & DispatchProps;

class UsageComponentContainer extends React.PureComponent<Props> {
  public render(): JSX.Element {
    const { component } = this.props;
    return <UsageComponent component={component} />;
  }
}

const mapStateToProps = (/* state: RootState */): StateProps => ({});

const mapDispatchToProps = (/* dispatch: Dispatch */): DispatchProps => ({});

export default connect(mapStateToProps, mapDispatchToProps)(UsageComponentContainer);

import axios, { AxiosInstance } from "axios";
import { parseISO } from "date-fns";
import { PirikaBaseApi } from "./interface";
import {
  ApiActivities,
  ApiCounter,
  ApiDatabox,
  ApiDataboxParameter,
  ApiEvent,
  ApiEventDetail,
  ApiEvents,
  ApiGovernmentNonIndividuals,
  ApiPost,
  ApiPrefectureRegion,
  ApiRawDatabox,
  ApiRawDataboxItem,
  ApiRawEventDetail,
  ApiRawEvents,
  ApiRawGovernmentNonIndividuals,
  ApiRegion,
  ApiRegionActivities,
  ApiResponse,
  ApiResponseStatus,
  ApiRevGeocode,
  ApiUserList,
} from "./model";
import Constants from "../../constants";

const parseDate = (dateString: string): Date => parseISO(`${dateString}Z`);

const rawDataboxItemToPost = (item: ApiRawDataboxItem): ApiPost => ({
  city: item.city,
  comment: item.comment,
  countComment: item.count_comment,
  countLike: item.count_like,
  countSum: item.count_sum,
  country: item.country,
  date: parseDate(item.date),
  id: item.id,
  imgUrl: item.img_url,
  lat: item.lat,
  lng: item.lng,
  prefecture: item.prefecture,
  prettyDate: item.pretty_date,
  profileImageUrl: item.profimg_url,
  thumbnailUrl: item.thumb_url,
  trashSum: item.trash_sum,
  userId: item.userid,
  userName: item.username,
});

export class PirikaBaseApiImplements implements PirikaBaseApi {
  private axios: AxiosInstance;

  public constructor() {
    this.axios = axios.create({
      baseURL: Constants.pirikaWebEndpoint,
      headers: {
        "X-Pirika-Platform": "web",
        "X-Pirika-Lang": "JP",
      },
      responseType: "json",
      validateStatus: (status) => status < 500,
    });
  }

  public getCounterAsync = async (
    organizationId: number,
    pageId: number,
    counterAddMetaId?: number,
  ): Promise<ApiCounter | ApiResponse> => {
    try {
      if (counterAddMetaId) {
        // 2024年6月現在、ハッシュタグ以外は未対応 https://pirika.docbase.io/posts/3411729
        throw new Error("Not implemented");
      }
      const response = await this.axios.get<ApiCounter>("/biz/counter", {
        params: {
          organization_id: organizationId,
          page_id: pageId,
        },
      });
      return response.data;
    } catch (e) {
      return { status: ApiResponseStatus.Error, code: -1 };
    }
  };

  public getDataboxAsync = async (
    organizationId: number,
    pageId: number,
    parameters: ApiDataboxParameter,
  ): Promise<ApiDatabox | ApiResponse> => {
    try {
      const params = {
        organization_id: organizationId,
        page_id: pageId,
        latlng: parameters.latlng,
        zoom_level: parameters.zoomLevel,
        after_id: parameters.afterId,
        before_id: parameters.beforeId,
        after_date: parameters.afterDate,
        before_date: parameters.beforeDate,
        count: parameters.count,
      };
      const response = await this.axios.get<ApiRawDatabox>("/biz/databox", {
        params,
      });
      const { data } = response;
      return {
        ...data,
        info: data.info.map(rawDataboxItemToPost),
      };
    } catch (e) {
      return { status: ApiResponseStatus.Error, code: -1 };
    }
  };

  public getRegionDataboxAsync = async (
    organizationId: number,
    pageId: number,
    polygonId: string | undefined,
    parameters: ApiDataboxParameter,
  ): Promise<ApiDatabox | ApiResponse> => {
    try {
      const params = {
        organization_id: organizationId,
        page_id: pageId,
        polygon_id: polygonId,
        latlng: parameters.latlng,
        zoom_level: parameters.zoomLevel,
        after_id: parameters.afterId,
        before_id: parameters.beforeId,
        after_date: parameters.afterDate,
        before_date: parameters.beforeDate,
        count: parameters.count,
      };
      const response = await this.axios.get<ApiRawDatabox>("/biz/region/databox", {
        params,
      });
      const { data } = response;
      return {
        ...data,
        info: data.info.map(rawDataboxItemToPost),
      };
    } catch (e) {
      return { status: ApiResponseStatus.Error, code: -1 };
    }
  };

  public getActivitiesAsync = async (
    organizationId: number,
    pageId: number,
    counterAddMetaId?: number,
  ): Promise<ApiActivities | ApiResponse> => {
    try {
      if (counterAddMetaId) {
        // 2024年6月現在、ハッシュタグ以外は未対応 https://pirika.docbase.io/posts/3411729
        throw new Error("Not implemented");
      }
      const response = await this.axios.get<ApiActivities>("/biz/activities", {
        params: {
          organization_id: organizationId,
          page_id: pageId,
        },
      });
      return response.data;
    } catch (e) {
      return { status: ApiResponseStatus.Error, code: -1 };
    }
  };

  public getRankingAsync = async (
    organizationId: number,
    pageId: number,
    type: string,
    aggregateBy?: string,
    period?: string,
    exceptUserListId?: number,
  ): Promise<ApiActivities | ApiResponse> => {
    try {
      const params: Record<string, string | number> = {
        organization_id: organizationId,
        page_id: pageId,
        type,
      };
      if (aggregateBy !== undefined) {
        params.aggregateBy = aggregateBy;
      }
      if (period !== undefined) {
        params.period = period;
      }
      if (exceptUserListId !== undefined) {
        params.exceptUserListId = exceptUserListId;
      }
      const response = await this.axios.get<ApiActivities>("/biz/ranking", {
        params,
      });
      return response.data;
    } catch (e) {
      return { status: ApiResponseStatus.Error, code: -1 };
    }
  };

  public getGovernmentNonIndividualsAsync = async (
    organizationId: number,
    pageId: number,
    blockUserListId: number | undefined,
  ): Promise<ApiGovernmentNonIndividuals | ApiResponse> => {
    try {
      const params: Record<string, string | number> = {
        organization_id: organizationId,
        page_id: pageId,
      };
      if (blockUserListId !== undefined) {
        params.block_user_list_id = blockUserListId;
      }
      const response = await this.axios.get<ApiRawGovernmentNonIndividuals>("/biz/governments/not_individuals", {
        params,
      });
      const { data } = response;
      return {
        ...data,
        publicOrganizations: data.organizations_public,
        privateOrganizations: data.organizations_private,
      };
    } catch (e) {
      return { status: ApiResponseStatus.Error, code: -1 };
    }
  };

  public getGovernmentEventsAsync = (
    organizationId: number,
    pageId: number,
    count?: number,
    cursorId?: number,
    all?: boolean,
  ): Promise<ApiEvents | ApiResponse> =>
    this.getEventsAsync("/biz/governments/events", {
      organization_id: organizationId,
      page_id: pageId,
      count,
      cursor_id: cursorId,
      is_all: all === true ? "1" : undefined,
    });

  public getGroupEventsAsync = (
    organizationId: number,
    pageId: number,
    groupId: number,
    count?: number,
    cursorId?: number,
  ): Promise<ApiEvents | ApiResponse> =>
    this.getEventsAsync("/biz/groups/events", {
      organization_id: organizationId,
      page_id: pageId,
      group_id: groupId,
      count,
      cursor_id: cursorId,
    });

  private async getEventsAsync(
    path: string,
    params: Record<string, string | number | undefined>,
  ): Promise<ApiEvents | ApiResponse> {
    try {
      const response = await this.axios.get<ApiRawEvents>(path, {
        params,
      });
      const { data } = response;
      return {
        ...data,
        events: data.events.map(
          (v): ApiEvent => ({
            creationDate: v.creation_date,
            endDate: parseDate(v.end_date),
            endLocalDate: v.end_local_date,
            eventId: v.eventid,
            isFuture: v.is_future,
            lat: v.lat,
            lng: v.lng,
            place: v.place,
            startDate: parseDate(v.start_date),
            startLocalDate: v.start_local_date,
            thumbnailUrl: v.thumb_url,
            title: v.title,
            updateDate: v.update_date,
            isPublic: v.is_public,
          }),
        ),
      };
    } catch (e) {
      return { status: ApiResponseStatus.Error, code: -1 };
    }
  }

  public getEventDetailAsync = async (eventId: number): Promise<ApiEventDetail | ApiResponse> => {
    try {
      const response = await this.axios.get<ApiRawEventDetail>("eventdetail", {
        params: {
          eventid: eventId,
        },
      });
      const { data } = response;
      return {
        status: data.status,
        code: data.code,
        message: data.message,
        creationDate: parseDate(data.creation_date),
        endDate: parseDate(data.end_date),
        eventId,
        lat: data.lat,
        lng: data.lng,
        place: data.place,
        startDate: parseDate(data.start_date),
        thumbnailUrl: data.thumb_url,
        title: data.title,
        updateDate: parseDate(data.update_date),
        isPublic: data.is_public,
        comment: data.comment,
        hashtags: data.hashtags,
        authorName: data.author_name,
        authorId: data.author_id,
        imageUrl: data.img_url,
      };
    } catch (e) {
      return { status: ApiResponseStatus.Error, code: -1 };
    }
  };

  public getRegionAsync = async (organizationId: number, pageId: number): Promise<ApiRegion | ApiResponse> => {
    try {
      const response = await this.axios.get<ApiRegion>("/biz/region/", {
        params: {
          organization_id: organizationId,
          page_id: pageId,
        },
      });
      return response.data;
    } catch (e) {
      return { status: ApiResponseStatus.Error, code: -1 };
    }
  };

  public getRegionCounterAsync = async (
    organizationId: number,
    pageId: number,
    counterAddMetaId?: number,
  ): Promise<ApiCounter | ApiResponse> => {
    try {
      if (counterAddMetaId) {
        // 2024年6月現在、ハッシュタグ以外は未対応 https://pirika.docbase.io/posts/3411729
        throw new Error("Not implemented");
      }
      const response = await this.axios.get<ApiCounter>("/biz/region/counter", {
        params: {
          organization_id: organizationId,
          page_id: pageId,
        },
      });
      return response.data;
    } catch (e) {
      return { status: ApiResponseStatus.Error, code: -1 };
    }
  };

  public getRegionActivitiesAsync = async (
    organizationId: number,
    pageId: number,
    counterAddMetaId?: number,
  ): Promise<ApiRegionActivities | ApiResponse> => {
    try {
      if (counterAddMetaId) {
        // 2024年6月現在、ハッシュタグ以外は未対応 https://pirika.docbase.io/posts/3411729
        throw new Error("Not implemented");
      }
      const response = await this.axios.get<ApiRegionActivities>("/biz/region/activities", {
        params: {
          organization_id: organizationId,
          page_id: pageId,
        },
      });
      return response.data;
    } catch (e) {
      return { status: ApiResponseStatus.Error, code: -1 };
    }
  };

  public getPrefectureRegionAsync = async (
    organizationId: number,
    pageId: number,
  ): Promise<ApiPrefectureRegion | ApiResponse> => {
    try {
      const response = await this.axios.get<ApiPrefectureRegion>("/biz/prefectureRegion/", {
        params: {
          organization_id: organizationId,
          page_id: pageId,
        },
      });
      return response.data;
    } catch (e) {
      return { status: ApiResponseStatus.Error, code: -1 };
    }
  };

  public getPrefectureRegionActivitiesAsync = async (
    organizationId: number,
    pageId: number,
  ): Promise<ApiRegionActivities | ApiResponse> => {
    try {
      const response = await this.axios.get<ApiRegionActivities>("/biz/prefectureRegion/activities", {
        params: {
          organization_id: organizationId,
          page_id: pageId,
        },
      });
      return response.data;
    } catch (e) {
      return { status: ApiResponseStatus.Error, code: -1 };
    }
  };

  public getPrefectureRegionDataboxAsync = async (
    organizationId: number,
    pageId: number,
    polygonId: string,
    parameters: ApiDataboxParameter,
  ): Promise<ApiDatabox | ApiResponse> => {
    try {
      const params = {
        organization_id: organizationId,
        page_id: pageId,
        polygon_id: polygonId,
        after_id: parameters.afterId,
        before_id: parameters.beforeId,
        after_date: parameters.afterDate,
        before_date: parameters.beforeDate,
        count: parameters.count,
      };
      const response = await this.axios.get<ApiRawDatabox>("/biz/prefectureRegion/databox", {
        params,
      });
      const { data } = response;
      return {
        ...data,
        info: data.info.map(rawDataboxItemToPost),
      };
    } catch (e) {
      return { status: ApiResponseStatus.Error, code: -1 };
    }
  };

  public getRevGeocodeAsync = async (lat: number, lng: number): Promise<ApiRevGeocode | undefined> => {
    try {
      const response = await this.axios.get<ApiRevGeocode>("/revgeocode", {
        params: {
          lat,
          lng,
        },
      });
      return response.data;
    } catch (e) {
      return undefined;
    }
  };

  public getUserListAsync = async (
    organizationId: number,
    pageId: number,
    listId: number,
  ): Promise<ApiUserList | ApiResponse> => {
    try {
      const params = {
        organization_id: organizationId,
        page_id: pageId,
        user_list_id: listId,
      };
      const response = await this.axios.get<ApiRawDatabox>("/biz/userLists/users", {
        params,
      });
      const { data } = response;
      return data;
    } catch (e) {
      return { status: ApiResponseStatus.Error, code: -1 };
    }
  };

  public getHashtagCounterAsync = async (
    organizationId: number,
    pageId: number,
    hashtag: string,
    counterAddMetaId?: number,
  ): Promise<ApiCounter | ApiResponse> => {
    try {
      const params: Record<string, string | number> = {
        organization_id: organizationId,
        page_id: pageId,
        hashtag,
      };
      if (counterAddMetaId) {
        params.counter_add_meta_id = counterAddMetaId;
      }

      const response = await this.axios.get<ApiCounter>("/biz/hashtag/counter", {
        params,
      });
      return response.data;
    } catch (e) {
      return { status: ApiResponseStatus.Error, code: -1 };
    }
  };

  public getHashtagDataboxAsync = async (
    organizationId: number,
    pageId: number,
    hashtag: string,
    parameters: ApiDataboxParameter,
  ): Promise<ApiDatabox | ApiResponse> => {
    try {
      const params = {
        organization_id: organizationId,
        page_id: pageId,
        hashtag,
        latlng: parameters.latlng,
        zoom_level: parameters.zoomLevel,
        after_id: parameters.afterId,
        before_id: parameters.beforeId,
        after_date: parameters.afterDate,
        before_date: parameters.beforeDate,
        count: parameters.count,
      };
      const response = await this.axios.get<ApiRawDatabox>("/biz/hashtag/databox", {
        params,
      });
      const { data } = response;
      return {
        ...data,
        info: data.info.map(rawDataboxItemToPost),
      };
    } catch (e) {
      return { status: ApiResponseStatus.Error, code: -1 };
    }
  };

  public getHashtagActivitiesAsync = async (
    organizationId: number,
    pageId: number,
    hashtag: string,
    counterAddMetaId?: number,
  ): Promise<ApiActivities | ApiResponse> => {
    try {
      const params: Record<string, string | number> = {
        organization_id: organizationId,
        page_id: pageId,
        hashtag,
      };
      if (counterAddMetaId) {
        params.counter_add_meta_id = counterAddMetaId;
      }
      const response = await this.axios.get<ApiActivities>("/biz/hashtag/activities", {
        params,
      });
      return response.data;
    } catch (e) {
      return { status: ApiResponseStatus.Error, code: -1 };
    }
  };
}

export default PirikaBaseApiImplements;

import * as React from "react";
import { connect } from "react-redux";
import SummaryRegionGraph from "../../../../components/dashboard/components/regionGraph/SummaryRegionGraph";
import { RegionGraphPageComponent } from "../../../../api/odpApi";
import { Region, RegionPolygonCounters } from "../../../../modules/regionComponents";
import { RootState } from "../../../../modules";
import { accumulateDataPoints, CounterDataPoint } from "../../../../modules/counterGraphComponents";

interface ExportProps {
  // eslint-disable-next-line react/no-unused-prop-types
  component: RegionGraphPageComponent;
}

interface StateProps {
  region?: Region;
  counters?: Record<string, RegionPolygonCounters>;
  originPeriod?: string;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface DispatchProps {}

type Props = ExportProps & StateProps & DispatchProps;

interface State {
  dataPoints: CounterDataPoint[] | undefined;
}

class SummaryRegionGraphContainer extends React.PureComponent<Props, State> {
  public constructor(props: Props, context: unknown) {
    super(props, context);
    this.state = { dataPoints: undefined };
  }

  public componentDidMount() {
    this.generateDataPoints();
  }

  public componentDidUpdate(prevProps: Readonly<Props>) {
    const { region, counters, originPeriod } = this.props;
    if (prevProps.region !== region || prevProps.counters !== counters || prevProps.originPeriod !== originPeriod) {
      this.generateDataPoints();
    }
  }

  private generateDataPoints = () => {
    const { region, counters, originPeriod } = this.props;
    if (!region || !counters || !originPeriod) {
      return;
    }
    // すべての区域のカウンターを集計してエリア全体のカウンタ合計を得る
    const workMap: Record<string, CounterDataPoint> = {};
    Object.values(counters).forEach((regionCounter) =>
      regionCounter.counters.forEach((dataPoint) => {
        if (!workMap[dataPoint.period]) {
          workMap[dataPoint.period] = {
            period: dataPoint.period,
            participants: 0,
            post: 0,
            trash: 0,
          };
        }
        workMap[dataPoint.period].participants += dataPoint.participants;
        workMap[dataPoint.period].post += dataPoint.post;
        workMap[dataPoint.period].trash += dataPoint.trash;
      }),
    );

    // 生成されたカウンタ区域を配列にし、period順ソート
    const dataPoints = accumulateDataPoints(
      Object.values(workMap).sort((a, b) => parseInt(a.period, 10) - parseInt(b.period, 10)),
    );
    this.setState({ dataPoints });
  };

  public render(): JSX.Element | null {
    const { dataPoints } = this.state;
    if (!dataPoints) {
      return null;
    }
    return <SummaryRegionGraph dataPoints={dataPoints} />;
  }
}

const mapStateToProps = (state: RootState): StateProps => ({
  region: state.regionComponents.region,
  counters: state.regionComponents.counters,
  originPeriod: state.openDataPage.pageConfigurations?.config.originPeriod,
});

const mapDispatchToProps = (/* dispatch: Dispatch */): DispatchProps => ({});

export default connect(mapStateToProps, mapDispatchToProps)(SummaryRegionGraphContainer);

import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { RootState } from "../../../../modules";
import { DataboxQueryParameter, LoadingState } from "../../../../modules/models";
import { DataboxComponentState, loadDatabox as loadDataboxAction } from "../../../../modules/databoxComponents";
import Constants from "../../../../constants";
import { PageDatabox, TimelinePageComponent } from "../../../../api/odpApi";
import { TimelineProviderCallableChildren, TimelineProviderProps } from "./index";
import { selectDataboxConfig } from "../../../../modules/openDataPage";

interface ExportProps {
  children: TimelineProviderCallableChildren | React.ReactNode;
  component: TimelinePageComponent;
  databoxId: string;
}

interface StateProps {
  organizationId: number;
  openDataPageId: number;
  databoxes: Record<string, DataboxComponentState>;
  databoxConfig: PageDatabox | undefined;
}

interface DispatchProps {
  loadDatabox: (
    databoxConfig: PageDatabox,
    componentId: string,
    organizationId: number,
    pageId: number,
    params: DataboxQueryParameter,
  ) => void;
}

type Props = ExportProps & StateProps & DispatchProps;

class TimelineProvider extends React.PureComponent<Props> {
  public componentDidMount() {
    const { component, openDataPageId, organizationId, databoxConfig, loadDatabox } = this.props;
    const count = Constants.defaultDataboxItemCount;
    if (!databoxConfig) {
      return;
    }
    loadDatabox(databoxConfig, component.id, organizationId, openDataPageId, { count });
  }

  private getProviderProps = (): TimelineProviderProps => {
    const { databoxes, databoxId } = this.props;
    const databox = databoxes[databoxId];
    const loadingState =
      (databox?.posts === undefined && databox?.loadingState !== LoadingState.Error) ||
      databox?.loadingState === undefined
        ? LoadingState.Loading
        : databox.loadingState;
    const posts = databox?.posts;
    const lastPostId = posts ? posts[posts.length - 1]?.id : undefined;
    const hasMore = databox ? !databox.isTerminated : true;
    return { loadingState, posts, lastPostId, hasMore, handleLoadMore: this.handleLoadMore };
  };

  private handleLoadMore = () => {
    const { component, organizationId, openDataPageId, loadDatabox, databoxId, databoxes, databoxConfig } = this.props;
    if (!databoxConfig) {
      return;
    }
    const databox = databoxes[databoxId];
    if (databox.isTerminated) {
      return;
    }
    const props = this.getProviderProps();
    if (props.loadingState === LoadingState.Initial) {
      loadDatabox(databoxConfig, component.id, organizationId, openDataPageId, {
        beforeId: props.lastPostId,
        count: Constants.fetchDataboxItemCount,
      });
    }
  };

  public render(): React.ReactNode {
    const { children } = this.props;
    const props = this.getProviderProps();
    return typeof children === "function" ? (children as TimelineProviderCallableChildren)(props) : children;
  }
}

const mapStateToProps = (state: RootState, ownProps: ExportProps): StateProps => ({
  databoxes: state.databoxComponents.databoxes,
  organizationId: state.openDataPage.pageConfigurations?.config.organizationId ?? 0,
  openDataPageId: state.openDataPage.pageConfigurations?.config.openDataPageId ?? 0,
  databoxConfig: selectDataboxConfig(state, ownProps.component.config.databox),
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  loadDatabox: (databoxConfig, componentId, organizationId, pageId, parameter) =>
    dispatch(loadDataboxAction({ databox: databoxConfig, componentId, organizationId, pageId, parameter })),
});

export default connect(mapStateToProps, mapDispatchToProps)(TimelineProvider);

import * as React from "react";
import { connect } from "react-redux";
import LocationInput from "../../../../components/dashboard/eventRegister/parts/LocationInput";
import { geocode, revGeocode } from "../../../../helpers/geocode";
import { GeoPoint } from "../../../../modules/geolocation";

interface ExportProps {
  children: React.ReactNode;
  initialLat?: number;
  initialLng?: number;
  initialPlace?: string;
  onPlaceUpdated: (lat: number, lng: number, place: string) => void;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface StateProps {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface DispatchProps {}

type Props = ExportProps & StateProps & DispatchProps;

interface State {
  placeEditing: string;
  location: GeoPoint;
}

class LocationInputContainer extends React.Component<Props, State> {
  public constructor(props: Props, context: unknown) {
    super(props, context);

    const { initialPlace, initialLat, initialLng } = props;
    this.state = {
      placeEditing: initialPlace ?? "",
      location: {
        latitude: initialLat ?? 0,
        longitude: initialLng ?? 0,
      },
    };
  }

  public componentDidMount() {
    const { initialPlace, initialLat, initialLng } = this.props;
    if (initialPlace && !(initialLat && initialLng)) {
      this.updatePlace(initialPlace);
    }
    if (!initialPlace && initialLat && initialLng) {
      this.updatePlace(undefined, {
        latitude: initialLat,
        longitude: initialLng,
      });
    }
  }

  private onMapMoved = (latitude: number, longitude: number) => {
    this.updatePlace(undefined, { latitude, longitude });
  };

  private onPlaceEdited = (placeEditing: string) => {
    this.setState({
      placeEditing,
    });
  };

  private handlePlaceSearch = () => {
    const { placeEditing } = this.state;
    this.updatePlace(placeEditing);
  };

  private updatePlace = (place?: string, location?: GeoPoint) => {
    const { onPlaceUpdated } = this.props;
    if (place) {
      void geocode(place).then((newLocation) => {
        this.setState({
          location: newLocation,
        });
        onPlaceUpdated(newLocation.latitude, newLocation.longitude, place);
      });
    }
    if (location) {
      void revGeocode(location.latitude, location.longitude).then((newPlace) => {
        this.setState({
          placeEditing: newPlace,
        });
        onPlaceUpdated(location.latitude, location.longitude, newPlace);
      });
    }
  };

  public render(): JSX.Element {
    const { children } = this.props;
    const { placeEditing, location } = this.state;
    return (
      <>
        <LocationInput
          place={placeEditing}
          lat={location.latitude}
          lng={location.longitude}
          onMapMoved={this.onMapMoved}
          onPlaceEdited={this.onPlaceEdited}
          handlePlaceSearch={this.handlePlaceSearch}
        />
        {children}
      </>
    );
  }
}

const mapStateToProps = (/* state: RootState */): StateProps => ({});

const mapDispatchToProps = (/* dispatch: Dispatch */): DispatchProps => ({});

export default connect(mapStateToProps, mapDispatchToProps)(LocationInputContainer);

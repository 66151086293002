import { createStyles, StyleRules, Theme } from "@material-ui/core";

const styles = (theme: Theme): StyleRules =>
  createStyles({
    root: {
      marginTop: theme.spacing(4),
      alignItems: "center",
    },
    container: {
      display: "flex",
      justifyContent: "center",
    },
  });
export default styles;

import { createStyles, StyleRules, Theme } from "@material-ui/core";

const styles = (theme: Theme): StyleRules =>
  createStyles({
    root: {},
    datePickerContainer: {
      marginTop: theme.spacing(2),
    },
    // FIXME(Niccari): スマホ向けに、画面が小さいときにもDate,Time入力部が横に並ぶようにしている
    datePickerPopper: {
      width: "110%",
    },
  });
export default styles;

import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import RegionGraphComponent from "../../../components/dashboard/components/RegionGraphComponent";
import { RootState } from "../../../modules";
import { RegionGraphPageComponent } from "../../../api/odpApi";
import {
  loadRegion as loadRegionAction,
  loadRegionActivities as loadRegionActivitiesAction,
  Region,
  RegionGraphComponentState,
  RegionPolygonCounters,
} from "../../../modules/regionComponents";
import StackedBarRegionGraphContainer from "./regionGraph/StackedBarRegionGraphContainer";
import { LoadingState } from "../../../modules/models";
import SummaryRegionGraphContainer from "./regionGraph/SummaryRegionGraphContainer";

interface ExportProps {
  component: RegionGraphPageComponent;
}

interface StateProps {
  organizationId: number;
  openDataPageId: number;
  regionLoadingState: LoadingState;
  countersLoadingState: LoadingState;
  region?: Region;
  counters?: Record<string, RegionPolygonCounters>;
  // eslint-disable-next-line react/no-unused-prop-types
  componentsStates: Record<string, RegionGraphComponentState>;
}

interface DispatchProps {
  loadRegion: (organizationId: number, openDataPageId: number) => void;
  loadRegionActivities: (organizationId: number, openDataPageId: number) => void;
}

type Props = ExportProps & StateProps & DispatchProps;

class RegionGraphComponentContainer extends React.Component<Props> {
  public componentDidMount() {
    const { region, loadRegion, organizationId, openDataPageId } = this.props;
    if (!region) {
      loadRegion(organizationId, openDataPageId);
    }
  }

  public componentDidUpdate(prevProps: Readonly<Props>) {
    const {
      region,
      counters,
      regionLoadingState,
      countersLoadingState,
      loadRegionActivities,
      organizationId,
      openDataPageId,
    } = this.props;
    // 何も更新がないときはスキップ
    if (
      region?.available === prevProps.region?.available &&
      regionLoadingState === prevProps.regionLoadingState &&
      counters === prevProps.counters &&
      countersLoadingState === prevProps.countersLoadingState
    ) {
      return;
    }
    if (
      regionLoadingState === LoadingState.Initial &&
      countersLoadingState === LoadingState.Initial &&
      region &&
      region.available &&
      !counters
    ) {
      loadRegionActivities(organizationId, openDataPageId);
    }
  }

  public render(): JSX.Element {
    const { component, regionLoadingState, countersLoadingState } = this.props;
    const loadingState = (() => {
      if (regionLoadingState === LoadingState.Loading || countersLoadingState === LoadingState.Loading) {
        return LoadingState.Loading;
      }
      if (regionLoadingState === LoadingState.Error || countersLoadingState === LoadingState.Error) {
        return LoadingState.Error;
      }
      return LoadingState.Initial;
    })();
    return (
      <RegionGraphComponent component={component} loadingState={loadingState}>
        {component.config.chartType === "stackedBar" && <StackedBarRegionGraphContainer component={component} />}
        {component.config.chartType === "summary" && <SummaryRegionGraphContainer component={component} />}
      </RegionGraphComponent>
    );
  }
}

const mapStateToProps = (state: RootState): StateProps => ({
  componentsStates: state.regionComponents.graphComponents,
  region: state.regionComponents.region,
  counters: state.regionComponents.counters,
  regionLoadingState: state.regionComponents.regionLoadingState,
  countersLoadingState: state.regionComponents.countersLoadingState,
  organizationId: state.openDataPage.pageConfigurations?.config.organizationId ?? 0,
  openDataPageId: state.openDataPage.pageConfigurations?.config.openDataPageId ?? 0,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  loadRegion: (organizationId: number, openDataPageId: number) =>
    dispatch(loadRegionAction({ organizationId, pageId: openDataPageId })),
  loadRegionActivities: (organizationId: number, openDataPageId: number) =>
    dispatch(loadRegionActivitiesAction({ organizationId, pageId: openDataPageId })),
});

export default connect(mapStateToProps, mapDispatchToProps)(RegionGraphComponentContainer);

import { createStyles, StyleRules, Theme } from "@material-ui/core";

const styles = (theme: Theme): StyleRules =>
  createStyles({
    root: {},
    errorMessage: {
      marginTop: theme.spacing(0.5),
      color: theme.palette.error.main,
    },
  });
export default styles;

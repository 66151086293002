import actionCreatorFactory from "typescript-fsa";
import { reducerWithInitialState } from "typescript-fsa-reducers";
import { ApiActivities, ApiActivityDataPoint } from "../../api/baseApi";
import { numberYearMonthToPeriod, periodToNumberYearMonth } from "../../helpers/date";
import { LoadingState } from "../models";
import { PageDatabox } from "../../api/odpApi";

// models
// ----------------------------------------

export type CounterDataPoint = ApiActivityDataPoint;

// State
// ----------------------------------------

export interface CounterGraphComponentState {
  loadingState: LoadingState;
  dataPoints?: CounterDataPoint[];
}

const EmptyCounterGraphComponentState: CounterGraphComponentState = {
  loadingState: LoadingState.Initial,
};

export interface CounterGraphComponentsState {
  components: Record<string, CounterGraphComponentState>;
}

const initialState: CounterGraphComponentsState = {
  components: {},
};

// Parameters
// ----------------------------------------

export interface LoadCounterGraphActivitiesParameters {
  componentId: string;
  organizationId: number;
  pageId: number;
  databox: PageDatabox;
  counterAddMetaId?: number;
}

// Support Functions
// ----------------------------------------

const lookupComponent = (
  state: CounterGraphComponentsState,
  id: string,
): [CounterGraphComponentsState, CounterGraphComponentState] => {
  if (!state.components[id]) {
    const newComponent = { ...EmptyCounterGraphComponentState };
    const newComponents = { ...state.components };
    newComponents[id] = newComponent;
    return [{ ...state, components: newComponents }, newComponent];
  }
  return [state, state.components[id]];
};

const updateComponent = (
  state: CounterGraphComponentsState,
  id: string,
  component: CounterGraphComponentState,
): CounterGraphComponentsState => {
  const newComponents = { ...state.components };
  newComponents[id] = component;
  return { ...state, components: newComponents };
};

const fillMissingDataPoints = (points: CounterDataPoint[]): CounterDataPoint[] => {
  if (points.length < 2) {
    return points;
  }
  const result: CounterDataPoint[] = [];
  const remainPoints = [...points];
  let [y, m] = periodToNumberYearMonth(remainPoints[0].period);
  do {
    const period = numberYearMonthToPeriod(y, m);
    if (remainPoints[0].period === period) {
      result.push(
        remainPoints.shift() ?? {
          period,
          participants: 0,
          post: 0,
          trash: 0,
        },
      );
    } else {
      result.push({
        period,
        participants: 0,
        post: 0,
        trash: 0,
      });
    }
    m += 1;
    if (m > 12) {
      y += 1;
      m = 1;
    }
  } while (remainPoints.length > 0);

  return result;
};

/**
 * データポイントを積算に変換する
 * @param dataPoints
 * @private
 */
export const accumulateDataPoints = (dataPoints: CounterDataPoint[]): CounterDataPoint[] => {
  if (dataPoints.length < 2) {
    return dataPoints;
  }
  const dataPoint: CounterDataPoint = {
    period: "",
    participants: 0,
    post: 0,
    trash: 0,
  };
  const results: CounterDataPoint[] = [];
  dataPoints.forEach((v) => {
    dataPoint.participants += v.participants;
    dataPoint.post += v.post;
    dataPoint.trash += v.trash;
    results.push({
      ...dataPoint,
      period: v.period,
    });
  });

  return results;
};

// Selectors
// ----------------------------------------

// ActionCreators
// ----------------------------------------

const actionCreator = actionCreatorFactory("PirikaOdp/CounterGraphComponents");

export const loadCounterGraphActivities =
  actionCreator<LoadCounterGraphActivitiesParameters>("LoadCounterGraphActivities");
export const loadCounterGraphActivitiesProgress = actionCreator.async<
  LoadCounterGraphActivitiesParameters,
  ApiActivities,
  Error
>("LoadCounterGraphActivitiesProgress");

// Reducer
// ----------------------------------------

const reducer = reducerWithInitialState(initialState)
  .case(loadCounterGraphActivitiesProgress.started, (state, { componentId }) => {
    const [newState, component] = lookupComponent(state, componentId);
    return updateComponent(newState, componentId, {
      ...component,
      loadingState: LoadingState.Loading,
    });
  })
  .case(loadCounterGraphActivitiesProgress.done, (state, { params, result }) => {
    const [newState, component] = lookupComponent(state, params.componentId);
    return updateComponent(newState, params.componentId, {
      ...component,
      loadingState: LoadingState.Initial,
      dataPoints: fillMissingDataPoints(result.points),
    });
  })
  .case(loadCounterGraphActivitiesProgress.failed, (state, { params }) => {
    const [newState, component] = lookupComponent(state, params.componentId);
    return updateComponent(newState, params.componentId, {
      ...component,
      loadingState: LoadingState.Error,
    });
  });

export default reducer;

/**
 * 数値の年月からperiodに変換する
 * @param year
 * @param month
 */
export const numberYearMonthToPeriod = (year: number, month: number): string =>
  `0000${year}`.slice(-4) + `00${month}`.slice(-2);

/**
 * periodから数値の年月に変換する
 * @param period
 */
export const periodToNumberYearMonth = (period: string): number[] => {
  const y = parseInt(period.substr(0, 4), 10);
  const m = parseInt(period.substr(4, 2), 10);
  return [y, m];
};

/**
 * periodからその月の1日のDateを得る
 * @param period
 */
export const periodToDate = (period: string): Date => {
  const [y, m] = periodToNumberYearMonth(period);
  return new Date(y, m - 1, 1);
};

/**
 * dateからその月のperiodを得る
 * @param date
 */
export const dateToPeriod = (date: Date): string => numberYearMonthToPeriod(date.getFullYear(), date.getMonth() + 1);

/**
 * 指定された日付の年度に属するperiodの一覧を生成する
 * @param date
 * @param origin
 */
export const generateYearPeriod = (date: Date, origin = 1): string[] => {
  let year = date.getFullYear();
  if (date.getMonth() + 1 < origin) {
    year -= 1;
  }
  const results = [];
  for (let i = 0; i < 12; i += 1) {
    let y = year;
    let m = origin + i;
    if (m > 12) {
      y += 1;
      m -= 12;
    }
    const period = `0000${y}`.slice(-4) + `00${m}`.slice(-2);
    results.push(period);
  }
  return results;
};

/**
 * 指定された年月の間のperiodを生成する
 * @param startDate
 * @param endDate
 */
export const generatePeriods = (startDate: Date, endDate: Date): string[] => {
  const results: string[] = [];
  const startPeriod = dateToPeriod(startDate);
  const endPeriod = dateToPeriod(endDate);
  if (startPeriod >= endPeriod) {
    return results;
  }
  let period = startPeriod;
  while (period <= endPeriod) {
    results.push(period);
    let [y, m] = periodToNumberYearMonth(period);
    m += 1;
    if (m > 12) {
      y += 1;
      m -= 12;
    }
    period = numberYearMonthToPeriod(y, m);
  }
  return results;
};

import { createStyles, StyleRules, Theme } from "@material-ui/core";

const styles = (theme: Theme): StyleRules =>
  createStyles({
    root: {},
    dialogTitle: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    dialogCloseButton: {
      marginRight: theme.spacing(1),
    },
    image: {
      width: "100%",
      marginBottom: theme.spacing(2),
    },
    captionText: {
      color: theme.palette.grey["600"],
    },
    captionImage: {
      width: "16px",
      verticalAlign: "-5px",
      marginRight: theme.spacing(1),
    },
    commentText: {
      marginBottom: theme.spacing(2),
    },
  });
export default styles;

import * as React from "react";
import { WithStyles, withStyles, TextField, Button, Box } from "@material-ui/core";
import { withTranslation, WithTranslation } from "react-i18next";
import LockIcon from "@material-ui/icons/Lock";
import styles from "./ConfigurationLoginFailedStateScreenStyles";

interface Props extends WithStyles<typeof styles>, WithTranslation {
  password: string;
  onPasswordChange: (value: string) => void;
  onPasswordSubmit: () => void;
}

const ConfigurationLoginFailedStateScreen: React.FC<Props> = ({
  classes,
  password,
  onPasswordChange,
  onPasswordSubmit,
}) => (
  <div className={classes.root}>
    <div className={classes.contentsFrame}>
      <LockIcon fontSize="large" />
      <p className={classes.paragraph}>このページはパスワードで保護されています。</p>
      <form
        onSubmit={(e) => {
          onPasswordSubmit();
          e.preventDefault();
          return false;
        }}
      >
        <p>
          <TextField name="password" value={password} onChange={(e) => onPasswordChange(e.target.value)} />
        </p>
        <Box display="flex" justifyContent="center">
          <Button variant="contained" type="submit">
            Login
          </Button>
        </Box>
      </form>
    </div>
  </div>
);

export default withTranslation()(withStyles(styles)(ConfigurationLoginFailedStateScreen));

import actionCreatorFactory from "typescript-fsa";
import { reducerWithInitialState } from "typescript-fsa-reducers";
import { ApiRanking, ApiRankingPlace } from "../../api/baseApi";
import { LoadingState } from "../models";

// models
// ----------------------------------------

export type RankingPlace = ApiRankingPlace;

// State
// ----------------------------------------

export interface RankingComponentState {
  loadingState: LoadingState;
  places?: RankingPlace[];
}

const EmptyRankingComponentState: RankingComponentState = {
  loadingState: LoadingState.Initial,
};

export interface RankingComponentsState {
  components: Record<string, RankingComponentState>;
}

const initialState: RankingComponentsState = {
  components: {},
};

// Parameters
// ----------------------------------------

export interface LoadRankingParameters {
  componentId: string;
  organizationId: number;
  pageId: number;
  mode: string;
  aggregateBy?: string;
  period?: string;
  exceptUserListId?: number;
}

// Support Functions
// ----------------------------------------

const lookupComponent = (
  state: RankingComponentsState,
  id: string,
): [RankingComponentsState, RankingComponentState] => {
  if (!state.components[id]) {
    const newComponent = { ...EmptyRankingComponentState };
    const newComponents = { ...state.components };
    newComponents[id] = newComponent;
    return [{ ...state, components: newComponents }, newComponent];
  }
  return [state, state.components[id]];
};

const updateComponent = (
  state: RankingComponentsState,
  id: string,
  component: RankingComponentState,
): RankingComponentsState => {
  const newComponents = { ...state.components };
  newComponents[id] = component;
  return { ...state, components: newComponents };
};

// Selectors
// ----------------------------------------

// ActionCreators
// ----------------------------------------

const actionCreator = actionCreatorFactory("PirikaOdp/RankingComponents");

export const loadRanking = actionCreator<LoadRankingParameters>("LoadRanking");
export const loadRankingProgress = actionCreator.async<LoadRankingParameters, ApiRanking, Error>("LoadRankingProgress");

// Reducer
// ----------------------------------------

const reducer = reducerWithInitialState(initialState)
  .case(loadRankingProgress.started, (state, { componentId }) => {
    const [newState, component] = lookupComponent(state, componentId);
    return updateComponent(newState, componentId, {
      ...component,
      loadingState: LoadingState.Loading,
    });
  })
  .case(loadRankingProgress.done, (state, { params, result }) => {
    const [newState, component] = lookupComponent(state, params.componentId);
    return updateComponent(newState, params.componentId, {
      ...component,
      loadingState: LoadingState.Initial,
      places: result.entities,
    });
  })
  .case(loadRankingProgress.failed, (state, { params }) => {
    const [newState, component] = lookupComponent(state, params.componentId);
    return updateComponent(newState, params.componentId, {
      ...component,
      loadingState: LoadingState.Error,
    });
  });

export default reducer;

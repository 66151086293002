import * as React from "react";
import { connect } from "react-redux";
import { TimelinePageComponent } from "../../../api/odpApi";
import { RootState } from "../../../modules";
import TimelineComponent from "../../../components/dashboard/components/TimelineComponent";
import { DataboxComponentState, RegionFocusType } from "../../../modules/databoxComponents";
import TimelineProviderContainer from "./timeline/TimelineProvider";
import { TimelineProviderProps } from "./timeline";
import RegionTimelineProviderContainer from "./timeline/RegionTimelineProvider";
import PrefectureRegionTimelineProviderContainer from "./timeline/PrefectureRegionTimelineProvider";
import TimelineListContainer from "./timeline/TimelineListContainer";
import TimelineLoadingStateContainer from "./timeline/TimelineLoadingStateContainer";
import { LoadingState } from "../../../modules/models";
import TimelineEmptyStateContainer from "./timeline/TimelineEmptyStateContainer";
import TimelineHeaderContainer from "./timeline/TimelineHeaderContainer";

interface ExportProps {
  component: TimelinePageComponent;
}

interface StateProps {
  databoxes: Record<string, DataboxComponentState>;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface DispatchProps {}

type Props = ExportProps & StateProps & DispatchProps;

class TimelineComponentContainer extends React.Component<Props> {
  private getDataboxId = () => {
    const { component } = this.props;
    return component.config.databox;
  };

  private renderHeader = (timelineProps: TimelineProviderProps) => {
    const { component } = this.props;
    const { regionName, summaryValue, summaryValueType, summaryValueTerm } = timelineProps;
    return (
      <TimelineHeaderContainer
        component={component}
        databoxId={this.getDataboxId()}
        regionName={regionName}
        summaryValue={summaryValue}
        summaryValueType={summaryValueType}
        summaryValueTerm={summaryValueTerm}
      />
    );
  };

  private renderList = (timelineProps: TimelineProviderProps) => {
    const { component } = this.props;
    const { loadingState, posts, handleLoadMore, hasMore } = timelineProps;

    if ((loadingState === LoadingState.Loading && posts === undefined) || loadingState === LoadingState.Error) {
      return <TimelineLoadingStateContainer loadingState={loadingState} />;
    }
    if (posts === undefined) {
      return <TimelineLoadingStateContainer loadingState={LoadingState.Loading} />;
    }
    if (posts.length === 0) {
      return <TimelineEmptyStateContainer />;
    }

    return (
      <TimelineListContainer
        component={component}
        loadingState={loadingState}
        posts={posts}
        hasMore={hasMore}
        handleLoadMore={handleLoadMore}
      />
    );
  };

  private renderContents(component: TimelinePageComponent, databoxId: string, databox: DataboxComponentState) {
    if (databox?.focusedRegion) {
      if (databox.focusedRegion.type === RegionFocusType.Custom) {
        // 任意区域分割モード
        return (
          <RegionTimelineProviderContainer component={component} regionId={databox.focusedRegion.regionId}>
            {(props) => (
              <>
                {this.renderHeader(props)}
                {this.renderList(props)}
              </>
            )}
          </RegionTimelineProviderContainer>
        );
      }
      if (databox.focusedRegion.type === RegionFocusType.Prefecture) {
        // 市区町村区域分割
        return (
          <PrefectureRegionTimelineProviderContainer component={component} regionId={databox.focusedRegion.regionId}>
            {(props) => (
              <>
                {this.renderHeader(props)}
                {this.renderList(props)}
              </>
            )}
          </PrefectureRegionTimelineProviderContainer>
        );
      }
    }
    // 通常モード
    return (
      <TimelineProviderContainer component={component} databoxId={databoxId}>
        {(props) => (
          <>
            {this.renderHeader(props)}
            {this.renderList(props)}
          </>
        )}
      </TimelineProviderContainer>
    );
  }

  public render() {
    const { component, databoxes } = this.props;
    if (!component) {
      return undefined;
    }
    const databoxId = this.getDataboxId();
    const databox = databoxes[this.getDataboxId()];
    return (
      <TimelineComponent component={component}>{this.renderContents(component, databoxId, databox)}</TimelineComponent>
    );
  }
}

const mapStateToProps = (state: RootState): StateProps => ({
  databoxes: state.databoxComponents.databoxes,
});

const mapDispatchToProps = (/* dispatch: Dispatch */): DispatchProps => ({});

export default connect(mapStateToProps, mapDispatchToProps)(TimelineComponentContainer);

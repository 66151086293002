import * as React from "react";
import { WithStyles, withStyles } from "@material-ui/core";
import { withTranslation, WithTranslation } from "react-i18next";
import styles from "./TitleComponentStyles";
import WidgetGridItem from "./WidgetGridItem";
import useMediaQuery from "../useMediaQuery";
import { ReactComponent as PoweredLogoImage } from "../../../assets/powered.svg";
import { TitlePageComponent } from "../../../api/odpApi";
import Constants from "../../../constants";

interface Props extends WithStyles<typeof styles>, WithTranslation {
  component: TitlePageComponent;
  logoUrl: string;
}

const TitleComponent: React.FC<Props> = ({ classes, component, logoUrl }) => {
  const { config } = component;
  const mediaQuery = useMediaQuery();

  const isDescription = mediaQuery.isL.matches;
  const isNotSmall = !mediaQuery.isS.matches;

  return (
    <WidgetGridItem component={component}>
      <div className={classes.root}>
        <div className={classes.logoContainer}>
          <img className={classes.logo} src={logoUrl} alt={component.title} />
        </div>
        <div className={classes.titleContainer}>
          {config.upperSubTitle && <div className={classes.upperSubTitle}>{config.upperSubTitle}</div>}
          <h1 className={classes.title}>{component.title}</h1>
          {config.subTitle && <div className={classes.lowerSubTitle}>{config.subTitle}</div>}
        </div>
        {isNotSmall && (
          <div className={classes.descriptionContainer}>
            {isDescription && <div className={classes.description}>{component.config.description}</div>}
          </div>
        )}
        {isNotSmall && (
          <div className={classes.poweredLogoContainer}>
            <a href={`${Constants.snsServiceUrl}/`} target="_blank" rel="noopener noreferrer">
              <PoweredLogoImage />
            </a>
          </div>
        )}
      </div>
    </WidgetGridItem>
  );
};

export default withTranslation()(withStyles(styles)(TitleComponent));

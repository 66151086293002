import * as React from "react";
import { connect } from "react-redux";
import { MapPageComponent } from "../../../api/odpApi";
import MapContentsContainer from "./map/MapContentsContainer";
import MapComponent from "../../../components/dashboard/components/MapComponent";
import MapRegionProvider from "./map/MapRegionProvider";
import MapPrefectureRegionProvider from "./map/MapPrefectureRegionProvider";

interface ExportProps {
  component: MapPageComponent;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface StateProps {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface DispatchProps {}

type Props = ExportProps & StateProps & DispatchProps;

class MapComponentContainer extends React.Component<Props> {
  private regionMap = (regionDrawType: "static" | "relative") => {
    const { component } = this.props;
    return (
      <MapRegionProvider
        regionDrawType={regionDrawType}
        regionLowerColor={component.config.regionLowerColor}
        regionHigherColor={component.config.regionHigherColor}
        legendMaxTrashes={component.config.legendMaxTrashes}
        legendMaxParticipants={component.config.legendMaxParticipants}
      >
        {(props) => <MapContentsContainer component={component} regionProps={props} />}
      </MapRegionProvider>
    );
  };

  private prefectureRegionMap = () => {
    const { component } = this.props;
    return (
      <MapPrefectureRegionProvider
        regionLowerColor={component.config.regionLowerColor}
        regionHigherColor={component.config.regionHigherColor}
        usePopulationScale={component.config.usePopulationScale}
        legendMaxTrashes={component.config.legendMaxTrashes}
        legendMaxParticipants={component.config.legendMaxParticipants}
      >
        {(props) => <MapContentsContainer component={component} regionProps={props} />}
      </MapPrefectureRegionProvider>
    );
  };

  private plainMap = () => {
    const { component } = this.props;
    return <MapContentsContainer component={component} />;
  };

  private contents = () => {
    const { component } = this.props;
    const { regionDrawType } = component.config;
    if (regionDrawType === "static" || regionDrawType === "relative") {
      return this.regionMap(regionDrawType);
    }
    if (regionDrawType === "prefecture") {
      return this.prefectureRegionMap();
    }
    return this.plainMap();
  };

  public render(): JSX.Element {
    return <MapComponent>{this.contents()}</MapComponent>;
  }
}

const mapStateToProps = (/* state: RootState */): StateProps => ({});

const mapDispatchToProps = (/* dispatch: Dispatch */): DispatchProps => ({});

export default connect(mapStateToProps, mapDispatchToProps)(MapComponentContainer);

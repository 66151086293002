/* eslint-disable react/no-danger */
import * as React from "react";
import { WithStyles, withStyles } from "@material-ui/core";
import { withTranslation, WithTranslation } from "react-i18next";
import styles from "./HtmlComponentStyles";
import { HtmlPageComponent } from "../../../api/odpApi";
import WidgetGridItem from "./WidgetGridItem";
import ComponentContents from "./parts/ComponentContents";
import ComponentTitle from "./parts/ComponentTitle";

interface Props extends WithStyles<typeof styles>, WithTranslation {
  component: HtmlPageComponent;
}

const HtmlComponent: React.FC<Props> = ({ component, classes }) => (
  <WidgetGridItem component={component}>
    <div className={classes.root}>
      {component.config.useFrame && (
        <ComponentContents>
          <ComponentTitle
            accessoryLinkTitle={component.config.accessoryLinkTitle}
            accessoryLinkTarget={component.config.accessoryLinkTarget}
            accessoryTooltipText={component.config.accessoryTooltipText}
          >
            {component.title}
          </ComponentTitle>
          <div dangerouslySetInnerHTML={{ __html: component.config.html }} />
        </ComponentContents>
      )}
      {!component.config.useFrame && <div dangerouslySetInnerHTML={{ __html: component.config.html }} />}
    </div>
  </WidgetGridItem>
);

export default withTranslation()(withStyles(styles)(HtmlComponent));

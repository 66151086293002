import { all, call, put, takeEvery } from "redux-saga/effects";
import { Action } from "typescript-fsa";
import { SagaIterator } from "redux-saga";
import { loadCounter, LoadCounterParameters, loadCounterProgress } from "./index";
import Api, { ApiCounter, ApiResponse } from "../../api/baseApi";
import { checkPirikaWebApiResult } from "../saga-support";
import { PageDataboxType } from "../../api/odpApi";

function* loadCounterSaga(action: Action<LoadCounterParameters>) {
  const params = action.payload;
  const progressAction = loadCounterProgress;
  yield put(progressAction.started(params));

  let api: (organizationId: number, pageId: number, counterAddMetaId?: number) => Promise<ApiResponse | ApiCounter>;
  switch (params.databox.type) {
    case PageDataboxType.Hashtag:
      api = (organizationId: number, pageId: number, counterAddMetaId?: number) =>
        Api.getHashtagCounterAsync(organizationId, pageId, params.databox.hashtag ?? "", counterAddMetaId);
      break;
    case PageDataboxType.Region:
      api = Api.getRegionCounterAsync;
      break;
    default:
      api = Api.getCounterAsync;
      break;
  }

  const result = (yield call([Api, api], params.organizationId, params.pageId, params.counterAddMetaId)) as ApiCounter;
  yield all(
    checkPirikaWebApiResult(
      result,
      (payload: ApiCounter) => progressAction.done({ params, result: payload }),
      () => progressAction.failed({ params, error: new Error() }),
    ),
  );
}

export default function* watch(): SagaIterator {
  yield takeEvery(loadCounter.type, loadCounterSaga);
}

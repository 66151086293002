import { createStyles, StyleRules, Theme } from "@material-ui/core";

const styles = (theme: Theme): StyleRules =>
  createStyles({
    root: {
      height: "3rem",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    frame: {
      margin: "0 auto",
      width: "100%",
      textAlign: "center",
    },
    point: {
      width: "0.5rem",
      height: "0.5rem",
      margin: "0.16rem",
      backgroundColor: theme.palette.primary.main,
      borderRadius: "100%",
      display: "inline-block",
      animation: "$skBouncedelay 1500ms infinite ease-in-out both",
    },
    bounce1: {
      animationDelay: "-0.3s",
    },
    bounce2: {
      animationDelay: "-0.15s",
    },
    "@keyframes skBouncedelay": {
      "100%": {
        transform: "scale(0)",
      },
      "40%": {
        transform: "scale(1)",
      },
    },
  });
export default styles;

/* eslint-disable camelcase */
export const ApiResponseStatus = {
  Success: "success",
  Error: "error",
};
export type ApiResponseStatus = (typeof ApiResponseStatus)[keyof typeof ApiResponseStatus];

export interface ApiResponse {
  code: number;
  status: ApiResponseStatus;
  message?: string;
}

export type ApiCounter = ApiResponse & {
  participants: number;
  post: number;
  trash: number;
};

export interface ApiDataboxParameter {
  latlng?: string;
  zoomLevel?: number;
  afterId?: number;
  beforeId?: number;
  afterDate?: Date;
  beforeDate?: Date;
  count?: number;
}

export type ApiRawDataboxItem = {
  city: string;
  comment: string;
  count_comment: number;
  count_like: number;
  count_sum: number;
  country: string;
  date: string;
  id: number;
  img_url: string[];
  lat: number;
  lng: number;
  prefecture: string;
  pretty_date: string;
  profimg_url: string;
  thumb_url: string[];
  trash_sum: number;
  userid: number;
  username: string;
};

export type ApiRawDatabox = ApiResponse & {
  info: ApiRawDataboxItem[];
};

export interface ApiPost {
  city: string;
  comment: string;
  countComment: number;
  countLike: number;
  countSum: number;
  country: string;
  date: Date;
  id: number;
  imgUrl: string[];
  lat: number;
  lng: number;
  prefecture: string;
  prettyDate: string;
  profileImageUrl: string;
  thumbnailUrl: string[];
  trashSum: number;
  userId: number;
  userName: string;
}

export type ApiDatabox = ApiResponse & {
  info: ApiPost[];
};

export interface ApiActivityDataPoint {
  period: string;
  participants: number;
  post: number;
  trash: number;
}

export type ApiActivities = ApiResponse & {
  points: ApiActivityDataPoint[];
};

export interface ApiRankingPlace {
  id?: number;
  name: string;
  image: string;
}

export type ApiRanking = ApiResponse & {
  entities: ApiRankingPlace[];
};

export interface ApiUserMetadata {
  id: number;
  name: string;
}

export type ApiRawGovernmentNonIndividuals = ApiResponse & {
  companies: ApiUserMetadata[];
  associations: ApiUserMetadata[];
  governments: ApiUserMetadata[];
  organizations_public: ApiUserMetadata[];
  organizations_private: ApiUserMetadata[];
  schools: ApiUserMetadata[];
  others: ApiUserMetadata[];
};

export type ApiGovernmentNonIndividuals = ApiResponse & {
  companies: ApiUserMetadata[];
  associations: ApiUserMetadata[];
  governments: ApiUserMetadata[];
  publicOrganizations: ApiUserMetadata[];
  privateOrganizations: ApiUserMetadata[];
  schools: ApiUserMetadata[];
  others: ApiUserMetadata[];
};

export type ApiRawEvents = ApiResponse & {
  events: {
    creation_date: string;
    end_date: string;
    end_local_date: string;
    // noinspection SpellCheckingInspection
    eventid: number;
    is_future: boolean;
    lat: number;
    lng: number;
    place: string;
    start_date: string;
    start_local_date: string;
    thumb_url: string[];
    title: string;
    update_date: string;
    is_public: boolean;
  }[];
};

export interface ApiEvent {
  creationDate: string;
  endDate: Date;
  endLocalDate: string;
  eventId: number;
  isFuture: boolean;
  lat: number;
  lng: number;
  place: string;
  startDate: Date;
  startLocalDate: string;
  thumbnailUrl: string[];
  title: string;
  updateDate: string;
  isPublic: boolean;
}

export type ApiEvents = ApiResponse & {
  events: ApiEvent[];
};

export type ApiRawEventDetail = ApiResponse & {
  creation_date: string;
  end_date: string;
  event_id: number;
  lat: number;
  lng: number;
  place: string;
  start_date: string;
  thumb_url: string[];
  title: string;
  update_date: string;
  is_public: boolean;
  comment: string;
  // memberCount: number;
  // region: string;
  hashtags: string[];
  author_name: string;
  author_id: number;
  img_url: string[];
};

export type ApiEventDetail = ApiResponse & {
  creationDate: Date;
  endDate: Date;
  eventId: number;
  lat: number;
  lng: number;
  place: string;
  startDate: Date;
  thumbnailUrl: string[];
  title: string;
  updateDate: Date;
  isPublic: boolean;
  comment: string;
  // memberCount: number;
  // region: string;
  hashtags: string[];
  authorName: string;
  authorId: number;
  imageUrl: string[];
};

export interface ApiRevGeocode {
  pref: string;
  city: string;
  country: string;
}

export interface ApiRegionPolygon {
  polygonId: string;
  name: string;
  strokeColor: string;
  backgroundColor: string;
}

export interface ApiPopulationData {
  countryCode: string;
  prefectureCode: string;
  prefectureName: string;
  cityCode: string;
  cityName: string;
  population: number;
  year: number;
}

export type ApiRegion = ApiResponse & {
  available: boolean;
  geojsonUrl: string;
  topojsonUrl: string;
  polygons: ApiRegionPolygon[];
};

export type ApiPrefectureRegion = ApiResponse & {
  available: boolean;
  geojsonUrl: string;
  topojsonUrl: string;
  polygons: ApiRegionPolygon[];
  populations: ApiPopulationData[];
};

export interface ApiRegionPolygonCounters {
  polygonId: string;
  counters: ApiActivityDataPoint[];
}

export type ApiRegionActivities = ApiResponse & {
  counters: ApiRegionPolygonCounters[];
};

export interface ApiUserListEntry {
  id: number;
  name: string;
  image: string;
}

export type ApiUserList = ApiResponse & {
  entities: ApiUserListEntry[];
};

import actionCreatorFactory from "typescript-fsa";
import { reducerWithInitialState } from "typescript-fsa-reducers";

// models
// ----------------------------------------

// State
// ----------------------------------------

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface WindowState {}

export const EmptyWindowState = {};

const initialState: WindowState = EmptyWindowState;

// Parameters
// ----------------------------------------

export interface OpenNewWindowParameters {
  url: string;
}

// ActionCreators
// ----------------------------------------

const actionCreator = actionCreatorFactory("PirikaOdp/Window");

export const openNewWindow = actionCreator<OpenNewWindowParameters>("OpenNewWindow");

// Reducer
// ----------------------------------------

const reducer = reducerWithInitialState(initialState);

export default reducer;

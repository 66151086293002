import * as React from "react";
import { connect } from "react-redux";
import TimelineLoadingState from "../../../../components/dashboard/components/timeline/TimelineLoadingState";
import { LoadingState } from "../../../../modules/models";

interface ExportProps {
  loadingState: LoadingState;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface StateProps {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface DispatchProps {}

type Props = ExportProps & StateProps & DispatchProps;

class TimelineLoadingStateContainer extends React.PureComponent<Props> {
  public render(): JSX.Element {
    const { loadingState } = this.props;
    return <TimelineLoadingState loadingState={loadingState} />;
  }
}

const mapStateToProps = (/* state: RootState */): StateProps => ({});

const mapDispatchToProps = (/* dispatch: Dispatch */): DispatchProps => ({});

export default connect(mapStateToProps, mapDispatchToProps)(TimelineLoadingStateContainer);

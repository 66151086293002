export interface GeoPoint {
  latitude: number;
  longitude: number;
}

export interface GeoBounds {
  northEast: GeoPoint;
  southWest: GeoPoint;
}

export const isEqualGeoPoint = (point1: GeoPoint, point2: GeoPoint): boolean =>
  point1.latitude === point2.latitude && point1.longitude === point2.longitude;

export const isEqualGeoBounds = (bounds1: GeoBounds, bounds2: GeoBounds): boolean =>
  isEqualGeoPoint(bounds1.southWest, bounds2.southWest) && isEqualGeoPoint(bounds1.northEast, bounds2.northEast);

export const locationStringToGeoPoint = (location: string): GeoPoint | undefined => {
  const [latStr, lngStr] = location.split(",");
  const latitude = parseFloat(latStr);
  const longitude = parseFloat(lngStr);
  return !(Number.isNaN(latitude) || Number.isNaN(longitude)) ? { latitude, longitude } : undefined;
};

import { createStyles, StyleRules, Theme } from "@material-ui/core";

const breakpointNum = 600;

const styles = (theme: Theme): StyleRules =>
  createStyles({
    root: {},
    counterOuterContainer: {
      [theme.breakpoints.down(breakpointNum)]: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
    },
    counterContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      padding: theme.spacing(1),
      [theme.breakpoints.down(breakpointNum)]: {
        flexDirection: "column",
        alignItems: "flex-end",
      },
    },
    counter: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      marginLeft: theme.spacing(4),
      "&:first-child": {
        marginLeft: 0,
      },
      [theme.breakpoints.down(breakpointNum)]: {
        marginLeft: 0,
        marginTop: theme.spacing(1),
        "&:first-child": {
          marginTop: 0,
        },
      },
    },
    counterTitle: {
      fontSize: "min(2.5vw, 16px)",
      marginRight: theme.spacing(1),
    },
    counterDigitContainer: {
      whiteSpace: "nowrap",
    },
    counterDigit: {
      display: "inline-block",
      width: "24px",
      marginLeft: "2px",
      "& svg": {
        verticalAlign: "middle",
      },
      [theme.breakpoints.down(960)]: {
        width: "20px",
      },
    },
  });
export default styles;

import * as React from "react";
import { connect } from "react-redux";
import { FormikProps } from "formik";
import EventRegisterModalLocationInput from "../../../components/dashboard/eventRegister/EventRegisterModalLocationInput";
import { RootState } from "../../../modules";
import { PageConfiguration } from "../../../api/odpApi";
import LocationInputContainer from "./parts/LocationInputContainer";
import { EventRegisterApplication } from "../../../modules/eventRegisterModal";

interface ExportProps {
  // eslint-disable-next-line react/no-unused-prop-types
  initialLat?: number;
  // eslint-disable-next-line react/no-unused-prop-types
  initialLng?: number;
  // eslint-disable-next-line react/no-unused-prop-types
  initialPlace?: string;
  formikProps: FormikProps<EventRegisterApplication>;
}

interface StateProps {
  pageConfiguration?: PageConfiguration;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface DispatchProps {}

type Props = ExportProps & StateProps & DispatchProps;

class EventRegisterModalLocationInputContainer extends React.PureComponent<Props> {
  public render(): JSX.Element {
    const { pageConfiguration, formikProps } = this.props;
    const place = (pageConfiguration?.eventPrefecture ?? "") + (pageConfiguration?.eventCity ?? "");
    return (
      <LocationInputContainer
        initialPlace={place}
        onPlaceUpdated={(lat, lng, newPlace) => {
          const { setFieldValue } = formikProps;
          setFieldValue("lat", lat);
          setFieldValue("lng", lng);
          setFieldValue("place", newPlace);
        }}
      >
        <EventRegisterModalLocationInput place={formikProps.values.place} targetPlace={place} />
      </LocationInputContainer>
    );
  }
}

const mapStateToProps = (state: RootState): StateProps => ({
  pageConfiguration: state.openDataPage.pageConfigurations?.config,
});

const mapDispatchToProps = (/* dispatch: Dispatch */): DispatchProps => ({});

export default connect(mapStateToProps, mapDispatchToProps)(EventRegisterModalLocationInputContainer);

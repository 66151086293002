import { all, call, put, takeEvery } from "redux-saga/effects";
import { Action } from "typescript-fsa";
import { SagaIterator } from "redux-saga";
import {
  loadCounterGraphActivities,
  LoadCounterGraphActivitiesParameters,
  loadCounterGraphActivitiesProgress,
} from "./index";
import Api, { ApiActivities, ApiActivityDataPoint, ApiRegionActivities, ApiResponse } from "../../api/baseApi";
import { checkPirikaWebApiResult } from "../saga-support";
import { ApiResponseStatus, PageDataboxType } from "../../api/odpApi";

function* loadCounterGraphActivitiesSaga(action: Action<LoadCounterGraphActivitiesParameters>) {
  const params = action.payload;
  const progressAction = loadCounterGraphActivitiesProgress;
  yield put(progressAction.started(params));

  let api: () => Promise<ApiResponse | ApiActivities>;
  switch (params.databox.type) {
    case PageDataboxType.Hashtag:
      api = () =>
        Api.getHashtagActivitiesAsync(
          params.organizationId,
          params.pageId,
          params.databox.hashtag ?? "",
          params.counterAddMetaId,
        );
      break;
    case PageDataboxType.Region:
      api = async () => {
        const regionResult = await Api.getRegionActivitiesAsync(
          params.organizationId,
          params.pageId,
          params.counterAddMetaId,
        );
        if (regionResult.status !== ApiResponseStatus.Success) {
          return regionResult as ApiResponse;
        }
        const dataPoints: Record<string, ApiActivityDataPoint> = {};
        (regionResult as ApiRegionActivities).counters.forEach((rCounter) =>
          rCounter.counters.forEach((counter) => {
            if (!dataPoints[counter.period]) {
              dataPoints[counter.period] = {
                period: counter.period,
                trash: 0,
                participants: 0,
                post: 0,
              };
            }
            dataPoints[counter.period].trash += counter.trash;
            dataPoints[counter.period].participants += counter.participants;
            dataPoints[counter.period].post += counter.post;
          }),
        );
        return {
          code: regionResult.code,
          status: regionResult.status,
          message: regionResult.message,
          points: Object.values(dataPoints),
        } as ApiActivities;
      };
      break;
    default:
      api = () => Api.getActivitiesAsync(params.organizationId, params.pageId, params.counterAddMetaId);
  }

  const result = (yield call(api)) as ApiActivities;
  yield all(
    checkPirikaWebApiResult(
      result,
      (payload: ApiActivities) => progressAction.done({ params, result: payload }),
      () => progressAction.failed({ params, error: new Error() }),
    ),
  );
}

export default function* watch(): SagaIterator {
  yield takeEvery(loadCounterGraphActivities.type, loadCounterGraphActivitiesSaga);
}

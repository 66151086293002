import * as React from "react";
import { connect } from "react-redux";
import { TitlePageComponent } from "../../../api/odpApi";
import { RootState } from "../../../modules";
import TitleComponent from "../../../components/dashboard/components/TitleComponent";
import { getUploadedFileUrl } from "../../../modules/openDataPage";

interface ExportProps {
  component: TitlePageComponent;
}

interface StateProps {
  organizationId: number;
  openDataPageId: number;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface DispatchProps {}

type Props = ExportProps & StateProps & DispatchProps;

class TitleComponentContainer extends React.PureComponent<Props> {
  public render(): JSX.Element {
    const { component, organizationId, openDataPageId } = this.props;
    if (!component) {
      // eslint-disable-next-line react/jsx-no-useless-fragment
      return <></>;
    }
    const logoUrl = component.config.logoImage
      ? getUploadedFileUrl(organizationId, openDataPageId, component.config.logoImage)
      : "";
    return <TitleComponent component={component} logoUrl={logoUrl} />;
  }
}

const mapStateToProps = (state: RootState): StateProps => ({
  organizationId: state.openDataPage.pageConfigurations?.config.organizationId ?? 0,
  openDataPageId: state.openDataPage.pageConfigurations?.config.openDataPageId ?? 0,
});

const mapDispatchToProps = (/* dispatch: Dispatch */): DispatchProps => ({});

export default connect(mapStateToProps, mapDispatchToProps)(TitleComponentContainer);

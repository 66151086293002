import { GeoPoint } from "../modules/geolocation";
import ExternalApi from "../api/externalApi";
import BaseApi from "../api/baseApi";

export const revGeocode = async (lat: number, lng: number): Promise<string> => {
  const response = await BaseApi.getRevGeocodeAsync(lat, lng);
  if (!response) {
    throw Error("No result or Api Error.");
  }
  try {
    if (!response.pref || !response.city) {
      return "";
    }
    return response.pref + response.city;
  } catch {
    throw Error("Cannot parse response.");
  }
};

export const geocode = async (place?: string): Promise<GeoPoint> => {
  const response = await ExternalApi.getGeocodeAsync(place);
  if (!response) {
    throw Error("No result or Api Error.");
  }
  try {
    return {
      latitude: response.results[0].geometry.location.lat,
      longitude: response.results[0].geometry.location.lng,
    };
  } catch {
    throw Error("Cannot parse response.");
  }
};

import * as React from "react";
import { connect } from "react-redux";
import { FormikProps } from "formik";
import EventRegisterModalRecaptcha from "../../../components/dashboard/eventRegister/EventRegisterModalRecaptcha";
import { RootState } from "../../../modules";
import { EventRegisterApplication } from "../../../modules/eventRegisterModal";

interface ExportProps {
  formikProps: FormikProps<EventRegisterApplication>;
}

interface StateProps {
  recaptchaKey?: string;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface DispatchProps {}

type Props = ExportProps & StateProps & DispatchProps;

class EventRegisterModalRecaptchaContainer extends React.Component<Props> {
  private setRecaptchaVerified = (isVerified: boolean) => {
    const { formikProps } = this.props;
    formikProps.setFieldValue("recaptchaVerified", isVerified);
  };

  public render(): JSX.Element {
    const { recaptchaKey } = this.props;
    return (
      <EventRegisterModalRecaptcha
        recaptchaKey={recaptchaKey || "-"}
        onVerify={() => this.setRecaptchaVerified(true)}
        onExpired={() => this.setRecaptchaVerified(false)}
      />
    );
  }
}

const mapStateToProps = (state: RootState): StateProps => ({
  recaptchaKey: state.openDataPage.pageConfigurations?.config.recaptchaKey,
});

const mapDispatchToProps = (/* dispatch: Dispatch */): DispatchProps => ({});

export default connect(mapStateToProps, mapDispatchToProps)(EventRegisterModalRecaptchaContainer);

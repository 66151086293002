import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import EventDialog from "../../../components/dashboard/events/EventDialog";
import { RootState } from "../../../modules";
import { closeEventDialog as closeEventDialogAction, EventDetail } from "../../../modules/events";
import { LoadingState } from "../../../modules/models";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ExportProps {}

interface StateProps {
  open: boolean;
  loadingState: LoadingState;
  eventDetail?: EventDetail;
}

interface DispatchProps {
  closeEventDialog: () => void;
}

type Props = ExportProps & StateProps & DispatchProps;

class EventDialogContainer extends React.Component<Props> {
  private handleOnClose = () => {
    const { closeEventDialog } = this.props;
    closeEventDialog();
  };

  public render(): JSX.Element {
    const { open, loadingState, eventDetail } = this.props;
    return (
      <EventDialog open={open} loadingState={loadingState} eventDetail={eventDetail} onClose={this.handleOnClose} />
    );
  }
}

const mapStateToProps = (state: RootState): StateProps => ({
  open: Boolean(state.events.dialogOpenEvent),
  loadingState: state.events.dialogEventDetailLoadingState,
  eventDetail: state.events.dialogEventDetail,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  closeEventDialog: () => dispatch(closeEventDialogAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(EventDialogContainer);

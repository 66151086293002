import { createStyles, StyleRules, Theme } from "@material-ui/core";

const styles = (theme: Theme): StyleRules =>
  createStyles({
    root: {
      minHeight: "80px",
    },
    imageContainer: {
      position: "relative",
    },
    image: {
      width: "100%",
      minHeight: "80px",
    },
    buttons: {
      position: "absolute",
      bottom: theme.spacing(1),
      right: theme.spacing(1),
    },
    placeholder: {
      borderColor: theme.palette.grey["300"],
      borderWidth: "2px",
      borderStyle: "dashed",
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.grey["700"],
    },
    placeholderCaption: {
      marginTop: theme.spacing(1),
      marginBottom: 0,
    },
  });
export default styles;

import * as React from "react";
import { WithStyles, withStyles } from "@material-ui/core";
import { withTranslation, WithTranslation } from "react-i18next";
import { useEffect, useRef } from "react";
import styles from "./EventRegisterModalRecaptchaStyles";

interface Props extends WithStyles<typeof styles>, WithTranslation {
  recaptchaKey: string;
  onVerify: () => void;
  onExpired: () => void;
}

const EventRegisterModalRecaptcha: React.FC<Props> = ({ classes, recaptchaKey, onVerify, onExpired }) => {
  const rootRef = useRef<HTMLDivElement>(null);
  const divRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const root = rootRef.current;
    const container = divRef.current;
    if (!root || !container) {
      return;
    }
    const script = document.createElement("script");
    script.type = "application/javascript";
    script.src = "https://www.google.com/recaptcha/enterprise.js";
    root.appendChild(script);
    script.onload = () => {
      grecaptcha.enterprise.ready(() => {
        grecaptcha.enterprise.render(container, {
          sitekey: recaptchaKey,
          callback: onVerify,
          "expired-callback": onExpired,
        });
      });
    };
  }, [rootRef, divRef]);

  return (
    <div ref={rootRef} className={classes.root}>
      <div ref={divRef} className={classes.container} />
    </div>
  );
};

export default withTranslation()(withStyles(styles)(EventRegisterModalRecaptcha));

import * as React from "react";
import { WithStyles, withStyles } from "@material-ui/core";
import { withTranslation, WithTranslation } from "react-i18next";
import { format } from "date-fns";
import styles from "./EventItemViewStyles";
import Constants from "../../../../constants";
import { GroupEvent } from "../../../../modules/groupEventsComponents";
import { AreaEvent } from "../../../../modules/areaEventsComponents";
import MapImage from "../../../../assets/map.png";
import PlaceholderImage from "../../../../assets/placeholder.png";

interface Props extends WithStyles<typeof styles>, WithTranslation {
  event: AreaEvent | GroupEvent;
  onOpen: () => void;
}

const EventItemView: React.FC<Props> = ({ event, classes, onOpen }) => (
  <div className={classes.root}>
    <a
      key={event.eventId}
      className={classes.itemLink}
      href={event.isPublic ? `${Constants.snsServiceUrl}/ev/${event.eventId}` : "#"}
      target="_blank"
      rel="noopener noreferrer"
      onClick={(e) => {
        e.preventDefault();
        onOpen();
        return false;
      }}
    >
      <div className={classes.item}>
        <div className={classes.itemAbstractContainer}>
          <div className={classes.itemDate}>{format(event.startDate, "yyyy/MM/dd")}</div>
          <div className={classes.itemTitle}>{event.title}</div>
          <div className={classes.itemPlace}>
            <img className={classes.itemPlaceIcon} src={MapImage} alt="場所" />
            {event.place}
          </div>
        </div>
        <div className={classes.itemImageContainer}>
          {event.thumbnailUrl.length > 0 && (
            <img className={classes.itemImage} src={event.thumbnailUrl[0]} alt={event.title} />
          )}
          {event.thumbnailUrl.length === 0 && (
            <img className={classes.itemImage} src={PlaceholderImage} alt={event.title} />
          )}
        </div>
      </div>
    </a>
  </div>
);

export default withTranslation()(withStyles(styles)(EventItemView));

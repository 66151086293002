import * as React from "react";
import { ClickAwayListener, IconButton, Tooltip, WithStyles, withStyles } from "@material-ui/core";
import { withTranslation, WithTranslation } from "react-i18next";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import styles from "./ComponentTitleStyles";
import Nl2Br from "./Nl2Br";

interface Props extends WithStyles<typeof styles>, WithTranslation {
  children?: React.ReactNode;
  accessoryTooltipText: string;
  accessoryLinkTarget: string;
  accessoryLinkTitle: string;
}

const AccessoryTooltip = withStyles((theme) => ({
  tooltip: {
    boxShadow: theme.shadows[1],
    backgroundColor: "white",
    color: "#3f3f3f",
    fontSize: "14px",
    fontWeight: "normal",
    padding: theme.spacing(1),
  },
}))(Tooltip);

const ComponentTitle: React.FC<Props> = ({
  children,
  accessoryLinkTarget,
  accessoryLinkTitle,
  accessoryTooltipText,
  classes,
}) => {
  const [open, setOpen] = React.useState(false);
  const handleTooltipOpen = () => setOpen(true);
  const handleTooltipClose = () => setOpen(false);
  return (
    <div className={classes.root}>
      <h3 className={classes.title}>{children}</h3>
      {accessoryLinkTitle && accessoryLinkTarget && (
        <div className={classes.accessoryLinkContainer}>
          <a className={classes.accessoryLink} href={accessoryLinkTarget} target="_blank" rel="noopener noreferrer">
            {accessoryLinkTitle}
          </a>
        </div>
      )}
      {accessoryTooltipText && (
        <div className={classes.accessoryTooltipContainer}>
          <ClickAwayListener onClickAway={handleTooltipClose}>
            <AccessoryTooltip
              PopperProps={{
                disablePortal: true,
              }}
              onClose={handleTooltipClose}
              open={open}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              placement="top"
              title={<Nl2Br text={accessoryTooltipText} />}
            >
              <div className={classes.accessoryButtonContainer}>
                <IconButton className={classes.accessoryTooltipButton} size="small" onClick={handleTooltipOpen}>
                  <HelpOutlineIcon />
                </IconButton>
              </div>
            </AccessoryTooltip>
          </ClickAwayListener>
        </div>
      )}
    </div>
  );
};

export default withTranslation()(withStyles(styles)(ComponentTitle));

// eslint-disable-next-line import/prefer-default-export
export const getContrastedColor = (hexColor: string): string => {
  let workHexColor = hexColor;
  if (!/^#?([0-9a-f]{3,6})$/i.exec(workHexColor)) {
    return "black";
  }
  if (workHexColor.slice(0, 1) === "#") {
    workHexColor = workHexColor.slice(1);
  }
  if (workHexColor.length === 3) {
    workHexColor = workHexColor
      .split("")
      .map((hex) => hex + hex)
      .join("");
  }
  const r = parseInt(workHexColor.substr(0, 2), 16);
  const g = parseInt(workHexColor.substr(2, 2), 16);
  const b = parseInt(workHexColor.substr(4, 2), 16);
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 125 ? "black" : "white";
};

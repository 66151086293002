import * as React from "react";
import { Grid, GridSize, WithStyles, withStyles } from "@material-ui/core";
import { withTranslation, WithTranslation } from "react-i18next";
import styles from "./UserListComponentStyles";
import { UserListPageComponent } from "../../../api/odpApi";
import { LoadingState } from "../../../modules/models";
import { UserListEntry } from "../../../modules/userListComponents";
import WidgetGridItem from "./WidgetGridItem";
import ComponentFrame from "./parts/ComponentFrame";
import ComponentContents from "./parts/ComponentContents";
import ComponentTitle from "./parts/ComponentTitle";
import ComponentLoadingStateView from "./parts/ComponentLoadingStateView";
import ComponentErrorStateView from "./parts/ComponentErrorStateView";
import UserEntry from "./userList/UserEntry";

interface Props extends WithStyles<typeof styles>, WithTranslation {
  component: UserListPageComponent;
  loadingState: LoadingState;
  users: UserListEntry[];
}

/**
 * コンポーネント設定から列数を判定
 * @param component
 */
const calculateGridSizeConfig = (component: UserListPageComponent) => {
  const componentSizeColumns = (() => {
    switch (component.size) {
      case "S":
        return 1;
      case "M":
        return 2;
      case "L":
      default:
        return 3;
    }
  })();
  const columns = Math.min(componentSizeColumns, component.config.columns);
  return {
    xs: 12 as GridSize,
    sm: (columns >= 2 ? 6 : 12) as GridSize,
    md: ((): GridSize => {
      if (columns >= 3) {
        return 4;
      }
      if (columns === 2) {
        return 6;
      }
      return 12;
    })(),
  };
};

const UserListComponent: React.FC<Props> = ({ component, loadingState, users, classes }) => {
  const gridSizeConfig = calculateGridSizeConfig(component);
  return (
    <WidgetGridItem component={component}>
      <ComponentFrame className={classes.root}>
        {loadingState === LoadingState.Initial && (
          <ComponentContents>
            <ComponentTitle
              accessoryLinkTitle={component.config.accessoryLinkTitle}
              accessoryLinkTarget={component.config.accessoryLinkTarget}
              accessoryTooltipText={component.config.accessoryTooltipText}
            >
              {component.title}
            </ComponentTitle>
            {users.length > 0 && (
              <Grid container>
                {users.map((user) => (
                  <UserEntry
                    key={user.id}
                    xs={gridSizeConfig.xs}
                    sm={gridSizeConfig.sm}
                    md={gridSizeConfig.md}
                    user={user}
                    useIcon={component.config.listType === "icon"}
                  />
                ))}
              </Grid>
            )}
            {users.length === 0 && <div className={classes.emptyState}>内容がありません</div>}
          </ComponentContents>
        )}
        {loadingState === LoadingState.Loading && <ComponentLoadingStateView />}
        {loadingState === LoadingState.Error && <ComponentErrorStateView />}
      </ComponentFrame>
    </WidgetGridItem>
  );
};

export default withTranslation()(withStyles(styles)(UserListComponent));

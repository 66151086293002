import React from "react";
import Constants from "../../../../constants";

/**
 * sample params
 * {
 *   center: 'Brooklyn Bridge,New York,NY',
 *   zoom: '13',
 *   size: '600x300',
 *   maptype: 'roadmap',
 *   markers: [
 *     'color:blue|label:S|40.702147,-74.015794',
 *     'color:green|label:G|40.711614,-74.012318',
 *     'color:red|label:C|40.718217,-73.998284',
 *   ],
 *   key: 'xxxx',
 * }
 */

type MapParams = {
  size: string;
  // eslint-disable-next-line react/no-unused-prop-types
  zoom?: number;
  // eslint-disable-next-line react/no-unused-prop-types
  maptype?: "roadmap" | "satellite" | "hybrid" | "terrain";
  // eslint-disable-next-line react/no-unused-prop-types
  language?: string;
  // eslint-disable-next-line react/no-unused-prop-types
  region?: string;
};

type MarkerAttrs = {
  location: [number, number];
  size?: "tiny" | "mid" | "small";
  color?: "black | brown | green | purple | yellow | blue | gray | orange | red | white";
  scale?: 1 | 2 | 4;
};

type MapFeatureParams = {
  // eslint-disable-next-line react/no-unused-prop-types
  center?: string;
  markers: MarkerAttrs[];
};

type StaticMapPros = MapParams & MapFeatureParams;

const formatMarkers = (target: MarkerAttrs[]) =>
  target.map((m) =>
    Object.entries(m)
      .map(([k, v]) => {
        if (v === undefined) {
          return "";
        }
        return k === "location" ? `${v[0] as string},${v[1] as string}` : `${k}|${v as string}`;
      })
      .join("|"),
  );

const StaticMap: React.FC<StaticMapPros> = ({ size, markers }) => {
  const params = new URLSearchParams([
    ["size", `${size}`],
    ["zoom", "12"],
    ["key", Constants.googleApiKey],
    ...formatMarkers(markers).map((m) => ["markers", m]),
  ]).toString();
  const src = `//maps.googleapis.com/maps/api/staticmap?${params}`;
  const [lat, lng] = markers[0].location;
  return (
    <a href={`https://www.google.com/maps/search/?api=1&query=${lat},${lng}`} target="_blank" rel="noopener noreferrer">
      <img src={src} alt="map" />
    </a>
  );
};

export default StaticMap;

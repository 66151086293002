import * as React from "react";

interface Props {
  text: string;
}

const Nl2Br: React.FC<Props> = ({ text }) => (
  <>
    {text.split("\n").map((item, key) => (
      // eslint-disable-next-line react/no-array-index-key
      <React.Fragment key={key}>
        {item}
        <br />
      </React.Fragment>
    ))}
  </>
);

export default Nl2Br;

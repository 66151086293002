import { all, call, put, takeEvery } from "redux-saga/effects";
import { Action } from "typescript-fsa";
import { SagaIterator } from "redux-saga";
import { loadAreaEvents, LoadAreaEventsParameters, loadAreaEventsProgress } from "./index";
import Api, { ApiEvents } from "../../api/baseApi";
import { checkPirikaWebApiResult } from "../saga-support";

function* loadAreaEventsSaga(action: Action<LoadAreaEventsParameters>) {
  const params = action.payload;
  const progressAction = loadAreaEventsProgress;
  yield put(progressAction.started(params));

  const result = (yield call(
    [Api, Api.getGovernmentEventsAsync],
    params.organizationId,
    params.pageId,
    undefined,
    params.cursorId,
    params.all,
  )) as ApiEvents;
  yield all(
    checkPirikaWebApiResult(
      result,
      (payload: ApiEvents) => progressAction.done({ params, result: payload }),
      () => progressAction.failed({ params, error: new Error() }),
    ),
  );
}

export default function* watch(): SagaIterator {
  yield takeEvery(loadAreaEvents.type, loadAreaEventsSaga);
}

import * as React from "react";
import { WithStyles, withStyles } from "@material-ui/core";
import { withTranslation, WithTranslation } from "react-i18next";
// eslint-disable-next-line import/no-unresolved
import { GoogleChartWrapper } from "react-google-charts/dist/types";
import { useEffect, useRef } from "react";
import Chart from "react-google-charts";
import styles from "./StackedBarRegionGraphStyles";
import { Region } from "../../../../modules/regionComponents";
import { periodToNumberYearMonth } from "../../../../helpers/date";

interface Props extends WithStyles<typeof styles>, WithTranslation {
  region: Region;
  periods: string[];
  values: Record<string, Record<string, number>>;
}

const generateDataTable = (
  region: Region,
  periods: string[],
  values: Record<string, Record<string, number>>,
): (string | number)[][] => [
  ["Region"].concat(region.polygons.map((polygon) => polygon.name)),
  ...periods.map((period) => {
    const [y, m] = periodToNumberYearMonth(period);
    return [
      `${y}年${m}月`,
      ...region.polygons.map((polygon) =>
        values[polygon.polygonId] !== undefined ? values[polygon.polygonId][period] : 0,
      ),
    ];
  }),
];

const StackedBarRegionGraph: React.FC<Props> = ({ region, periods, values, classes }) => {
  // リサイズのための対応
  let chartWrapper: GoogleChartWrapper | undefined;
  const containerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      chartWrapper?.setOption("height", containerRef.current?.clientHeight);
      chartWrapper?.draw();
    });
    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }
    return (): void => resizeObserver.disconnect();
  }, []);
  return (
    <div className={classes.root} ref={containerRef}>
      <Chart
        chartType="ColumnChart"
        getChartWrapper={(newChartWrapper) => {
          chartWrapper = newChartWrapper;
        }}
        options={{
          title: "",
          isStacked: true,
          legend: {
            position: "bottom",
          },
          colors: region.polygons.map((v) => v.backgroundColor),
          /*
          hAxis: {
            title: "月",
            titleTextStyle: { color: "#333" },
            format: "yyyy/MM",
          },
          vAxes: {
            0: { minValue: 0, title: "人" },
            1: { minValue: 0, title: "個" },
          },
          series: {
            0: { type: "line", targetAxisIndex: 0 },
            1: { type: "line", targetAxisIndex: 1 },
          },
           */
        }}
        data={generateDataTable(region, periods, values)}
      />
    </div>
  );
};

export default withTranslation()(withStyles(styles)(StackedBarRegionGraph));

import * as React from "react";
import { WithStyles, withStyles } from "@material-ui/core";
import { withTranslation, WithTranslation } from "react-i18next";
import styles from "./TimelineLoadingStateStyles";
import ComponentLoadingStateView from "../parts/ComponentLoadingStateView";
import { LoadingState } from "../../../../modules/models";
import ComponentErrorStateView from "../parts/ComponentErrorStateView";

interface Props extends WithStyles<typeof styles>, WithTranslation {
  loadingState: LoadingState;
}

const TimelineLoadingState: React.FC<Props> = ({ loadingState, classes }) => (
  <div className={classes.root}>
    {loadingState === LoadingState.Loading && <ComponentLoadingStateView />}
    {loadingState === LoadingState.Error && <ComponentErrorStateView />}
  </div>
);

export default withTranslation()(withStyles(styles)(TimelineLoadingState));

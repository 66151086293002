import * as React from "react";
import { Button, WithStyles, withStyles } from "@material-ui/core";
import { withTranslation, WithTranslation } from "react-i18next";
import classNames from "classnames";
import styles from "./UsageComponentStyles";
import WidgetGridItem from "./WidgetGridItem";
import { UsagePageComponent } from "../../../api/odpApi";
import ComponentContents from "./parts/ComponentContents";
import ComponentTitle from "./parts/ComponentTitle";
import SmartphoneImage from "../../../assets/icon_smartphone.png";
import AppStoreImage from "../../../assets/icon_appstore.png";
import GooglePlayImage from "../../../assets/icon_google_play.png";
import ComputerImage from "../../../assets/icon_pc.png";
import ComponentFrame from "./parts/ComponentFrame";
import { ReactComponent as UsageImage } from "../../../assets/usage.svg";
import Constants from "../../../constants";

interface Props extends WithStyles<typeof styles>, WithTranslation {
  component: UsagePageComponent;
}

const UsageComponent: React.FC<Props> = ({ component, classes }) => (
  <WidgetGridItem component={component}>
    <ComponentFrame className={classes.root}>
      <ComponentContents>
        <div className={classes.contentsFrame}>
          <div className={classes.description}>
            <ComponentTitle
              accessoryLinkTitle={component.config.accessoryLinkTitle}
              accessoryLinkTarget={component.config.accessoryLinkTarget}
              accessoryTooltipText={component.config.accessoryTooltipText}
            >
              {component.title}
            </ComponentTitle>
            <p className={classes.descriptionText}>
              ごみを拾って写真を撮るだけ、ピリカを使えば
              <br />
              誰でもみんなで気軽にごみ拾い活動に参加できます！
            </p>
            <div className={classes.descriptionImageContainer}>
              <UsageImage className={classes.descriptionImage} />
            </div>
          </div>
          <div className={classes.links}>
            <div className={classes.linkFrame}>
              <div className={classes.linkDescription}>
                <span className={classes.linkDescriptionText}>スマートフォンからの利用</span>
                <span className={classes.linkDescriptionImage}>
                  <img src={SmartphoneImage} alt="スマートフォンからの利用" />
                </span>
              </div>
              <div className={classes.linkButtons}>
                <span className={classes.storeIconContainer}>
                  <a href={`${Constants.snsServiceUrl}/download?target=1`} target="_blank" rel="noopener noreferrer">
                    <img className={classes.storeIconImage} src={AppStoreImage} alt="AppStore" />
                  </a>
                </span>
                <span className={classes.storeIconContainer}>
                  <a href={`${Constants.snsServiceUrl}/download?target=2`} target="_blank" rel="noopener noreferrer">
                    <img className={classes.storeIconImage} src={GooglePlayImage} alt="Google Play" />
                  </a>
                </span>
              </div>
            </div>
            <div className={classes.linkFrame}>
              <div className={classes.linkDescription}>
                <span className={classes.linkDescriptionText}>パソコンからの利用</span>
                <span className={classes.linkDescriptionImage}>
                  <img src={ComputerImage} alt="パソコンからの利用" />
                </span>
              </div>
              <div className={classes.linkButtons}>
                <Button
                  className={classNames(classes.loginButton)}
                  variant="contained"
                  onClick={() => {
                    window.open("https://www.pirika.org/", "_blank", "noopener,noreferrer");
                  }}
                >
                  Web版はこちら
                </Button>
              </div>
            </div>
          </div>
        </div>
      </ComponentContents>
    </ComponentFrame>
  </WidgetGridItem>
);

export default withTranslation()(withStyles(styles)(UsageComponent));

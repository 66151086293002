import * as React from "react";
import { connect } from "react-redux";
import { Route, Switch } from "react-router";
import { RootState } from "../modules";
import DashboardPageContainer from "./dashboard/DashboardPageContainer";
import NotFoundPageContainer from "./pages/NotFoundPageContainer";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ExportProps {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface StateProps {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface DispatchProps {}

type Props = ExportProps & StateProps & DispatchProps;

class Router extends React.PureComponent<Props> {
  public render(): JSX.Element {
    return (
      <Switch>
        <Route exact path="/" component={DashboardPageContainer} />
        <Route exact path="/events/register" component={DashboardPageContainer} />
        <Route component={NotFoundPageContainer} />
      </Switch>
    );
  }
}

const mapStateToProps = (state: RootState): StateProps => ({
  location: state.router.location,
});

const mapDispatchToProps = (/* dispatch: Dispatch */): DispatchProps => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Router);

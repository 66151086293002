import * as React from "react";
import { WithStyles, withStyles } from "@material-ui/core";
import { withTranslation, WithTranslation } from "react-i18next";
import styles from "./FooterComponentStyles";
import WidgetGridItem from "./WidgetGridItem";
import { FooterPageComponent } from "../../../api/odpApi";
import { ReactComponent as WithPirikaImage } from "../../../assets/with_pirika.svg";
import Constants from "../../../constants";

interface Props extends WithStyles<typeof styles>, WithTranslation {
  component: FooterPageComponent;
  pageTitle: string;
  logoUrl: string;
  hasExternalData: boolean;
  onAcknowledgementsClick: () => void;
}

const FooterComponent: React.FC<Props> = ({
  component,
  pageTitle,
  logoUrl,
  hasExternalData,
  onAcknowledgementsClick,
  classes,
}) => (
  <WidgetGridItem component={component}>
    <div className={classes.root}>
      <div className={classes.buttonsContainer}>
        <div className={classes.shareButtonContainer}>
          {!component.config.hideShareButton && (
            <>
              <div className={classes.shareButton}>
                <a
                  href="https://twitter.com/share"
                  className="twitter-share-button"
                  data-url={window.location.href}
                  data-text={pageTitle}
                  data-size="large"
                  data-show-count="false"
                  data-via="PIRIKA_info"
                >
                  Tweet
                </a>
              </div>
              <div className={classes.shareButton}>
                <iframe
                  title="facebook"
                  src={`https://www.facebook.com/plugins/share_button.php?href=${encodeURIComponent(
                    window.location.href,
                  )}&layout=button&size=large&width=79&height=28&appId`}
                  width="120"
                  height="28"
                  style={{ border: "none", overflow: "hidden" }}
                  scrolling="no"
                  frameBorder="0"
                  allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                />
              </div>
            </>
          )}
        </div>
        {logoUrl && (
          <div className={classes.bannerContainer}>
            {component.config.logoLinkUrl && (
              <a href={component.config.logoLinkUrl} target="_blank" rel="noopener noreferrer">
                <img src={logoUrl} alt="page" />
              </a>
            )}
            {!component.config.logoLinkUrl && <img className={classes.bannerImg} src={logoUrl} alt="page" />}
            <span className={classes.withPirikaLogo}>
              <a href={`${Constants.snsHowtoUrl}`} target="_blank" rel="noopener noreferrer">
                <WithPirikaImage />
              </a>
            </span>
          </div>
        )}
      </div>
      <div className={classes.copyrightContainer}>
        <div className={classes.copyright}>&copy; Pirika, Inc.</div>
        {hasExternalData && (
          <div className={classes.acknowledgements}>
            <a
              className={classes.acknowledgementsLink}
              href="/"
              onClick={(e) => {
                e.preventDefault();
                onAcknowledgementsClick();
              }}
            >
              使用データについて
            </a>
          </div>
        )}
      </div>
    </div>
  </WidgetGridItem>
);

export default withTranslation()(withStyles(styles)(FooterComponent));

import actionCreatorFactory from "typescript-fsa";
import { reducerWithInitialState } from "typescript-fsa-reducers";
import { ApiDatabox } from "../../api/baseApi";
import { DataboxQueryParameter, LoadingState, PirikaPost } from "../models";
import { mergePosts } from "../functions";
import { PageDatabox } from "../../api/odpApi";

// タイムラインとマップは同じデータを共有する
// そのため、扱うデータの総称となるDataboxという名前を採用している

// models
// ----------------------------------------

export const RegionFocusType = {
  Custom: "region",
  Prefecture: "prefectureRegion",
};
export type RegionFocusType = (typeof RegionFocusType)[keyof typeof RegionFocusType];

export interface DataboxRegionFocus {
  type: RegionFocusType;
  regionId: string;
}

export const PageDataboxMode = {
  Default: "default",
  Region: "region",
};
export type PageDataboxMode = (typeof PageDataboxMode)[keyof typeof PageDataboxMode];

// State
// ----------------------------------------

export interface DataboxComponentState {
  loadingState: LoadingState;
  posts?: PirikaPost[];
  selectedId?: number;
  focusedRegion?: DataboxRegionFocus;
  isTerminated: boolean;
}

const EmptyDataboxComponentState: DataboxComponentState = {
  loadingState: LoadingState.Initial,
  isTerminated: false,
};

export interface DataboxComponentsState {
  databoxes: Record<string, DataboxComponentState>;
}

const initialState: DataboxComponentsState = {
  databoxes: {},
};

// Parameters
// ----------------------------------------

export interface LoadDataboxParameters {
  componentId: string;
  organizationId: number;
  pageId: number;
  databox: PageDatabox;
  parameter: DataboxQueryParameter;
}

export interface SelectDataboxItemParameters {
  databoxId: string;
  postId?: number;
}

export interface FocusDataboxRegionParameters {
  databoxId: string;
  region: DataboxRegionFocus;
}

export interface BlurDataboxRegionParameters {
  databoxId: string;
}

// Support Functions
// ----------------------------------------

const lookupDatabox = (
  state: DataboxComponentsState,
  databoxId: string,
): [DataboxComponentsState, DataboxComponentState] => {
  const id = databoxId;
  if (!state.databoxes[id]) {
    const newDatabox = { ...EmptyDataboxComponentState };
    const newDataboxes = { ...state.databoxes };
    newDataboxes[id] = { ...EmptyDataboxComponentState };
    return [{ ...state, databoxes: newDataboxes }, newDatabox];
  }
  return [state, state.databoxes[id]];
};

const updateDatabox = (
  state: DataboxComponentsState,
  databoxId: string,
  databox: DataboxComponentState,
): DataboxComponentsState => {
  const newDataboxes = { ...state.databoxes };
  newDataboxes[databoxId] = databox;
  return { ...state, databoxes: newDataboxes };
};

export const getRegionFocusId = (focus: DataboxRegionFocus): string => `${focus.type}/${focus.regionId}`;

// Selectors
// ----------------------------------------

// ActionCreators
// ----------------------------------------

const actionCreator = actionCreatorFactory("PirikaOdp/DataboxComponents");

export const loadDatabox = actionCreator<LoadDataboxParameters>("LoadDatabox");
export const loadDataboxProgress = actionCreator.async<LoadDataboxParameters, ApiDatabox, Error>("LoadDataboxProgress");
export const selectDataboxItem = actionCreator<SelectDataboxItemParameters>("SelectDataboxItem");
export const focusDataboxRegionAction = actionCreator<FocusDataboxRegionParameters>("FocusRegion");
export const blurDataboxRegionAction = actionCreator<BlurDataboxRegionParameters>("BlurRegion");

// Reducer
// ----------------------------------------

const reducer = reducerWithInitialState(initialState)
  .case(loadDataboxProgress.started, (state, { databox }) => {
    const [newState, databoxState] = lookupDatabox(state, databox.id);
    return updateDatabox(newState, databox.id, {
      ...databoxState,
      loadingState: LoadingState.Loading,
    });
  })
  .case(loadDataboxProgress.done, (state, { params, result }) => {
    const [newState, databoxState] = lookupDatabox(state, params.databox.id);
    const byArea = params.parameter.latlng !== undefined && params.parameter.zoomLevel !== undefined;
    return updateDatabox(newState, params.databox.id, {
      ...databoxState,
      loadingState: LoadingState.Initial,
      posts: mergePosts(databoxState.posts ?? [], result.info),
      isTerminated: databoxState.isTerminated || (!byArea && result.info.length === 0),
    });
  })
  .case(loadDataboxProgress.failed, (state, { params }) => {
    const [newState, databox] = lookupDatabox(state, params.databox.id);
    return updateDatabox(newState, params.databox.id, {
      ...databox,
      loadingState: LoadingState.Error,
    });
  })
  .case(selectDataboxItem, (state, { databoxId, postId }) => {
    const [newState, databox] = lookupDatabox(state, databoxId);
    return updateDatabox(newState, databoxId, {
      ...databox,
      selectedId: postId,
    });
  })
  .case(focusDataboxRegionAction, (state, { databoxId, region }) => {
    const { databoxes } = state;
    return {
      ...state,
      databoxes: {
        ...databoxes,
        [databoxId]: { ...databoxes[databoxId], focusedRegion: region },
      },
    };
  })
  .case(blurDataboxRegionAction, (state, { databoxId }) => {
    const { databoxes } = state;
    return {
      ...state,
      databoxes: {
        ...databoxes,
        [databoxId]: { ...databoxes[databoxId], focusedRegion: undefined },
      },
    };
  });

export default reducer;

import * as React from "react";
import { WithStyles, withStyles } from "@material-ui/core";
import { withTranslation, WithTranslation } from "react-i18next";
import styles from "./ListLoadingStateStyles";

interface Props extends WithStyles<typeof styles>, WithTranslation {}

const ListLoadingState: React.FC<Props> = ({ classes }) => (
  <div className={classes.root}>
    <div className={classes.frame}>
      <div className={`${classes.point} ${classes.bounce1}`} />
      <div className={`${classes.point} ${classes.bounce2}`} />
      <div className={`${classes.point}`} />
    </div>
  </div>
);

export default withTranslation()(withStyles(styles)(ListLoadingState));

import * as React from "react";
import { connect } from "react-redux";
import DashboardPage from "../../components/dashboard/DashboardPage";
import { RootState } from "../../modules";
import { PageComponent } from "../../api/odpApi";
import TitleComponentContainer from "./components/TitleComponentContainer";
import TimelineComponentContainer from "./components/TimelineComponentContainer";
import FooterComponentContainer from "./components/FooterComponentContainer";
import MapComponentContainer from "./components/MapComponentContainer";
import CounterComponentContainer from "./components/CounterComponentContainer";
import CounterGraphComponentContainer from "./components/CounterGraphComponentContainer";
import GroupEventsComponentContainer from "./components/GroupEventsComponentContainer";
import AreaEventsComponentContainer from "./components/AreaEventsComponentContainer";
import RankingComponentContainer from "./components/RankingComponentContainer";
import UsageComponentContainer from "./components/UsageComponentContainer";
import OrganizationsComponentContainer from "./components/OrganizationsComponentContainer";
import InformationComponentContainer from "./components/InformationComponentContainer";
import HtmlComponentContainer from "./components/HtmlComponentContainer";
import UserListComponentContainer from "./components/UserListComponentContainer";
import PhotoGalleryComponentContainer from "./components/PhotoGalleryComponentContainer";
import EventRegisterModalContainer from "./eventRegister/EventRegisterModalContainer";
import RegionGraphComponentContainer from "./components/RegionGraphComponentContainer";
import EventDialogContainer from "./events/EventDialogContainer";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ExportProps {}

interface StateProps {
  components: PageComponent[] | undefined;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface DispatchProps {}

type Props = ExportProps & StateProps & DispatchProps;

const SelectedComponent = ({ component }: { component: PageComponent }) => {
  switch (component.type) {
    case "title":
      return <TitleComponentContainer component={component} />;
    case "photoGallery":
      return <PhotoGalleryComponentContainer component={component} />;
    case "counter":
      return <CounterComponentContainer component={component} />;
    case "map":
      return <MapComponentContainer component={component} />;
    case "timeline":
      return <TimelineComponentContainer component={component} />;
    case "counterGraph":
      return <CounterGraphComponentContainer component={component} />;
    case "regionGraph":
      return <RegionGraphComponentContainer component={component} />;
    case "groupEvents":
      return <GroupEventsComponentContainer component={component} />;
    case "areaEvents":
      return <AreaEventsComponentContainer component={component} />;
    case "ranking":
      return <RankingComponentContainer component={component} />;
    case "usage":
      return <UsageComponentContainer component={component} />;
    case "organizations":
      return <OrganizationsComponentContainer component={component} />;
    case "information":
      return <InformationComponentContainer component={component} />;
    case "footer":
      return <FooterComponentContainer component={component} />;
    case "html":
      return <HtmlComponentContainer component={component} />;
    case "userList":
      return <UserListComponentContainer component={component} />;
    default:
      return null;
  }
};

class DashboardPageContainer extends React.PureComponent<Props> {
  public render(): JSX.Element {
    const { components } = this.props;
    return (
      <DashboardPage>
        {components?.map((component) => (
          <SelectedComponent key={component.id} component={component} />
        ))}
        <EventRegisterModalContainer />
        <EventDialogContainer />
      </DashboardPage>
    );
  }
}

const mapStateToProps = (state: RootState): StateProps => ({
  components: state.openDataPage.pageConfigurations?.components,
});

const mapDispatchToProps = (/* dispatch: Dispatch */): DispatchProps => ({});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardPageContainer);

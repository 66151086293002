import * as React from "react";
import { CircularProgress, WithStyles, withStyles } from "@material-ui/core";
import { withTranslation, WithTranslation } from "react-i18next";
import styles from "./ComponentLoadingStateViewStyles";

interface Props extends WithStyles<typeof styles>, WithTranslation {}

const ComponentLoadingStateView: React.FC<Props> = ({ classes }) => (
  <div className={classes.root}>
    <CircularProgress />
  </div>
);

export default withTranslation()(withStyles(styles)(ComponentLoadingStateView));

import { put, Effect } from "redux-saga/effects";
import { ApiResponse, ApiResponseStatus } from "../api/odpApi";
// import { sessionError, SessionErrorState } from "./session";
import { Action } from "./index";

export const checkApiResultSimple = <T>(
  result: ApiResponse<T>,
  defaultError: (error: Error) => Action<unknown>,
): Action<unknown> | undefined => {
  if (result.status === ApiResponseStatus.Success) {
    return undefined;
  }
  // switch (result.code as SessionErrorState) {
  //   case SessionErrorState.InvalidToken:
  //   case SessionErrorState.NoUser:
  //   case SessionErrorState.ExpiredSession:
  //   case SessionErrorState.InvalidRequest:
  //   case SessionErrorState.DeviceLocked:
  //   case SessionErrorState.AccountLocked:
  //     return sessionError(result.code as SessionErrorState);
  // }
  return defaultError(new Error());
};

export const checkApiResult = <T>(
  result: ApiResponse<T>,
  successFunc: (payload: T) => Action<unknown> | Action<unknown>[],
  generalErrorFunc: (code: number) => Action<unknown> | Action<unknown>[],
  // sessionErrorFunc?: (code: SessionErrorState) => Action<unknown> | Action<unknown>[] | undefined
): Effect<unknown, unknown>[] => {
  const effectResult = (effects: Action<unknown> | Action<unknown>[]): Effect<unknown, unknown>[] =>
    Array.isArray(effects) ? effects.map((e) => put(e)) : [put(effects)];
  if (result.status === ApiResponseStatus.Success && result.payload !== undefined) {
    return effectResult(successFunc(result.payload));
  }
  // switch (result.code as SessionErrorState) {
  //   case SessionErrorState.InvalidToken:
  //   case SessionErrorState.NoUser:
  //   case SessionErrorState.ExpiredSession:
  //   case SessionErrorState.InvalidRequest:
  //   case SessionErrorState.DeviceLocked:
  //   case SessionErrorState.AccountLocked: {
  //     const errorAction = sessionErrorFunc && sessionErrorFunc(result.code as SessionErrorState);
  //     return effectResult(errorAction || sessionError(result.code as SessionErrorState));
  //   }
  // }
  return effectResult(generalErrorFunc(result.code));
};

export const checkPirikaWebApiResult = <T extends { status: ApiResponseStatus; code: number }>(
  result: T,
  successFunc: (payload: T) => Action<unknown> | Action<unknown>[],
  generalErrorFunc: (code: number) => Action<unknown> | Action<unknown>[],
  // sessionErrorFunc?: (code: SessionErrorState) => Action<unknown> | Action<unknown>[] | undefined
): Effect<unknown, unknown>[] => {
  const effectResult = (effects: Action<unknown> | Action<unknown>[]): Effect<unknown, unknown>[] =>
    Array.isArray(effects) ? effects.map((e) => put(e)) : [put(effects)];
  if (result.status === ApiResponseStatus.Success) {
    return effectResult(successFunc(result));
  }
  // switch (result.code as SessionErrorState) {
  //   case SessionErrorState.InvalidToken:
  //   case SessionErrorState.NoUser:
  //   case SessionErrorState.ExpiredSession:
  //   case SessionErrorState.InvalidRequest:
  //   case SessionErrorState.DeviceLocked:
  //   case SessionErrorState.AccountLocked: {
  //     const errorAction = sessionErrorFunc && sessionErrorFunc(result.code as SessionErrorState);
  //     return effectResult(errorAction || sessionError(result.code as SessionErrorState));
  //   }
  // }
  return effectResult(generalErrorFunc(result.code));
};

import React from "react";
import GoogleMapReact from "google-map-react";

import { Button, TextField, withStyles, WithStyles } from "@material-ui/core";
import { withTranslation, WithTranslation } from "react-i18next";
import marker from "../../../../assets/map.png";
import Constants from "../../../../constants";
import styles from "./LocationInputStyles";

interface Props extends WithStyles<typeof styles>, WithTranslation {
  place: string;
  onMapMoved: (lat: number, lng: number) => void;
  onPlaceEdited: (candidatePlace: string) => void;
  handlePlaceSearch: () => void;
  lat: number;
  lng: number;
}

const LocationInput: React.FC<Props> = ({ classes, place, onMapMoved, onPlaceEdited, handlePlaceSearch, lat, lng }) => (
  <div className={classes.root}>
    <div className={classes.placeEditArea}>
      <TextField
        name="place"
        label="住所で場所を指定"
        value={place}
        onChange={(e) => {
          const { value } = e.target;
          onPlaceEdited(value);
        }}
        fullWidth
      />
      <Button variant="contained" onClick={handlePlaceSearch} className={classes.searchButton}>
        検索する
      </Button>
    </div>
    <div className={classes.map}>
      <GoogleMapReact
        yesIWantToUseGoogleMapApiInternals
        bootstrapURLKeys={{ key: Constants.googleApiKey }}
        center={{ lat, lng }}
        defaultZoom={14}
        options={{
          clickableIcons: false,
          scrollwheel: true,
          fullscreenControl: false,
        }}
        onChange={(e) => {
          onMapMoved(e.center.lat, e.center.lng);
        }}
      />
      <img src={marker} alt="center" className={classes.marker} />
    </div>
  </div>
);

export default withTranslation()(withStyles(styles)(LocationInput));

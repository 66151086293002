import { all, call, put, takeEvery } from "redux-saga/effects";
import { Action } from "typescript-fsa";
import { SagaIterator } from "redux-saga";
import { loadInformation, LoadInformationParameters, loadInformationProgress } from "./index";
import Api, { ApiInformation, ApiResponse } from "../../api/odpApi";
import { checkApiResult } from "../saga-support";

function* loadInformationSaga(action: Action<LoadInformationParameters>) {
  const params = action.payload;
  const progressAction = loadInformationProgress;
  yield put(progressAction.started(params));

  const result = (yield call([Api, Api.getInformationAsync], params.parameter)) as ApiResponse<ApiInformation>;
  yield all(
    checkApiResult(
      result,
      (payload: ApiInformation) => progressAction.done({ params, result: payload }),
      () => progressAction.failed({ params, error: new Error() }),
    ),
  );
}

export default function* watch(): SagaIterator {
  yield takeEvery(loadInformation.type, loadInformationSaga);
}

import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import ConfigurationLoginFailedStateScreen from "../../components/loading/ConfigurationLoginFailedStateScreen";
import { loadOpenDataPageConfiguration as loadOpenDataPageConfigurationAction } from "../../modules/openDataPage";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ExportProps {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface StateProps {}

interface DispatchProps {
  loadOpenDataPageConfiguration: (password: string) => void;
}

type Props = ExportProps & StateProps & DispatchProps;

interface State {
  password: string;
}

class ConfigurationLoginFailedStateScreenContainer extends React.Component<Props, State> {
  public constructor(props: Props, context: unknown) {
    super(props, context);
    this.state = {
      password: "",
    };
  }

  private handlePasswordChange = (password: string) => this.setState({ password });

  private handlePasswordSubmit = () => {
    const { loadOpenDataPageConfiguration } = this.props;
    const { password } = this.state;
    loadOpenDataPageConfiguration(password);
  };

  public render(): JSX.Element {
    const { password } = this.state;
    return (
      <ConfigurationLoginFailedStateScreen
        password={password}
        onPasswordChange={this.handlePasswordChange}
        onPasswordSubmit={this.handlePasswordSubmit}
      />
    );
  }
}

const mapStateToProps = (/* state: RootState */): StateProps => ({});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  loadOpenDataPageConfiguration: (password) => dispatch(loadOpenDataPageConfigurationAction({ password })),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfigurationLoginFailedStateScreenContainer);

import { all, call, put, takeEvery } from "redux-saga/effects";
import { Action } from "typescript-fsa";
import { SagaIterator } from "redux-saga";
import { loadRanking, LoadRankingParameters, loadRankingProgress } from "./index";
import Api, { ApiRanking } from "../../api/baseApi";
import { checkPirikaWebApiResult } from "../saga-support";

function* loadRankingSaga(action: Action<LoadRankingParameters>) {
  const params = action.payload;
  const progressAction = loadRankingProgress;
  yield put(progressAction.started(params));

  const result = (yield call(
    [Api, Api.getRankingAsync],
    params.organizationId,
    params.pageId,
    params.mode,
    params.aggregateBy,
    params.period,
    params.exceptUserListId,
  )) as ApiRanking;
  yield all(
    checkPirikaWebApiResult(
      result,
      (payload: ApiRanking) => progressAction.done({ params, result: payload }),
      () => progressAction.failed({ params, error: new Error() }),
    ),
  );
}

export default function* watch(): SagaIterator {
  yield takeEvery(loadRanking.type, loadRankingSaga);
}

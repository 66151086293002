import actionCreatorFactory from "typescript-fsa";
import { reducerWithInitialState } from "typescript-fsa-reducers";
import { ApiEvent, ApiEvents } from "../../api/baseApi";
import { LoadingState } from "../models";

// models
// ----------------------------------------

export type AreaEvent = ApiEvent;

// State
// ----------------------------------------

export interface AreaEventsComponentState {
  loadingState: LoadingState;
  events?: AreaEvent[];
}

const EmptyAreaEventsComponentState: AreaEventsComponentState = {
  loadingState: LoadingState.Initial,
};

export interface AreaEventsComponentsState {
  components: Record<string, AreaEventsComponentState>;
}

const initialState: AreaEventsComponentsState = {
  components: {},
};

// Parameters
// ----------------------------------------

export interface LoadAreaEventsParameters {
  componentId: string;
  organizationId: number;
  pageId: number;
  cursorId?: number;
  all: boolean;
}

// Support Functions
// ----------------------------------------

const lookupComponent = (
  state: AreaEventsComponentsState,
  id: string,
): [AreaEventsComponentsState, AreaEventsComponentState] => {
  if (!state.components[id]) {
    const newComponent = { ...EmptyAreaEventsComponentState };
    const newComponents = { ...state.components };
    newComponents[id] = newComponent;
    return [{ ...state, components: newComponents }, newComponent];
  }
  return [state, state.components[id]];
};

const updateComponent = (
  state: AreaEventsComponentsState,
  id: string,
  component: AreaEventsComponentState,
): AreaEventsComponentsState => {
  const newComponents = { ...state.components };
  newComponents[id] = component;
  return { ...state, components: newComponents };
};

// Selectors
// ----------------------------------------

// ActionCreators
// ----------------------------------------

const actionCreator = actionCreatorFactory("PirikaOdp/AreaEventsComponents");

export const loadAreaEvents = actionCreator<LoadAreaEventsParameters>("LoadAreaEvents");
export const loadAreaEventsProgress = actionCreator.async<LoadAreaEventsParameters, ApiEvents, Error>(
  "LoadAreaEventsProgress",
);

// Reducer
// ----------------------------------------

const reducer = reducerWithInitialState(initialState)
  .case(loadAreaEventsProgress.started, (state, { componentId }) => {
    const [newState, component] = lookupComponent(state, componentId);
    return updateComponent(newState, componentId, {
      ...component,
      loadingState: LoadingState.Loading,
    });
  })
  .case(loadAreaEventsProgress.done, (state, { params, result }) => {
    const [newState, component] = lookupComponent(state, params.componentId);
    const events = params.cursorId && component.events ? [...component.events, ...result.events] : result.events;
    return updateComponent(newState, params.componentId, {
      ...component,
      loadingState: LoadingState.Initial,
      events,
    });
  })
  .case(loadAreaEventsProgress.failed, (state, { params }) => {
    const [newState, component] = lookupComponent(state, params.componentId);
    return updateComponent(newState, params.componentId, {
      ...component,
      loadingState: LoadingState.Error,
    });
  });

export default reducer;

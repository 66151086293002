import { useEffect, useRef } from "react";

// eslint-disable-next-line import/prefer-default-export
export const usePrevious = <T>(value: T): T | undefined => {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

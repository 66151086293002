export const ApiResponseStatus = {
  Success: "success",
  Error: "error",
};
export type ApiResponseStatus = (typeof ApiResponseStatus)[keyof typeof ApiResponseStatus];

export const PageComponentSize = {
  S: "S",
  M: "M",
  L: "L",
};
export type PageComponentSize = (typeof PageComponentSize)[keyof typeof PageComponentSize];

export const ApiPageContractType = {
  Government: "government",
  Organization: "organization",
};
export type ApiPageContractType = (typeof ApiPageContractType)[keyof typeof ApiPageContractType];

export const ApiPageDataboxMode = {
  Default: "default",
  Region: "region",
};
export type ApiPageDataboxMode = (typeof ApiPageDataboxMode)[keyof typeof ApiPageDataboxMode];

export interface ApiResponse<T> {
  code: number;
  status: ApiResponseStatus;
  message?: string;
  payload?: T;
}

export interface PageConfigurations {
  config: PageConfiguration;
  components: PageComponent[];
  databoxes: PageDatabox[];
}

export interface PageConfiguration {
  type: ApiPageContractType;
  organizationId: number;
  openDataPageId: number;
  keyColor: string;
  title: string;
  isWidget?: boolean;
  eventRegisterDescription: string;
  eventPrefecture: string;
  eventCity: string;
  eventCities: string[];
  originPeriod: string;
  recaptchaKey: string;
  /**
   * @deprecated
   */
  databoxMode?: ApiPageDataboxMode;
}

export interface PageComponentCommon<TName, TConfig> {
  id: string;
  size: PageComponentSize;
  title: string;
  allowScreens: PageComponentSize[];
  type: TName;
  config: TConfig;
}

export type TitlePageComponent = PageComponentCommon<
  "title",
  {
    subTitle: string;
    upperSubTitle: string;
    description: string;
    logoImage: string;
  }
>;

export type PhotoGalleryPageComponent = PageComponentCommon<
  "photoGallery",
  {
    smallImage: string;
    middleImage: string;
    largeImage: string;
  }
>;

export type CounterPageComponent = PageComponentCommon<
  "counter",
  {
    databox: string;
    showParticipants: boolean;
    participantsTitle: string;
    showPieces: boolean;
    piecesTitle: string;
    backgroundColor: string;
    counterAddMetaId?: number;
  }
>;

export type MapPageComponent = PageComponentCommon<
  "map",
  {
    databox: string;
    northEast: string;
    southWest: string;
    regionDrawType?: "none" | "static" | "relative" | "prefecture";
    regionLowerColor?: string;
    regionHigherColor?: string;
    regionStrokeColor?: string;
    usePopulationScale?: boolean;
    legendMaxTrashes?: number;
    legendMaxParticipants?: number;
    customizeHeight?: number;
    customizePinImage?: string;
  }
>;

export type TimelinePageComponent = PageComponentCommon<
  "timeline",
  {
    databox: string;
    backgroundColor?: string;
    customizeHeight?: number;
  }
>;

export type CounterGraphPageComponent = PageComponentCommon<
  "counterGraph",
  {
    databox: string;
    accessoryTooltipText: string;
    accessoryLinkTarget: string;
    accessoryLinkTitle: string;
    counterAddMetaId?: number;
  }
>;

export type RegionGraphPageComponent = PageComponentCommon<
  "regionGraph",
  {
    chartType: "stackedBar" | "summary";
    collectionPeriodType: "all" | "year" | "fiscalYear";
    accessoryTooltipText: string;
    accessoryLinkTarget: string;
    accessoryLinkTitle: string;
  }
>;

export type GroupEventsPageComponent = PageComponentCommon<
  "groupEvents",
  {
    groupId: number;
    accessoryTooltipText: string;
    accessoryLinkTarget: string;
    accessoryLinkTitle: string;
  }
>;

export type AreaEventsPageComponent = PageComponentCommon<
  "areaEvents",
  {
    showAllEvents: boolean;
    accessoryTooltipText: string;
    accessoryLinkTarget: string;
    accessoryLinkTitle: string;
  }
>;

export type RankingPageComponent = PageComponentCommon<
  "ranking",
  {
    mode: "areaIndividual" | "areaNonIndividual" | "organizationAccounts";
    aggregateBy: "post" | "trashPiece";
    period: "all" | "thisYear" | "thisFiscalYear" | "lastYear";
    exceptUserListId?: number;
    accessoryTooltipText: string;
    accessoryLinkTarget: string;
    accessoryLinkTitle: string;
  }
>;

export type UsagePageComponent = PageComponentCommon<
  "usage",
  {
    accessoryTooltipText: string;
    accessoryLinkTarget: string;
    accessoryLinkTitle: string;
  }
>;

export type OrganizationsPageComponent = PageComponentCommon<
  "organizations",
  {
    accessoryTooltipText: string;
    accessoryLinkTarget: string;
    accessoryLinkTitle: string;
    blockUserListId: number | undefined;
  }
>;

export type InformationPageComponent = PageComponentCommon<
  "information",
  {
    accessoryTooltipText: string;
    accessoryLinkTarget: string;
    accessoryLinkTitle: string;
  }
>;

export type FooterPageComponent = PageComponentCommon<
  "footer",
  {
    logoImage: string;
    logoLinkUrl: string;
    hideShareButton?: boolean;
  }
>;

export type HtmlPageComponent = PageComponentCommon<
  "html",
  {
    useFrame: boolean;
    html: string;
    accessoryTooltipText: string;
    accessoryLinkTarget: string;
    accessoryLinkTitle: string;
  }
>;

export type UserListPageComponent = PageComponentCommon<
  "userList",
  {
    accessoryTooltipText: string;
    accessoryLinkTarget: string;
    accessoryLinkTitle: string;
    userListId: number;
    columns: 1 | 2 | 3;
    listType: "simple" | "icon";
  }
>;

export type PageComponent =
  | TitlePageComponent
  | PhotoGalleryPageComponent
  | CounterPageComponent
  | MapPageComponent
  | TimelinePageComponent
  | CounterGraphPageComponent
  | RegionGraphPageComponent
  | GroupEventsPageComponent
  | AreaEventsPageComponent
  | RankingPageComponent
  | UsagePageComponent
  | OrganizationsPageComponent
  | InformationPageComponent
  | FooterPageComponent
  | HtmlPageComponent
  | UserListPageComponent;

export const PageDataboxType = {
  Area: "area",
  Organization: "organization",
  Region: "region",
  Hashtag: "hashtag",
};
export type PageDataboxType = (typeof PageDataboxType)[keyof typeof PageDataboxType];

export type PageDatabox = {
  id: string;
  name: string;
  type: PageDataboxType;
  hashtag?: string;
};

export interface ApiInformationItem {
  id: number;
  title: string;
  detail: string;
  author: string;
  url: string;
  date: Date;
  availableFrom: Date;
  availableTo: Date;
  updateDate: Date;
}

export interface ApiInformationParameter {
  cursorId?: number;
  count?: number;
}

export interface ApiInformation {
  information: ApiInformationItem[];
}

export interface ApiEventApprovalRegister {
  message: ApiEventRegisterResponseMessage;
}

export interface ApiEventRegisterParams {
  contactName: string;
  contactEmail: string;
  contactPhone?: string;
  contactOther?: string;
  title: string;
  comment: string;
  startDate?: Date;
  endDate?: Date;
  authorName: string;
  photoFile?: File[];
  lat: number;
  lng: number;
}

export interface ApiEventRegisterResponseMessage {
  title: string;
  description: string;
}

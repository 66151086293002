import * as React from "react";
import { WithStyles, withStyles } from "@material-ui/core";
import { withTranslation, WithTranslation } from "react-i18next";
import styles from "./TimelineEmptyStateStyles";

interface Props extends WithStyles<typeof styles>, WithTranslation {}

const TimelineEmptyState: React.FC<Props> = ({ classes }) => <div className={classes.root}>投稿がありません</div>;

export default withTranslation()(withStyles(styles)(TimelineEmptyState));

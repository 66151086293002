import * as React from "react";
import { WithStyles, withStyles } from "@material-ui/core";
import { withTranslation, WithTranslation } from "react-i18next";
import { useEffect, useRef } from "react";
import Chart from "react-google-charts";
// eslint-disable-next-line import/no-unresolved
import { GoogleChartWrapper } from "react-google-charts/dist/types";
import { CounterDataPoint } from "../../../../modules/counterGraphComponents";
import styles from "./CounterGraphStyles";
import { periodToDate } from "../../../../helpers/date";

interface Props extends WithStyles<typeof styles>, WithTranslation {
  dataPoints: CounterDataPoint[];
}

const CounterGraph: React.FC<Props> = ({ dataPoints, classes }) => {
  // TODO: リサイズのための対応だが、動作している気配がない…
  let chartWrapper: GoogleChartWrapper | undefined;
  const containerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      chartWrapper?.draw();
    });
    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }
    return (): void => resizeObserver.disconnect();
  }, []);

  return (
    <div className={classes.root}>
      <div className={classes.chartContainer} ref={containerRef}>
        <Chart
          chartType="AreaChart"
          getChartWrapper={(newChartWrapper) => {
            chartWrapper = newChartWrapper;
          }}
          options={{
            title: "",
            hAxis: {
              title: "月",
              titleTextStyle: { color: "#333" },
              format: "yyyy/MM",
            },
            vAxes: {
              0: { minValue: 0, title: "人" },
              1: { minValue: 0, title: "個" },
            },
            series: {
              0: { type: "line", targetAxisIndex: 0 },
              1: { type: "line", targetAxisIndex: 1 },
            },
          }}
          formatters={[
            {
              type: "DateFormat",
              column: 0,
              options: {
                pattern: "yyyy/MM",
              },
            },
          ]}
          columns={[
            {
              label: "年月",
              type: "date",
            },
            {
              label: "のべ参加人数",
              type: "number",
            },
            {
              label: "拾われたごみの数",
              type: "number",
            },
          ]}
          rows={[...dataPoints.map((v) => [periodToDate(v.period), v.participants, v.trash])]}
        />
      </div>
    </div>
  );
};

export default withTranslation()(withStyles(styles)(CounterGraph));

import { takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "redux-saga";
import { openNewWindow, OpenNewWindowParameters } from "./index";
import { Action } from "../index";

const processOpenNewWindow = (url: string) => window.open(url, "_blank", "noreferrer");

function* openNewWindowSaga(action: Action<OpenNewWindowParameters>) {
  const { payload } = action;
  if (!payload) {
    return;
  }

  const { url } = payload;

  yield call(() => {
    processOpenNewWindow(url);
  });
}

export default function* watch(): SagaIterator {
  yield takeEvery(openNewWindow.type, openNewWindowSaga);
}

import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import CounterGraphComponent from "../../../components/dashboard/components/CounterGraphComponent";
import { RootState } from "../../../modules";
import { CounterGraphPageComponent, PageDatabox } from "../../../api/odpApi";
import {
  accumulateDataPoints,
  CounterGraphComponentState,
  loadCounterGraphActivities as loadCounterGraphActivitiesAction,
  LoadCounterGraphActivitiesParameters,
} from "../../../modules/counterGraphComponents";
import { LoadingState } from "../../../modules/models";
import { selectDataboxConfig } from "../../../modules/openDataPage";

interface ExportProps {
  component: CounterGraphPageComponent;
}

interface StateProps {
  organizationId: number;
  openDataPageId: number;
  componentsStates: Record<string, CounterGraphComponentState>;
  databoxConfig: PageDatabox | undefined;
}

interface DispatchProps {
  loadCounterGraphActivities: (params: LoadCounterGraphActivitiesParameters) => void;
}

type Props = ExportProps & StateProps & DispatchProps;

class CounterGraphComponentContainer extends React.Component<Props> {
  public componentDidMount() {
    const { loadCounterGraphActivities, component, organizationId, openDataPageId, databoxConfig } = this.props;
    if (!databoxConfig) {
      return;
    }
    loadCounterGraphActivities({
      componentId: component.id,
      organizationId,
      pageId: openDataPageId,
      databox: databoxConfig,
      counterAddMetaId: component.config.counterAddMetaId,
    });
  }

  public render(): JSX.Element {
    const { component, componentsStates } = this.props;
    const states = componentsStates[component.id];
    const loadingState =
      (states?.dataPoints === undefined && states?.loadingState !== LoadingState.Error) ||
      states?.loadingState === undefined
        ? LoadingState.Loading
        : states.loadingState;
    const dataPoints = accumulateDataPoints(states?.dataPoints ?? []);
    return <CounterGraphComponent component={component} loadingState={loadingState} dataPoints={dataPoints} />;
  }
}

const mapStateToProps = (state: RootState, ownProps: ExportProps): StateProps => ({
  databoxConfig: selectDataboxConfig(state, ownProps.component.config.databox),
  componentsStates: state.counterGraphComponents.components,
  organizationId: state.openDataPage.pageConfigurations?.config.organizationId ?? 0,
  openDataPageId: state.openDataPage.pageConfigurations?.config.openDataPageId ?? 0,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  loadCounterGraphActivities: (params) =>
    dispatch(loadCounterGraphActivitiesAction(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CounterGraphComponentContainer);

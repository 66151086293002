import { PirikaPost } from "./models";

// eslint-disable-next-line import/prefer-default-export
export const mergePosts = (base: PirikaPost[], appendArray: PirikaPost[]): PirikaPost[] => {
  const result = [...base];
  appendArray.forEach((append) => {
    if (result.find((v) => v.id === append.id) === undefined) {
      result.push(append);
    }
  });
  result.sort((a, b) => b.date.getTime() - a.date.getTime());
  return result;
};

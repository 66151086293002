import { createStyles, StyleRules, Theme } from "@material-ui/core";

const styles = (theme: Theme): StyleRules =>
  createStyles({
    root: {},
    dialogTitleContainer: {
      display: "flex",
    },
    dialogTitle: {
      flexGrow: 1,
    },
    dialogCloseButton: {
      paddingTop: theme.spacing(1),
      paddingRight: theme.spacing(1.5),
    },
    modalDescription: {
      marginBottom: theme.spacing(2),
    },
    footerContainer: {
      minHeight: "55px",
    },
    footer: {
      padding: theme.spacing(1),
      textAlign: "center",
      verticalAlign: "middle",
    },
    footerError: {
      color: theme.palette.error.main,
    },
  });
export default styles;

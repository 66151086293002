import { createStyles, StyleRules, Theme } from "@material-ui/core";

const breakpointSize = 960;

const styles = (theme: Theme): StyleRules =>
  createStyles({
    root: {},
    contentsFrame: {
      display: "flex",
      flexDirection: "row",
      [theme.breakpoints.down(breakpointSize)]: {
        flexDirection: "column",
      },
    },
    description: {
      flexGrow: 1,
    },
    descriptionText: {
      margin: 0,
      fontSize: "14px",
      marginBottom: theme.spacing(1),
    },
    descriptionImageContainer: {
      textAlign: "center",
    },
    descriptionImage: {
      maxHeight: 220,
      [theme.breakpoints.down(breakpointSize)]: {
        maxHeight: "auto",
        width: "100%",
      },
    },
    links: {
      display: "flex",
      flexDirection: "column",
    },
    linkFrame: {
      display: "flex",
      flexDirection: "row",
      backgroundColor: "#f7fafe",
      padding: theme.spacing(1),
      borderRadius: "5px",
      minWidth: "360px",
      marginBottom: theme.spacing(1),
      "&:last-child": {
        marginBottom: 0,
      },
      [theme.breakpoints.down(breakpointSize)]: {
        flexDirection: "column",
        minWidth: "auto",
      },
    },
    linkDescription: {
      flexGrow: 1,
      display: "flex",
      flexDirection: "column",
      marginRight: theme.spacing(2),
      [theme.breakpoints.down(breakpointSize)]: {
        marginRight: 0,
      },
    },
    linkDescriptionText: {
      fontSize: "14px",
      marginBottom: theme.spacing(2),
    },
    linkDescriptionImage: {
      alignSelf: "center",
    },
    storeIconContainer: {
      textAlign: "center",
    },
    storeIconImage: {
      width: "210px",
      [theme.breakpoints.down(breakpointSize)]: {
        width: "95%",
      },
    },
    linkButtons: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      [theme.breakpoints.down(breakpointSize)]: {
        flexDirection: "row",
        justifyContent: "space-around",
        paddingTop: theme.spacing(2),
      },
    },
    loginButton: {
      width: "210px",
      height: "60px",
      marginBottom: theme.spacing(1),
      fontSize: "16px",
      backgroundColor: "#1470CC",
      color: "white",
      "&:hover": {
        backgroundColor: "#1470CC",
        color: "white",
      },
      [theme.breakpoints.down(breakpointSize)]: {
        width: "60%",
        height: "60px",
        marginBottom: 0,
        marginLeft: theme.spacing(1),
        fontSize: theme.typography.subtitle2.fontSize,
      },
    },
  });
export default styles;

/* eslint-disable react/no-danger */
import * as React from "react";
import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  WithStyles,
  withStyles,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { withTranslation, WithTranslation } from "react-i18next";
import { FormikProps } from "formik";
import styles from "./EventRegisterModalStyles";
import { EventRegisterApplication, EventRegisterResponseMessage } from "../../../modules/eventRegisterModal";
import EventRegisterModalForm from "./EventRegisterModalForm";
import { LoadingState } from "../../../modules/models";

interface Props extends WithStyles<typeof styles>, WithTranslation {
  modalOpen: boolean;
  onClose: () => void;
  submittingLoadingState: LoadingState;
  eventRegisterDescription?: string;
  responseMessage?: EventRegisterResponseMessage;
  formikProps: FormikProps<EventRegisterApplication>;
  children?: React.ReactNode;
}

const EventRegisterModal: React.FC<Props> = ({
  classes,
  modalOpen,
  onClose,
  submittingLoadingState,
  eventRegisterDescription,
  responseMessage,
  formikProps,
  children,
}) => (
  <Dialog
    open={modalOpen}
    onClose={onClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
    fullWidth
  >
    <div className={classes.dialogTitleContainer}>
      <DialogTitle className={classes.dialogTitle}>
        <div>イベントを申請する</div>
      </DialogTitle>
      <div className={classes.dialogCloseButton}>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </div>
    </div>
    <DialogContent>
      {!responseMessage && (
        <>
          {eventRegisterDescription && (
            <div className={classes.modalDescription} dangerouslySetInnerHTML={{ __html: eventRegisterDescription }} />
          )}
          <EventRegisterModalForm formikProps={formikProps}>{children}</EventRegisterModalForm>
        </>
      )}
      {responseMessage && (
        <div>
          <h4>{responseMessage.title}</h4>
          <p>{responseMessage.description}</p>
        </div>
      )}
      <div className={classes.footerContainer}>
        {submittingLoadingState === LoadingState.Loading && (
          <div className={classes.footer}>
            <CircularProgress />
          </div>
        )}
        {submittingLoadingState === LoadingState.Error && (
          <div className={`${classes.footer} ${classes.footerError}`}>
            申請できませんでした。数回申請できないことが続く場合、ピリカまでお問い合わせください。
          </div>
        )}
      </div>
    </DialogContent>
  </Dialog>
);

export default withTranslation()(withStyles(styles)(EventRegisterModal));

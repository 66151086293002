import { Action } from "typescript-fsa";
import { all, call, put, takeEvery } from "redux-saga/effects";
import { SagaIterator } from "redux-saga";
import {
  loadPrefectureRegion,
  loadPrefectureRegionActivities,
  loadPrefectureRegionActivitiesProgress,
  loadPrefectureRegionDataboxAction,
  loadPrefectureRegionDataboxActionProgress,
  LoadPrefectureRegionDataboxParameters,
  loadPrefectureRegionGeoJson,
  LoadPrefectureRegionGeoJsonParameters,
  loadPrefectureRegionGeoJsonProgress,
  LoadPrefectureRegionParameters,
  loadPrefectureRegionProgress,
} from "./index";
import Api, { ApiDatabox, ApiPrefectureRegion, ApiRegionActivities } from "../../api/baseApi";
import GeoJsonDownloader from "../../api/geojson";
import { checkPirikaWebApiResult } from "../saga-support";
import { GeoJson } from "../../api/geojson/interface";

function* loadPrefectureRegionSaga(action: Action<LoadPrefectureRegionParameters>) {
  const params = action.payload;
  const progressAction = loadPrefectureRegionProgress;
  yield put(progressAction.started(params));

  const result = (yield call(
    [Api, Api.getPrefectureRegionAsync],
    params.organizationId,
    params.pageId,
  )) as ApiPrefectureRegion;
  yield all(
    checkPirikaWebApiResult(
      result,
      (payload: ApiPrefectureRegion) => progressAction.done({ params, result: payload }),
      () => progressAction.failed({ params, error: new Error() }),
    ),
  );
}

function* loadPrefectureRegionActivitiesSaga(action: Action<LoadPrefectureRegionParameters>) {
  const params = action.payload;
  const progressAction = loadPrefectureRegionActivitiesProgress;
  yield put(progressAction.started(params));

  const result = (yield call(
    [Api, Api.getPrefectureRegionActivitiesAsync],
    params.organizationId,
    params.pageId,
  )) as ApiRegionActivities;
  yield all(
    checkPirikaWebApiResult(
      result,
      (payload: ApiRegionActivities) => progressAction.done({ params, result: payload }),
      () => progressAction.failed({ params, error: new Error() }),
    ),
  );
}

function* loadPrefectureRegionGeoJsonSaga(action: Action<LoadPrefectureRegionGeoJsonParameters>) {
  const params = action.payload;
  const progressAction = loadPrefectureRegionGeoJsonProgress;
  yield put(progressAction.started(params));

  try {
    const result = (yield call(
      [GeoJsonDownloader, GeoJsonDownloader.getTopoJsonZipAsync],
      params.topojsonUrl,
    )) as GeoJson;
    yield put(progressAction.done({ params, result }));
  } catch (error) {
    yield put(progressAction.failed({ params, error: error as Error }));
  }
}

function* loadPrefectureRegionDataboxSaga(action: Action<LoadPrefectureRegionDataboxParameters>) {
  const params = action.payload;
  const progressAction = loadPrefectureRegionDataboxActionProgress;
  yield put(progressAction.started(params));

  // TODO: 都道府県区域分割用のものに置き換える
  const result = (yield call(
    [Api, Api.getPrefectureRegionDataboxAsync],
    params.organizationId,
    params.pageId,
    params.regionId,
    params.parameter,
  )) as ApiDatabox;
  yield all(
    checkPirikaWebApiResult(
      result,
      (payload: ApiDatabox) => progressAction.done({ params, result: payload }),
      () => progressAction.failed({ params, error: new Error() }),
    ),
  );
}

export default function* watch(): SagaIterator {
  yield takeEvery(loadPrefectureRegion.type, loadPrefectureRegionSaga);
  yield takeEvery(loadPrefectureRegionActivities.type, loadPrefectureRegionActivitiesSaga);
  yield takeEvery(loadPrefectureRegionGeoJson.type, loadPrefectureRegionGeoJsonSaga);
  yield takeEvery(loadPrefectureRegionDataboxAction.type, loadPrefectureRegionDataboxSaga);
}

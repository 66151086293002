import { Button, TextField, Typography, withStyles, WithStyles } from "@material-ui/core";
import * as React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { FormikProps } from "formik";
import DateTimePicker from "./parts/DateTimePicker";
import styles from "./EventRegisterModalFormStyles";
import { EventRegisterApplication } from "../../../modules/eventRegisterModal";

interface Props extends WithStyles<typeof styles>, WithTranslation {
  formikProps: FormikProps<EventRegisterApplication>;
  children?: React.ReactNode;
}

const EventRegisterModalForm: React.FC<Props> = ({ classes, formikProps, children }) => {
  const { values, handleSubmit, handleChange, handleBlur, setFieldValue, touched, errors, isValid } = formikProps;
  return (
    <form onSubmit={handleSubmit}>
      <div className={classes.formArea}>
        <Typography variant="h6">申請者情報</Typography>
        <div>
          <TextField
            className={classes.textField}
            name="contactName"
            label="申請者氏名"
            type="text"
            value={values.contactName}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.contactName && Boolean(errors.contactName)}
            helperText={touched.contactName && errors.contactName}
            fullWidth
            required
          />
        </div>
        <div>
          <TextField
            className={classes.textField}
            name="contactEmail"
            label="連絡先メールアドレス"
            type="email"
            value={values.contactEmail}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.contactEmail && Boolean(errors.contactEmail)}
            helperText={touched.contactEmail && errors.contactEmail}
            fullWidth
            required
          />
        </div>
        <div>
          <TextField
            className={classes.textField}
            name="contactPhone"
            label="連絡先電話番号"
            type="tel"
            value={values.contactPhone}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.contactPhone && Boolean(errors.contactPhone)}
            helperText={touched.contactPhone && errors.contactPhone}
            fullWidth
          />
        </div>
        <div>
          <TextField
            className={classes.textField}
            name="contactOther"
            label="連絡先(その他、自由記入)"
            type="text"
            value={values.contactOther}
            onChange={handleChange}
            fullWidth
          />
        </div>
      </div>
      <div className={classes.formArea}>
        <Typography variant="h6">イベント情報</Typography>
        <div>
          <TextField
            className={classes.textField}
            name="title"
            label="イベント名"
            value={values.title}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.title && Boolean(errors.title)}
            helperText={touched.title && errors.title}
            required
            fullWidth
          />
        </div>
        <DateTimePicker
          className={classes.datePickers}
          start={values.startDate}
          end={values.endDate}
          onStartChange={(date: Date) => {
            setFieldValue("startDate", date);
          }}
          onEndChange={(date: Date) => {
            setFieldValue("endDate", date);
          }}
          onBlur={handleBlur}
          startDateError={Boolean(errors.startDate)}
          endDateError={Boolean(errors.endDate)}
          startDateHelperText={errors.startDate}
          endDateHelperText={errors.endDate}
        />
        <div>
          <TextField
            className={classes.textField}
            name="comment"
            label="イベント説明"
            multiline
            value={values.comment}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.comment && Boolean(errors.comment)}
            helperText={touched.comment && errors.comment}
            required
            fullWidth
          />
        </div>
        <div>
          <TextField
            className={classes.textField}
            name="authorName"
            label="イベントページでの開催者表示名"
            value={values.authorName}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.authorName && Boolean(errors.authorName)}
            helperText={touched.authorName && errors.authorName}
            required
            fullWidth
          />
        </div>
        {children}
      </div>
      <div className={classes.submitArea}>
        <Button variant="contained" color="primary" type="submit" disabled={!isValid}>
          申請する
        </Button>
      </div>
    </form>
  );
};

export default withTranslation()(withStyles(styles)(EventRegisterModalForm));

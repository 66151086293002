import { takeEvery, call, put, all } from "redux-saga/effects";
import { SagaIterator } from "redux-saga";
import { Action } from "../index";
import { loadEvent, LoadEventParameters, loadEventProgress, openEvent, OpenEventParameters } from "./index";
import { openNewWindow } from "../window";
import Constants from "../../constants";
import Api, { ApiEventDetail } from "../../api/baseApi";
import { checkPirikaWebApiResult } from "../saga-support";

function* openEventSaga(action: Action<OpenEventParameters>) {
  const { payload } = action;
  if (!payload) {
    return;
  }

  if (payload.event.isPublic) {
    yield put(
      openNewWindow({
        url: `${Constants.snsServiceUrl}/ev/${payload.event.eventId}`,
      }),
    );
  } else {
    yield put(loadEvent({ event: payload.event }));
  }
}

function* loadEventSaga(action: Action<LoadEventParameters>) {
  const params = action.payload;
  if (!params) {
    return;
  }
  const progressAction = loadEventProgress;
  yield put(progressAction.started(params));

  const result = (yield call([Api, Api.getEventDetailAsync], params.event.eventId)) as ApiEventDetail;
  yield all(
    checkPirikaWebApiResult(
      result,
      (payload: ApiEventDetail) => progressAction.done({ params, result: payload }),
      () => progressAction.failed({ params, error: new Error() }),
    ),
  );
}

export default function* watch(): SagaIterator {
  yield takeEvery(openEvent.type, openEventSaga);
  yield takeEvery(loadEvent.type, loadEventSaga);
}

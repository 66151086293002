import * as React from "react";
import { WithStyles, withStyles } from "@material-ui/core";
import { withTranslation, WithTranslation } from "react-i18next";
import styles from "./EventRegisterModalLocationInputStyles";

interface Props extends WithStyles<typeof styles>, WithTranslation {
  place: string;
  targetPlace: string;
}

const EventRegisterModalLocationInput: React.FC<Props> = ({ classes, place, targetPlace }) => {
  const matchGovernment = ((): boolean => {
    if (!place) {
      return false;
    }
    return targetPlace.length > 0 && place.startsWith(targetPlace);
  })();
  return (
    <div className={classes.root}>
      {!matchGovernment && (
        <div className={classes.errorMessage}>{targetPlace}で開催されるイベントのみ登録できます</div>
      )}
    </div>
  );
};

export default withTranslation()(withStyles(styles)(EventRegisterModalLocationInput));

import * as React from "react";
import { WithStyles, withStyles } from "@material-ui/core";
import { withTranslation, WithTranslation } from "react-i18next";
import styles from "./UserIconDisplayStyles";
import { UserListEntry } from "../../../../modules/userListComponents";

interface Props extends WithStyles<typeof styles>, WithTranslation {
  user: UserListEntry;
}

const UserIconDisplay: React.FC<Props> = ({ user, classes }) => (
  <div className={classes.user}>
    <img className={classes.userImage} width="38" src={user.image} alt={user.name} />
    <div className={classes.userName}>{user.name}</div>
  </div>
);
export default withTranslation()(withStyles(styles)(UserIconDisplay));

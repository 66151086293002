import React, { Suspense } from "react";
import { ConnectedRouter } from "connected-react-router";
import { Provider } from "react-redux";
import createStore, { history } from "./configureStore";
import RootContainer from "./containers/RootContainer";
import Router from "./containers/Router";

const store = createStore();

const App = (): JSX.Element => (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Suspense fallback="">
        <RootContainer>
          <Router />
        </RootContainer>
      </Suspense>
    </ConnectedRouter>
  </Provider>
);

export default App;

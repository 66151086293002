import * as React from "react";
import { WithStyles, withStyles } from "@material-ui/core";
import { withTranslation, WithTranslation } from "react-i18next";
import styles from "./AreaEventsComponentStyles";
import { AreaEventsPageComponent } from "../../../api/odpApi";
import WidgetGridItem from "./WidgetGridItem";
import ComponentLoadingStateView from "./parts/ComponentLoadingStateView";
import ComponentErrorStateView from "./parts/ComponentErrorStateView";
import { AreaEvent } from "../../../modules/areaEventsComponents";
import ComponentContents from "./parts/ComponentContents";
import ComponentTitle from "./parts/ComponentTitle";
import EventItemView from "./parts/EventItemView";
import ComponentFrame from "./parts/ComponentFrame";
import { LoadingState } from "../../../modules/models";

interface Props extends WithStyles<typeof styles>, WithTranslation {
  component: AreaEventsPageComponent;
  loadingState: LoadingState;
  events: AreaEvent[];
  onOpenEvent: (areaEvent: AreaEvent) => void;
}

const AreaEventsComponent: React.FC<Props> = ({ component, loadingState, events, onOpenEvent, classes }) => (
  <WidgetGridItem component={component}>
    <ComponentFrame className={classes.root}>
      {loadingState === LoadingState.Initial && (
        <ComponentContents>
          <ComponentTitle
            accessoryLinkTitle={component.config.accessoryLinkTitle}
            accessoryLinkTarget={component.config.accessoryLinkTarget}
            accessoryTooltipText={component.config.accessoryTooltipText}
          >
            {component.title}
          </ComponentTitle>
          <div className={classes.items}>
            {events.length > 0 &&
              events.map((v) => <EventItemView key={v.eventId} event={v} onOpen={() => onOpenEvent(v)} />)}
          </div>
          {events.length === 0 && <div className={classes.emptyState}>予定されているイベントはありません</div>}
        </ComponentContents>
      )}
      {loadingState === LoadingState.Loading && <ComponentLoadingStateView />}
      {loadingState === LoadingState.Error && <ComponentErrorStateView />}
    </ComponentFrame>
  </WidgetGridItem>
);

export default withTranslation()(withStyles(styles)(AreaEventsComponent));

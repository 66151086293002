import actionCreatorFactory from "typescript-fsa";
import { reducerWithInitialState } from "typescript-fsa-reducers";
import {
  ApiDatabox,
  ApiPopulationData,
  ApiPrefectureRegion,
  ApiRegionActivities,
  ApiRegionPolygon,
  ApiRegionPolygonCounters,
} from "../../api/baseApi";
import { DataboxQueryParameter, GeoJson, LoadingState, PirikaPost } from "../models";
import { mergePosts } from "../functions";
import { OpenDataPageState } from "../openDataPage";

// 任意区域分割とほぼ同一コードだが、任意区域分割と両立させたいというリクエストが来ることを考慮している。

// models
// ----------------------------------------

export type PrefectureRegionPolygon = ApiRegionPolygon;
export type PopulationData = ApiPopulationData;
export type PrefectureRegionPolygonCounters = ApiRegionPolygonCounters;

export interface PrefectureRegion {
  available: boolean;
  geojsonUrl: string;
  topojsonUrl: string;
  polygons: PrefectureRegionPolygon[];
  populations: PopulationData[];
}

export interface RelativePrefectureRegionAggregateMode {
  valueType: "participants" | "trash";
  term: number | undefined;
}

// State
// ----------------------------------------

export interface PrefectureRegionDataboxState {
  loadingState: LoadingState;
  posts?: PirikaPost[];
  isTerminated: boolean;
}

const EmptyPrefectureRegionDataboxState: PrefectureRegionDataboxState = {
  loadingState: LoadingState.Initial,
  isTerminated: false,
};

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface PrefectureRegionGraphComponentState {}

// noinspection JSUnusedGlobalSymbols
export const EmptyPrefectureRegionGraphComponentState: PrefectureRegionGraphComponentState = {};

export interface PrefectureRegionComponentsState {
  regionLoadingState: LoadingState;
  region?: PrefectureRegion;
  countersLoadingState: LoadingState;
  counters?: Record<string, PrefectureRegionPolygonCounters>;
  populations?: Record<string, PopulationData>;
  graphComponents: Record<string, PrefectureRegionGraphComponentState>;
  geoJson?: GeoJson;
  databoxes: Record<string, PrefectureRegionDataboxState>;
  relativeRegionAggregateMode: RelativePrefectureRegionAggregateMode;
}

const EmptyPrefectureRegionComponentsState: PrefectureRegionComponentsState = {
  regionLoadingState: LoadingState.Initial,
  countersLoadingState: LoadingState.Initial,
  graphComponents: {},
  databoxes: {},
  relativeRegionAggregateMode: {
    valueType: "trash",
    term: 3,
  },
};

const initialState = EmptyPrefectureRegionComponentsState;

// Parameters
// ----------------------------------------

export interface LoadPrefectureRegionParameters {
  organizationId: number;
  pageId: number;
}

export interface LoadPrefectureRegionGeoJsonParameters {
  topojsonUrl: string;
}

export interface LoadPrefectureRegionDataboxParameters {
  regionId: string;
  organizationId: number;
  pageId: number;
  parameter: DataboxQueryParameter;
}

export interface UpdateRelativePrefectureRegionAggregateModeParameters {
  relativeRegionAggregateMode: RelativePrefectureRegionAggregateMode;
}

// Selectors
// ----------------------------------------

/**
 * ページのコンフィグの中に都道府県区域分割を参照したものが存在するか判定する
 */
export const hasPrefectureRegionDataComponent = (state: { openDataPage: OpenDataPageState }): boolean => {
  const components = state.openDataPage.pageConfigurations?.components || [];
  return (
    components.find((v) => {
      if (v.type === "map") {
        return Boolean(v.config.regionDrawType) && v.config.regionDrawType === "prefecture";
      }
      return false;
    }) !== undefined
  );
};

// Support Functions
// ----------------------------------------

/* 使用するようになったらコメントアウト解除
const lookupGraphComponent = (
  state: RegionComponentsState,
  id: string
): [RegionComponentsState, RegionGraphComponentState] => {
  if (!state.graphComponents[id]) {
    const newComponent = { ...EmptyRegionGraphComponentState };
    const newComponents = { ...state.graphComponents };
    newComponents[id] = newComponent;
    return [{ ...state, graphComponents: newComponents }, newComponent];
  }
  return [state, state.graphComponents[id]];
};

const updateComponent = (
  state: RegionComponentsState,
  id: string,
  component: RegionGraphComponentState
): RegionComponentsState => {
  const newComponents = { ...state.graphComponents };
  newComponents[id] = component;
  return { ...state, graphComponents: newComponents };
};
 */

/**
 * Counter のリストから PolygonId -> PolygonCounter のマップを作成する
 * @param result
 */
const makeRegionCounterDictionary = (result: ApiRegionActivities): Record<string, PrefectureRegionPolygonCounters> =>
  result.counters.reduce((prev, value) => {
    const next = prev;
    next[value.polygonId] = value;
    return next;
  }, {} as Record<string, PrefectureRegionPolygonCounters>);

/**
 * PopulationData のリストから PolygonId -> PopulationData のマップを作成する
 * @param result
 */
const makePopulationDataDictionary = (result: ApiPrefectureRegion): Record<string, PopulationData> =>
  result.populations.reduce((prev, value) => {
    const next = prev;
    next[`${value.prefectureName}${value.cityName}`] = value;
    return next;
  }, {} as Record<string, PopulationData>);

const lookupDatabox = (
  state: PrefectureRegionComponentsState,
  regionId: string,
): [PrefectureRegionComponentsState, PrefectureRegionDataboxState] => {
  const id = regionId;
  if (!state.databoxes[id]) {
    const newDatabox = { ...EmptyPrefectureRegionDataboxState };
    const newDataboxes = { ...state.databoxes };
    newDataboxes[id] = { ...EmptyPrefectureRegionDataboxState };
    return [{ ...state, databoxes: newDataboxes }, newDatabox];
  }
  return [state, state.databoxes[id]];
};

const updateDatabox = (
  state: PrefectureRegionComponentsState,
  regionId: string,
  databox: PrefectureRegionDataboxState,
): PrefectureRegionComponentsState => {
  const id = regionId;
  const newDataboxes = { ...state.databoxes };
  newDataboxes[id] = databox;
  return { ...state, databoxes: newDataboxes };
};

// ActionCreators
// ----------------------------------------

const actionCreator = actionCreatorFactory("PirikaOdp/PrefectureRegionComponents");
export const loadPrefectureRegion = actionCreator<LoadPrefectureRegionParameters>("LoadRegion");
export const loadPrefectureRegionProgress = actionCreator.async<
  LoadPrefectureRegionParameters,
  ApiPrefectureRegion,
  Error
>("LoadRegionProgress");
export const loadPrefectureRegionActivities = actionCreator<LoadPrefectureRegionParameters>("LoadRegionActivities");
export const loadPrefectureRegionActivitiesProgress = actionCreator.async<
  LoadPrefectureRegionParameters,
  ApiRegionActivities,
  Error
>("LoadRegionActivitiesProgress");
export const loadPrefectureRegionGeoJson = actionCreator<LoadPrefectureRegionGeoJsonParameters>("LoadRegionGeoJson");
export const loadPrefectureRegionGeoJsonProgress = actionCreator.async<
  LoadPrefectureRegionGeoJsonParameters,
  GeoJson,
  Error
>("LoadRegionGeoJsonProgress");
export const loadPrefectureRegionDataboxAction = actionCreator<LoadPrefectureRegionDataboxParameters>("LoadDatabox");
export const loadPrefectureRegionDataboxActionProgress = actionCreator.async<
  LoadPrefectureRegionDataboxParameters,
  ApiDatabox,
  Error
>("LoadDataboxProgress");
export const updateRelativePrefectureRegionAggregateModeAction =
  actionCreator<UpdateRelativePrefectureRegionAggregateModeParameters>("UpdateRelativePrefectureRegionAggregateMode");

// Reducer
// ----------------------------------------

const reducer = reducerWithInitialState(initialState)
  .case(loadPrefectureRegion, (state) => ({
    ...state,
    regionLoadingState: LoadingState.Loading,
  }))
  .case(loadPrefectureRegionProgress.started, (state) => ({
    ...state,
    regionLoadingState: LoadingState.Loading,
  }))
  .case(loadPrefectureRegionProgress.done, (state, { result }) => ({
    ...state,
    regionLoadingState: LoadingState.Initial,
    region: result,
    populations: makePopulationDataDictionary(result),
  }))
  .case(loadPrefectureRegionProgress.failed, (state) => ({
    ...state,
    regionLoadingState: LoadingState.Error,
  }))
  .case(loadPrefectureRegionActivitiesProgress.started, (state) => ({
    ...state,
    countersLoadingState: LoadingState.Loading,
  }))
  .case(loadPrefectureRegionActivitiesProgress.done, (state, { result }) => ({
    ...state,
    countersLoadingState: LoadingState.Initial,
    counters: makeRegionCounterDictionary(result),
  }))
  .case(loadPrefectureRegionActivitiesProgress.failed, (state) => ({
    ...state,
    countersLoadingState: LoadingState.Error,
  }))
  .case(loadPrefectureRegionGeoJsonProgress.done, (state, { result }) => ({
    ...state,
    geoJson: result,
  }))
  .case(loadPrefectureRegionDataboxActionProgress.started, (state, { regionId }) => {
    const [newState, databox] = lookupDatabox(state, regionId);
    return updateDatabox(newState, regionId, {
      ...databox,
      loadingState: LoadingState.Loading,
    });
  })
  .case(loadPrefectureRegionDataboxActionProgress.done, (state, { params, result }) => {
    const [newState, databox] = lookupDatabox(state, params.regionId);
    const byArea = params.parameter.latlng !== undefined && params.parameter.zoomLevel !== undefined;
    return updateDatabox(newState, params.regionId, {
      ...databox,
      loadingState: LoadingState.Initial,
      posts: mergePosts(databox.posts ?? [], result.info),
      isTerminated: databox.isTerminated || (!byArea && result.info.length === 0),
    });
  })
  .case(loadPrefectureRegionDataboxActionProgress.failed, (state, { params }) => {
    const [newState, databox] = lookupDatabox(state, params.regionId);
    return updateDatabox(newState, params.regionId, {
      ...databox,
      loadingState: LoadingState.Error,
    });
  })
  .case(updateRelativePrefectureRegionAggregateModeAction, (state, { relativeRegionAggregateMode }) => ({
    ...state,
    relativeRegionAggregateMode,
  }));

export default reducer;

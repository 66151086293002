import * as React from "react";
import { WithStyles, withStyles } from "@material-ui/core";
import { withTranslation, WithTranslation } from "react-i18next";
import styles from "./RegionGraphComponentStyles";
import ComponentContents from "./parts/ComponentContents";
import ComponentTitle from "./parts/ComponentTitle";
import ComponentLoadingStateView from "./parts/ComponentLoadingStateView";
import ComponentErrorStateView from "./parts/ComponentErrorStateView";
import { RegionGraphPageComponent } from "../../../api/odpApi";
import WidgetGridItem from "./WidgetGridItem";
import ComponentFrame from "./parts/ComponentFrame";
import { LoadingState } from "../../../modules/models";

interface Props extends WithStyles<typeof styles>, WithTranslation {
  component: RegionGraphPageComponent;
  loadingState: LoadingState;
}

const RegionGraphComponent: React.FC<Props> = ({ component, loadingState, classes, children }) => (
  <WidgetGridItem component={component}>
    <ComponentFrame className={classes.root}>
      {loadingState === LoadingState.Initial && (
        <ComponentContents>
          <ComponentTitle
            accessoryLinkTitle={component.config.accessoryLinkTitle}
            accessoryLinkTarget={component.config.accessoryLinkTarget}
            accessoryTooltipText={component.config.accessoryTooltipText}
          >
            {component.title}
          </ComponentTitle>
          {children}
        </ComponentContents>
      )}
      {loadingState === LoadingState.Loading && <ComponentLoadingStateView />}
      {loadingState === LoadingState.Error && <ComponentErrorStateView />}
    </ComponentFrame>
  </WidgetGridItem>
);

export default withTranslation()(withStyles(styles)(RegionGraphComponent));

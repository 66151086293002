import * as React from "react";
import { IconButton, WithStyles, withStyles } from "@material-ui/core";
import { withTranslation, WithTranslation } from "react-i18next";
import CloseIcon from "@material-ui/icons/Close";
import classNames from "classnames";
import styles from "./TimelineHeaderStyles";
import { getContrastedColor } from "../../../functions";

interface Props extends WithStyles<typeof styles>, WithTranslation {
  open: boolean;
  regionName: string;
  backgroundColor: string;
  showParticipants: boolean;
  showTrashes: boolean;
  participants?: number;
  trashes?: number;
  term?: number;
  onClose: () => void;
}

const TimelineHeader: React.FC<Props> = ({
  open,
  regionName,
  backgroundColor,
  onClose,
  participants,
  trashes,
  showParticipants,
  showTrashes,
  term,
  classes,
}) => {
  if (!open) {
    return null;
  }
  const color = getContrastedColor(backgroundColor);
  const termString = term === undefined ? " (全期間)" : ` (${term}ヶ月)`;
  return (
    <div className={classNames(classes.root)} style={{ backgroundColor, color }}>
      <div className={classes.closeButtonContainer}>
        <IconButton size="small" onClick={onClose}>
          <CloseIcon style={{ color }} />
        </IconButton>
      </div>
      <div className={classes.informationContainer}>
        <div className={classes.areaName}>{regionName}</div>
        {showParticipants && (
          <div className={classes.counterContainer}>
            <div className={classes.counterTitle}>のべ参加人数{termString}</div>
            <div className={classes.counterContent}>{participants?.toLocaleString()}</div>
          </div>
        )}
        {showTrashes && (
          <div className={classes.counterContainer}>
            <div className={classes.counterTitle}>ごみの数{termString}</div>
            <div className={classes.counterContent}>{trashes?.toLocaleString()}</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default withTranslation()(withStyles(styles)(TimelineHeader));

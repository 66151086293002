import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { RouterLocation } from "connected-react-router";
import { Formik } from "formik";
import * as Yup from "yup";
import { RootState } from "../../../modules";
import {
  EventRegisterApplication,
  EventRegisterResponseMessage,
  handleModal as handleModalAction,
  registerEvent as registerEventAction,
} from "../../../modules/eventRegisterModal";
import { history } from "../../../configureStore";
import EventRegisterModal from "../../../components/dashboard/eventRegister/EventRegisterModal";
import EventRegisterModalImageSetContainer from "./EventRegisterModalImageSetContainer";
import EventRegisterModalLocationInputContainer from "./EventRegisterModalLocationInputContainer";
import EventRegisterModalRecaptchaContainer from "./EventRegisterModalRecaptchaContainer";
import { LoadingState } from "../../../modules/models";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ExportProps {}

interface StateProps {
  modalOpen: boolean;
  location: RouterLocation<unknown>;
  eventRegisterDescription?: string;
  submittingLoadingState: LoadingState;
  responseMessage?: EventRegisterResponseMessage;
}

interface DispatchProps {
  handleEventRegisterModal: (next: boolean) => void;
  registerEvent: (params: EventRegisterApplication) => void;
}

type Props = ExportProps & StateProps & DispatchProps;

class EventRegisterModalContainer extends React.Component<Props> {
  private eventRegisterLocation = "/events/register";

  private initialValues: EventRegisterApplication = {
    contactName: "",
    contactEmail: "",
    contactPhone: undefined,
    contactOther: undefined,
    title: "",
    comment: "",
    startDate: new Date(),
    endDate: new Date(),
    authorName: "",
    photoFile: [],
    lat: 0,
    lng: 0,
    place: "",
    recaptchaVerified: false,
  };

  // TODO(Niccari): i18n in its component
  private validationSchema = Yup.object().shape({
    contactName: Yup.string().required("申請者氏名を入力してください"),
    contactEmail: Yup.string().email("正しいメールアドレスを入力してください"),
    contactPhone: Yup.string()
      .trim()
      .matches(/^[0-9]{10,11}$/, "固定番号もしくは携帯番号を入れてください"),
    title: Yup.string().required("イベント名を入力してください"),
    startDate: Yup.date().max(Yup.ref("endDate"), "開始日時は終了日時より前にしてください"),
    endDate: Yup.date().min(Yup.ref("startDate"), "終了日時は開始日時より後にしてください"),
    comment: Yup.string().required("イベントの説明を入力してください"),
    authorName: Yup.string().required("イベントの開催者名を入力してください"),
    photoFile: Yup.array().min(1, "イベントの画像を指定してください"),
    recaptchaVerified: Yup.boolean().isTrue("reCAPTCHAで認証してください"),
  });

  public componentDidMount() {
    const { location, handleEventRegisterModal } = this.props;
    if (location.pathname === this.eventRegisterLocation) {
      handleEventRegisterModal(true);
    }
  }

  public componentDidUpdate() {
    const { modalOpen, location, handleEventRegisterModal } = this.props;
    if (!modalOpen && location.pathname === this.eventRegisterLocation) {
      handleEventRegisterModal(true);
    } else if (modalOpen && location.pathname !== this.eventRegisterLocation) {
      handleEventRegisterModal(false);
    }
  }

  private onClose = () => {
    const { handleEventRegisterModal } = this.props;
    handleEventRegisterModal(false);
    history.push("/");
  };

  private onSubmit = (values: EventRegisterApplication) => {
    const { registerEvent } = this.props;
    registerEvent(values);
  };

  public render(): JSX.Element {
    const { modalOpen, submittingLoadingState, responseMessage, eventRegisterDescription } = this.props;
    return (
      <Formik
        initialValues={this.initialValues}
        onSubmit={(values) => this.onSubmit(values)}
        validationSchema={this.validationSchema}
        validateOnMount
      >
        {(formikProps) => (
          <EventRegisterModal
            modalOpen={modalOpen}
            onClose={this.onClose}
            submittingLoadingState={submittingLoadingState}
            eventRegisterDescription={eventRegisterDescription}
            responseMessage={responseMessage}
            formikProps={formikProps}
          >
            <EventRegisterModalImageSetContainer formikProps={formikProps} />
            <EventRegisterModalLocationInputContainer formikProps={formikProps} />
            <EventRegisterModalRecaptchaContainer formikProps={formikProps} />
          </EventRegisterModal>
        )}
      </Formik>
    );
  }
}

const mapStateToProps = (state: RootState): StateProps => ({
  modalOpen: state.eventRegisterModal.modalOpen,
  eventRegisterDescription: state.openDataPage.pageConfigurations?.config?.eventRegisterDescription,
  location: state.router.location,
  submittingLoadingState: state.eventRegisterModal.submittingLoadingState,
  responseMessage: state.eventRegisterModal.responseMessage,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  handleEventRegisterModal: (next) => dispatch(handleModalAction(next)),
  registerEvent: (params) => dispatch(registerEventAction(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EventRegisterModalContainer);

import * as React from "react";
import { createTheme as createMuiTheme, Theme, WithStyles, withStyles, ThemeProvider } from "@material-ui/core";
import { withTranslation, WithTranslation } from "react-i18next";
import { red } from "@material-ui/core/colors";
import classNames from "classnames";
import styles from "./RootStyles";

interface Props extends WithStyles<typeof styles>, WithTranslation {
  children?: React.ReactNode;
  themeColor?: string;
  isWidgetMode?: boolean;
}

const createTheme = (color?: string): Theme =>
  createMuiTheme({
    palette: {
      primary: {
        main: color ?? "#1470CC",
      },
      secondary: {
        main: red.A100,
      },
    },
    // MEMO: デフォルト値だけど明示しておく
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1920,
      },
    },
  });

const Root: React.FC<Props> = ({ children, themeColor, isWidgetMode, classes }) => (
  <ThemeProvider theme={createTheme(themeColor)}>
    <div className={classNames(classes.root, isWidgetMode && classes.isWidget)}>{children}</div>
  </ThemeProvider>
);

export default withTranslation()(withStyles(styles)(Root));

import { createStyles, StyleRules } from "@material-ui/core";

const styles = (/* theme: Theme */): StyleRules =>
  createStyles({
    root: {},
    userLink: {
      display: "block",
    },
    iconLink: {
      color: "#1470cc",
    },
    listLink: {
      color: "#1470cc",
    },
  });

export default styles;

import { createStyles, StyleRules, Theme } from "@material-ui/core";

const baseSize = "72px";

const styles = (theme: Theme): StyleRules =>
  createStyles({
    root: {
      overflow: "auto",
      flexGrow: 1,
    },
    section: {
      height: "100%",
    },
    ul: {
      overflow: "auto",
      margin: 0,
      padding: 0,
    },
    timelineItem: {
      borderBottom: "1px solid #d1ccc3",
      cursor: "pointer",
      padding: theme.spacing(0.5),
    },
    timelineBox: {
      display: "flex",
      height: baseSize,
    },
    timelineThumbnailContainer: {
      width: baseSize,
      overflow: "hidden",
      flexShrink: 0,
    },
    timelineThumbnail: {
      height: baseSize,
      width: baseSize,
      objectFit: "cover",
    },
    timelineInformationContainer: {
      paddingLeft: theme.spacing(1),
      flexGrow: 1,
      overflow: "hidden",
    },
    timelineAbstractContainer: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      minWidth: 0,
      alignItems: "baseline",
    },
    timelineUserImageContainer: {
      paddingRight: theme.spacing(1),
    },
    timelineUserImage: {
      height: "20px",
      width: "20px",
      objectFit: "cover",
      borderRadius: "10px",
      verticalAlign: "-4px",
    },
    timelineUserName: {
      flexGrow: 1,
      fontSize: "14px",
      fontWeight: "bold",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      flex: "1 1 auto",
      minWidth: 0,
    },
    timelineDate: {
      fontSize: "12px",
      color: "#5f5f5f",
      whiteSpace: "nowrap",
      marginLeft: theme.spacing(0.5),
    },
    timelineComment: {
      fontSize: "12px",
      lineHeight: "15px",
      paddingTop: theme.spacing(0.5),
    },
    loadMoreButton: {
      width: "100%",
      height: "54px",
      lineHeight: "1",
      background: "none",
      border: 0,
      textAlign: "center",
      fontSize: "14px",
      color: "#212121", // TODO: 全体で定数化したい
    },
  });

export default styles;

import { createStyles, StyleRules, Theme } from "@material-ui/core";

const styles = (theme: Theme): StyleRules =>
  createStyles({
    root: {
      paddingTop: theme.spacing(1),
      "&:first-child": {
        paddingTop: theme.spacing(0),
      },
    },
    item: {
      display: "flex",
      borderBottom: "1px solid #e2e2e2",
      paddingBottom: theme.spacing(1),
      alignItems: "flex-start",
      cursor: "pointer",
    },
    itemLink: {
      display: "block",
      textDecoration: "none",
      color: "inherit",
    },
    itemAbstractContainer: {
      flexGrow: 1,
    },
    itemDate: {
      fontSize: "12px",
      marginBottom: theme.spacing(1),
      color: "#5f5f5f",
    },
    itemTitle: {
      fontSize: "14px",
      marginBottom: theme.spacing(1),
    },
    itemPlace: {
      fontSize: "12px",
      color: "#5f5f5f",
    },
    itemPlaceIcon: {
      width: "14px",
      height: "14px",
      verticalAlign: "-3px",
      marginRight: "2px",
    },
    itemImageContainer: {
      height: "70px",
    },
    itemImage: {
      backgroundColor: "#e2e2e2",
      width: "70px",
      height: "70px",
    },
  });
export default styles;

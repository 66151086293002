import * as React from "react";
import { Button, WithStyles, withStyles } from "@material-ui/core";
import { withTranslation, WithTranslation } from "react-i18next";
import styles from "./MapBalloonStyles";
import PlaceholderImage from "../../../../assets/placeholder.png";
import { PirikaPost } from "../../../../modules/models";

interface Props extends WithStyles<typeof styles>, WithTranslation {
  post: PirikaPost;
  // (Google Maps から使用している)
  // eslint-disable-next-line react/no-unused-prop-types
  lat: number;
  // (Google Maps から使用している)
  // eslint-disable-next-line react/no-unused-prop-types
  lng: number;
  onDetailClick: () => void;
}

const MapBalloon: React.FC<Props> = ({ post, classes, onDetailClick }) => (
  <div className={classes.root}>
    <div className={classes.contents}>
      <div className={classes.postContainer}>
        <div className={classes.abstractContainer}>
          <div className={classes.userImageContainer}>
            <img className={classes.userImage} src={post.profileImageUrl} alt={post.userName} />
          </div>
          <div className={classes.abstractTextContainer}>
            <div className={classes.userName}>{post.userName}</div>
            <div>{post.prettyDate}</div>
          </div>
        </div>
        <div className={classes.bodyContainer}>
          <div className={classes.thumbnailContainer}>
            <img className={classes.thumbnail} src={post.thumbnailUrl[0] || PlaceholderImage} alt={`${post.id}`} />
          </div>
          <div className={classes.comment}>{post.comment}</div>
        </div>
        <div className={classes.actionContainer}>
          <Button variant="contained" onClick={onDetailClick}>
            ピリカで詳細を見る
          </Button>
        </div>
      </div>
    </div>
    <div className={classes.tail} />
  </div>
);

export default withTranslation()(withStyles(styles)(MapBalloon));

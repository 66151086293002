import { createStyles, StyleRules } from "@material-ui/core";

const styles = (/* theme: Theme */): StyleRules =>
  createStyles({
    root: {},
    contents: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
      border: "1px solid blue",
      padding: "4px",
    },
  });
export default styles;

import { SagaIterator } from "redux-saga";
import { all, call, put, takeEvery } from "redux-saga/effects";
import { Action } from "typescript-fsa";
import { loadUserList, LoadUserListParameters, loadUserListProgress } from "./index";
import Api, { ApiUserList } from "../../api/baseApi";
import { checkPirikaWebApiResult } from "../saga-support";

function* loadUserListSaga(action: Action<LoadUserListParameters>) {
  const params = action.payload;
  const progressAction = loadUserListProgress;
  yield put(progressAction.started(params));

  const result = (yield call(
    [Api, Api.getUserListAsync],
    params.organizationId,
    params.pageId,
    params.userListId,
  )) as ApiUserList;
  yield all(
    checkPirikaWebApiResult(
      result,
      (payload: ApiUserList) => progressAction.done({ params, result: payload }),
      () => progressAction.failed({ params, error: new Error() }),
    ),
  );
}

export default function* watch(): SagaIterator {
  yield takeEvery(loadUserList.type, loadUserListSaga);
}

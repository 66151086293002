import * as React from "react";
import { WithStyles, withStyles } from "@material-ui/core";
import { withTranslation, WithTranslation } from "react-i18next";
import { format } from "date-fns";
import InfiniteScroll from "react-infinite-scroller";
import styles from "./InformationComponentStyles";
import WidgetGridItem from "./WidgetGridItem";
import { InformationPageComponent } from "../../../api/odpApi";
import { InformationItem } from "../../../modules/informationComponents";
import ComponentLoadingStateView from "./parts/ComponentLoadingStateView";
import ComponentErrorStateView from "./parts/ComponentErrorStateView";
import ComponentContents from "./parts/ComponentContents";
import ComponentTitle from "./parts/ComponentTitle";
import ComponentFrame from "./parts/ComponentFrame";
import Nl2Br from "./parts/Nl2Br";
import { LoadingState } from "../../../modules/models";
import ListLoadingState from "../../loading/ListLoadingState";
import useMediaQuery from "../useMediaQuery";

interface Props extends WithStyles<typeof styles>, WithTranslation {
  component: InformationPageComponent;
  loadingState: LoadingState;
  information: InformationItem[];
  hasMore: boolean;
  onMore: () => void;
}

const InformationComponent: React.FC<Props> = ({ component, loadingState, information, hasMore, onMore, classes }) => {
  const { isS } = useMediaQuery();
  const contents = (
    <div className={classes.items}>
      {information.length > 0 &&
        information.map((v) => (
          <div className={classes.item} key={v.id}>
            <h4 className={classes.itemTitle}>
              {v.url && (
                <a className={classes.itemLink} href={v.url} target="_blank" rel="noopener noreferrer">
                  {v.title}
                </a>
              )}
              {!v.url ? v.title : ""}
            </h4>
            <div className={classes.itemDetail}>
              <Nl2Br text={v.detail} />
            </div>
            <div className={classes.itemFooter}>
              {format(v.date, "yyyy/MM/dd")} {v.author}
            </div>
          </div>
        ))}
      {information.length === 0 && <div className={classes.emptyState}>お知らせはありません</div>}
    </div>
  );
  return (
    <WidgetGridItem component={component}>
      <ComponentFrame className={classes.root}>
        {/* ロードが完了している場合、もしくはローディング中であってもすでにいくらかは取得済みの場合 */}
        {(loadingState === LoadingState.Initial ||
          (loadingState === LoadingState.Loading && information.length > 0)) && (
          <ComponentContents>
            <ComponentTitle
              accessoryLinkTitle={component.config.accessoryLinkTitle}
              accessoryLinkTarget={component.config.accessoryLinkTarget}
              accessoryTooltipText={component.config.accessoryTooltipText}
            >
              {component.title}
            </ComponentTitle>
            {isS.matches ? (
              <section>
                {contents}
                <button className={classes.loadMoreButton} type="button" onClick={onMore}>
                  {loadingState === LoadingState.Initial && hasMore && <span>もっと読む</span>}
                  {loadingState === LoadingState.Loading && <ListLoadingState key="Loader" />}
                  {loadingState === LoadingState.Error && <span>ページを再読み込みしてください</span>}
                </button>
              </section>
            ) : (
              <div className={classes.container}>
                <InfiniteScroll
                  pageStart={0}
                  loadMore={onMore}
                  hasMore={hasMore}
                  useWindow={false}
                  threshold={20}
                  loader={<ListLoadingState key="Loader" />}
                >
                  {contents}
                </InfiniteScroll>
              </div>
            )}
          </ComponentContents>
        )}
        {loadingState === LoadingState.Loading && information.length === 0 && <ComponentLoadingStateView />}
        {loadingState === LoadingState.Error && <ComponentErrorStateView />}
      </ComponentFrame>
    </WidgetGridItem>
  );
};

export default withTranslation()(withStyles(styles)(InformationComponent));

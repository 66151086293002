import * as React from "react";
import { WithStyles, withStyles } from "@material-ui/core";
import { withTranslation, WithTranslation } from "react-i18next";
import styles from "./OrganizationsComponentStyles";
import { OrganizationsPageComponent } from "../../../api/odpApi";
import WidgetGridItem from "./WidgetGridItem";
import { CategorizedOrganizations, OrganizationMetadata } from "../../../modules/organizationsComponents";
import ComponentLoadingStateView from "./parts/ComponentLoadingStateView";
import ComponentErrorStateView from "./parts/ComponentErrorStateView";
import ComponentContents from "./parts/ComponentContents";
import ComponentTitle from "./parts/ComponentTitle";
import ComponentFrame from "./parts/ComponentFrame";
import Constants from "../../../constants";
import { LoadingState } from "../../../modules/models";

interface Props extends WithStyles<typeof styles>, WithTranslation {
  component: OrganizationsPageComponent;
  loadingState: LoadingState;
  organizations?: CategorizedOrganizations;
}

const organizationSection = (
  props: Props,
  title: string,
  organizations: OrganizationMetadata[] | undefined,
): JSX.Element | undefined => {
  const { classes } = props;
  if (!organizations || organizations.length === 0) {
    return undefined;
  }
  return (
    <div className={classes.section}>
      <h4 className={classes.sectionTitle}>{title}</h4>
      <ul className={classes.list}>
        {organizations.map((v) => (
          <li key={v.id} className={classes.listItem}>
            <a
              className={classes.itemLink}
              href={`${Constants.snsServiceUrl}/u/${v.id}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {v.name}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

const OrganizationsComponent: React.FC<Props> = (props) => {
  const { component, loadingState, organizations, classes } = props;
  return (
    <WidgetGridItem component={component}>
      <ComponentFrame className={classes.root}>
        {loadingState === LoadingState.Initial && (
          <ComponentContents>
            <ComponentTitle
              accessoryLinkTitle={component.config.accessoryLinkTitle}
              accessoryLinkTarget={component.config.accessoryLinkTarget}
              accessoryTooltipText={component.config.accessoryTooltipText}
            >
              {component.title}
            </ComponentTitle>
            <div className={classes.frame}>
              {(organizations?.companies?.length ?? 0) !== 0 && (
                <div className={classes.frameSection}>
                  {organizationSection(props, "企業", organizations?.companies)}
                </div>
              )}
              <div className={classes.frameSection}>
                {organizationSection(props, "NPO、NGO、社団法人、財団法人など", organizations?.publicOrganizations)}
                {organizationSection(props, "学校、部活、生徒会など", organizations?.schools)}
                {organizationSection(props, "町内会、自治会、商店街など", organizations?.associations)}
                {organizationSection(props, "任意団体、サークルなど", organizations?.privateOrganizations)}
                {organizationSection(props, "行政・自治体など", organizations?.governments)}
                {organizationSection(props, "その他", organizations?.others)}
              </div>
            </div>
          </ComponentContents>
        )}
        {loadingState === LoadingState.Loading && <ComponentLoadingStateView />}
        {loadingState === LoadingState.Error && <ComponentErrorStateView />}
      </ComponentFrame>
    </WidgetGridItem>
  );
};

export default withTranslation()(withStyles(styles)(OrganizationsComponent));

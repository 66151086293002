import * as React from "react";
import { connect } from "react-redux";
import { FormikProps } from "formik";
import EventRegisterModalImageSet from "../../../components/dashboard/eventRegister/EventRegisterModalImageSet";
import { EventRegisterApplication } from "../../../modules/eventRegisterModal";

interface ExportProps {
  formikProps: FormikProps<EventRegisterApplication>;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface StateProps {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface DispatchProps {}

type Props = ExportProps & StateProps & DispatchProps;

interface State {
  imageDataUrl?: string;
}

class EventRegisterModalImageSetContainer extends React.Component<Props, State> {
  public constructor(props: Props, context: unknown) {
    super(props, context);
    this.state = {};
  }

  private onImageSet = (file: File): void => {
    const { formikProps } = this.props;
    const { setFieldValue } = formikProps;
    setFieldValue("photoFile", [file]);

    const fileReader = new FileReader();
    fileReader.onload = (e) => {
      const imageDataUrl = e?.target?.result;
      if (typeof imageDataUrl !== "string") {
        return;
      }
      this.setState({ imageDataUrl });
    };
    fileReader.readAsDataURL(file);
  };

  private onImageClear = (): void => {
    const { formikProps } = this.props;
    const { setFieldValue } = formikProps;
    setFieldValue("photoFile", []);

    this.setState({ imageDataUrl: undefined });
  };

  public render(): JSX.Element {
    const { imageDataUrl } = this.state;
    return (
      <EventRegisterModalImageSet
        imageDataUrl={imageDataUrl}
        onImageSet={this.onImageSet}
        onImageClear={this.onImageClear}
      />
    );
  }
}

const mapStateToProps = (/* state: RootState */): StateProps => ({});

const mapDispatchToProps = (/* dispatch: Dispatch */): DispatchProps => ({});

export default connect(mapStateToProps, mapDispatchToProps)(EventRegisterModalImageSetContainer);

import axios, { AxiosInstance } from "axios";
import JSZip from "jszip";
// eslint-disable-next-line import/no-unresolved
import { Topology } from "topojson-specification";
import { feature } from "topojson-client";
import { GeoJson, GeoJsonDownloader } from "./interface";

class GeoJsonDownloaderImplements implements GeoJsonDownloader {
  private axios: AxiosInstance;
  public constructor() {
    this.axios = axios.create();
  }

  public getTopoJsonZipAsync = async (url: string): Promise<GeoJson> => {
    const response = await this.axios.get<ArrayBuffer>(url, { responseType: "arraybuffer" });
    if (!response.data) {
      throw new Error("Request error");
    }
    const zip = await JSZip.loadAsync(response.data);
    const file = zip.file("topojson.topojson");
    if (!file) {
      throw new Error("Invalid Zip");
    }
    const topojsonContent = JSON.parse(await file.async("string")) as Topology;
    const firstObjectKey = Object.keys(topojsonContent.objects)[0];
    return feature(topojsonContent, topojsonContent.objects[firstObjectKey]);
  };
}

export default GeoJsonDownloaderImplements;

import actionCreatorFactory from "typescript-fsa";
import { reducerWithInitialState } from "typescript-fsa-reducers";
import { ApiGovernmentNonIndividuals, ApiUserMetadata } from "../../api/baseApi";
import { LoadingState } from "../models";

// models
// ----------------------------------------

export type OrganizationMetadata = ApiUserMetadata;

export interface CategorizedOrganizations {
  companies: OrganizationMetadata[];
  associations: OrganizationMetadata[];
  governments: OrganizationMetadata[];
  publicOrganizations: OrganizationMetadata[];
  privateOrganizations: OrganizationMetadata[];
  schools: OrganizationMetadata[];
  others: OrganizationMetadata[];
}

// State
// ----------------------------------------

export interface OrganizationsComponentState {
  loadingState: LoadingState;
  organizations?: CategorizedOrganizations;
}

const EmptyOrganizationsComponentState: OrganizationsComponentState = {
  loadingState: LoadingState.Initial,
};

export interface OrganizationsComponentsState {
  components: Record<string, OrganizationsComponentState>;
}

const initialState: OrganizationsComponentsState = {
  components: {},
};

// Parameters
// ----------------------------------------

export interface LoadOrganizationsParameters {
  componentId: string;
  organizationId: number;
  pageId: number;
  blockUserListId: number | undefined;
}

// Support Functions
// ----------------------------------------

const lookupComponent = (
  state: OrganizationsComponentsState,
  id: string,
): [OrganizationsComponentsState, OrganizationsComponentState] => {
  if (!state.components[id]) {
    const newComponent = { ...EmptyOrganizationsComponentState };
    const newComponents = { ...state.components };
    newComponents[id] = newComponent;
    return [{ ...state, components: newComponents }, newComponent];
  }
  return [state, state.components[id]];
};

const updateComponent = (
  state: OrganizationsComponentsState,
  id: string,
  component: OrganizationsComponentState,
): OrganizationsComponentsState => {
  const newComponents = { ...state.components };
  newComponents[id] = component;
  return { ...state, components: newComponents };
};

// Selectors
// ----------------------------------------

// ActionCreators
// ----------------------------------------

const actionCreator = actionCreatorFactory("PirikaOdp/OrganizationsComponents");

export const loadOrganizations = actionCreator<LoadOrganizationsParameters>("LoadOrganizations");
export const loadOrganizationsProgress = actionCreator.async<
  LoadOrganizationsParameters,
  ApiGovernmentNonIndividuals,
  Error
>("LoadOrganizationsProgress");

// Reducer
// ----------------------------------------

const reducer = reducerWithInitialState(initialState)
  .case(loadOrganizationsProgress.started, (state, { componentId }) => {
    const [newState, component] = lookupComponent(state, componentId);
    return updateComponent(newState, componentId, {
      ...component,
      loadingState: LoadingState.Loading,
    });
  })
  .case(loadOrganizationsProgress.done, (state, { params, result }) => {
    const [newState, component] = lookupComponent(state, params.componentId);
    return updateComponent(newState, params.componentId, {
      ...component,
      loadingState: LoadingState.Initial,
      organizations: {
        companies: result.companies,
        associations: result.associations,
        governments: result.governments,
        publicOrganizations: result.publicOrganizations,
        privateOrganizations: result.privateOrganizations,
        schools: result.schools,
        others: result.others,
      },
    });
  })
  .case(loadOrganizationsProgress.failed, (state, { params }) => {
    const [newState, component] = lookupComponent(state, params.componentId);
    return updateComponent(newState, params.componentId, {
      ...component,
      loadingState: LoadingState.Error,
    });
  });

export default reducer;

import actionCreatorFactory from "typescript-fsa";
import { reducerWithInitialState } from "typescript-fsa-reducers";
import { ApiInformation, ApiInformationItem, ApiInformationParameter } from "../../api/odpApi";
import { LoadingState } from "../models";

// models
// ----------------------------------------

export type InformationItem = ApiInformationItem;
export type InformationParameter = ApiInformationParameter;

// State
// ----------------------------------------

export interface InformationComponentState {
  loadingState: LoadingState;
  // お知らせ一覧
  information?: InformationItem[];
  // 全ての読み込みが完了したか
  isTerminated: boolean;
}

const EmptyInformationComponentState: InformationComponentState = {
  loadingState: LoadingState.Initial,
  isTerminated: false,
};

export interface InformationComponentsState {
  components: Record<string, InformationComponentState>;
}

const initialState: InformationComponentsState = {
  components: {},
};

// Parameters
// ----------------------------------------

export interface LoadInformationParameters {
  componentId: string;
  parameter: InformationParameter;
}

// Support Functions
// ----------------------------------------

/**
 * 指定されたIDを持つコンポーネントを状態から検索する。
 * もしコンポーネントが存在しない場合、新しいコンポーネントを作成し、状態に追加する。
 * @param state - 現在のInformationComponentsState
 * @param id - 検索するコンポーネントのID
 * @return 更新された状態と、検索されたまたは新しく作成されたコンポーネントのタプル
 */
const lookupComponent = (
  state: InformationComponentsState,
  id: string,
): [InformationComponentsState, InformationComponentState] => {
  if (!state.components[id]) {
    const newComponent = { ...EmptyInformationComponentState };
    const newComponents = { ...state.components };
    newComponents[id] = newComponent;
    return [{ ...state, components: newComponents }, newComponent];
  }
  return [state, state.components[id]];
};

const updateComponent = (
  state: InformationComponentsState,
  id: string,
  component: InformationComponentState,
): InformationComponentsState => {
  const newComponents = { ...state.components };
  newComponents[id] = component;
  return { ...state, components: newComponents };
};

/**
 * 二つのInformationItem配列をマージする。
 * 既存のアイテムは追加されず、新しいアイテムだけが結合される。
 * 最終的な結果は、availableFromプロパティに基づいて降順に並べ替えられる。
 *
 * @param base - ベースとなるInformationItemの配列
 * @param appendArray - 追加したいInformationItemの配列
 * @return マージされ、並べ替えられたInformationItemの配列
 */
const mergeInformation = (base: InformationItem[], appendArray: InformationItem[]): InformationItem[] => {
  const result: InformationItem[] = [...base];
  // 既に結果に存在しない場合のみ追加(基本的に重複はないはず)
  appendArray.forEach((append: InformationItem) => {
    if (result.find((v: InformationItem) => v.id === append.id) === undefined) {
      result.push(append);
    }
  });
  // availableFromプロパティに基づいて降順に並べ替え(表示の順位)
  result.sort((a: InformationItem, b: InformationItem) => b.availableFrom.getTime() - a.availableFrom.getTime());
  return result;
};

// Selectors
// ----------------------------------------

// ActionCreators
// ----------------------------------------

const actionCreator = actionCreatorFactory("PirikaOdp/InformationComponents");

export const loadInformation = actionCreator<LoadInformationParameters>("LoadInformation");
export const loadInformationProgress = actionCreator.async<LoadInformationParameters, ApiInformation, Error>(
  "LoadInformationProgress",
);

// Reducer
// ----------------------------------------

const reducer = reducerWithInitialState(initialState)
  .case(loadInformationProgress.started, (state, { componentId }) => {
    const [newState, component] = lookupComponent(state, componentId);
    return updateComponent(newState, componentId, {
      ...component,
      loadingState: LoadingState.Loading,
    });
  })
  .case(loadInformationProgress.done, (state, { params, result }) => {
    const [newState, component] = lookupComponent(state, params.componentId);
    return updateComponent(newState, params.componentId, {
      ...component,
      loadingState: LoadingState.Initial,
      information: mergeInformation(component.information || [], result.information),
      // 新規のお知らせが0件の場合は終了とみなす
      isTerminated: state.components[params.componentId].isTerminated || result.information.length === 0,
    });
  })
  .case(loadInformationProgress.failed, (state, { params }) => {
    const [newState, component] = lookupComponent(state, params.componentId);
    return updateComponent(newState, params.componentId, {
      ...component,
      loadingState: LoadingState.Error,
    });
  });

export default reducer;

import { createStyles, StyleRules, Theme } from "@material-ui/core";

const styles = (theme: Theme): StyleRules =>
  createStyles({
    root: {
      display: "flex",
      justifyContent: "left",
      [theme.breakpoints.down(600)]: {
        justifyContent: "center",
      },
    },
    logoContainer: {
      alignSelf: "center",
    },
    logo: {
      height: "100%",
      [theme.breakpoints.down(600)]: {
        maxHeight: "52px",
      },
    },
    titleContainer: {
      alignSelf: "center",
      paddingLeft: theme.spacing(1),
      paddingTop: "3px",
      paddingBottom: "5px",
      whiteSpace: "nowrap",
      [theme.breakpoints.down(960)]: {
        minWidth: 0,
      },
    },
    title: {
      margin: 0,
      fontSize: "22px",
      lineHeight: "28px",
      overflow: "hidden",
      textOverflow: "ellipsis",
      [theme.breakpoints.down(600)]: {
        fontSize: "18px",
        lineHeight: "20px",
      },
    },
    upperSubTitle: {
      fontSize: "14px",
      overflow: "hidden",
      textOverflow: "ellipsis",
      [theme.breakpoints.down(600)]: {
        fontSize: "12px",
      },
    },
    lowerSubTitle: {
      marginTop: "1px",
      fontSize: "14px",
      overflow: "hidden",
      textOverflow: "ellipsis",
      [theme.breakpoints.down(600)]: {
        fontSize: "12px",
      },
    },
    descriptionContainer: {
      fontSize: "12px",
      alignSelf: "center",
      flexGrow: 1,
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
    description: {},
    poweredLogoContainer: {
      alignSelf: "flex-end",
      "& svg": {
        width: "150px",
      },
    },
  });
export default styles;

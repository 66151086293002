import { createStyles, StyleRules } from "@material-ui/core";

const styles = (/* theme: Theme */): StyleRules =>
  createStyles({
    root: {},
    chartContainer: {
      flexGrow: 1,
      minHeight: "208px",
    },
  });

export default styles;

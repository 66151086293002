import { ApiDataboxParameter, ApiPost } from "../api/baseApi";
import { GeoJson as ApiGeoJson } from "../api/geojson/interface";

export const LoadingState = {
  Initial: "initial",
  Loading: "loading",
  Error: "error",
};
export type LoadingState = (typeof LoadingState)[keyof typeof LoadingState];

export type PirikaPost = ApiPost;
export type DataboxQueryParameter = ApiDataboxParameter;
export type GeoJson = ApiGeoJson;

import * as React from "react";
import { WithStyles, withStyles } from "@material-ui/core";
import { withTranslation, WithTranslation } from "react-i18next";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import styles from "./ConfigurationLoadFailedStateScreenStyles";

interface Props extends WithStyles<typeof styles>, WithTranslation {}

const ConfigurationLoadFailedStateScreen: React.FC<Props> = ({ classes }) => (
  <div className={classes.root}>
    <div className={classes.contentsFrame}>
      <ErrorOutlineIcon fontSize="large" />
      <p>ページの読み込みに失敗しました。再読み込みしてください。</p>
    </div>
  </div>
);

export default withTranslation()(withStyles(styles)(ConfigurationLoadFailedStateScreen));
